import React, {useEffect, useState, useMemo} from 'react';

import {useLocation} from '@reach/router';
import {useStaticQuery, graphql} from 'gatsby';
import {Anchor, Button, ExternalAnchor} from 'src/components/controls';
import {Box, Flex, Modal, NavigationItem} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import {Chevron} from 'src/svgs/chevron';
import {easing} from 'src/utils/animations';
import {ModalIds, ModalPriority} from 'src/utils/constants';
import * as copy from 'src/utils/copy';
import {comparePath} from 'src/utils/paths';
import styled, {css} from 'styled-components/macro';

import {NavDropdown} from './NavDropdown';

const activeClassName = 'nav-item-active';

const Nav = styled(Box).attrs({
  as: 'nav',
})``;

interface NavMainDropdownProps {
  navigationItems: NavigationItem[];
  setOpen: boolean;
  open: boolean;
}

export const NavMainDropdown: React.FC<NavMainDropdownProps> = ({navigationItems}: NavMainDropdownProps) => {
  const {pathname} = useLocation();

  const isNavigationItemActive = (item: Pick<NavigationItem, 'path' | 'subnavigationItems'>) =>
    comparePath(pathname, item.path) ||
    item.subnavigationItems?.some(subnavItem => comparePath(pathname, subnavItem.path));

  const visibleNavigationItems = useMemo(() => navigationItems.filter(i => !i.hidden), [navigationItems]);

  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleDropDown = (e: any) => {
    e.preventDefault();
    setOpen(prev => !prev);
  };

  return (
    <div className="bg-white z-20 h-screen">
      <Flex flexDirection={'column'} minHeight={'100vh'} data-cy="navbar.mobile.drawer">
        <Nav>
          <ul className="py-2 space-y-2">
            {visibleNavigationItems.map(item => (
              <li
                key={item.name}
                {...(isNavigationItemActive(item) ? {className: activeClassName} : {})}
                id={item.name === 'Thought Leadership' ? 'navDropdownArticles' : ''}
              >
                {item.name === 'Thought Leadership' ? (
                  <div className={open ? 'bg-green-dark' : ''}>
                    <Flex alignItems={'center'} justifyContent={'space-between'} pr={'M'}>
                      <a
                        className={`type-zeta px-gutter pt-6 ${open ? '!text-white' : 'text-green-dark'}`}
                        href={item.path}
                        data-cy={item.drawerDataCY}
                      >
                        {item.name}
                      </a>
                      <div onClick={event => toggleDropDown(event)} className="ml-2">
                        <Chevron
                          classes={` w-[9px] h-[7px]transition-all linear duration-300 mt-6 ${
                            open ? 'rotate-180 fill-white' : 'fill-green-dark'
                          }`}
                        />
                      </div>
                    </Flex>
                    {open ? (
                      <div className="type-zeta">
                        <NavDropdown />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <a
                    target={item.type === 'external' ? '_blank' : '_self'}
                    href={item.path}
                    data-cy={item.drawerDataCY}
                    rel="noreferrer"
                  >
                    <span className="type-zeta px-gutter text-green-dark py-6 inline-block">{item.name}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </Nav>
      </Flex>
    </div>
  );
};
