import React, {useEffect, useState} from 'react';

import {useStaticQuery, graphql} from 'gatsby';
import {Box, BoxProps, HeaderNav, CareersNav} from 'src/components/shared';
import * as paths from 'src/routes/paths';
import {zIndices} from 'src/styleguide/zIndices';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';
import styled from 'styled-components/macro';

import {SanityLohpBannerQuery} from 'graphql-types';

import {Banner} from './Banner/Banner';
import {Footer} from './Footer';

export type BaseLayoutProps = BoxProps;

const TopAlign: typeof Box = styled(Box).attrs({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: zIndices.fixed,
})``;

export const BaseLayout: React.FC<BoxProps & {path: string}> = ({children, ...props}) => {
  const data = useStaticQuery<SanityLohpBannerQuery>(graphql`
    query SanityLohpBanner {
      allSanityLohpBanner(limit: 1, sort: {order: DESC, fields: _updatedAt}) {
        nodes {
          id
          internalTitle
          message
          linkText
          linkUrl
        }
      }
    }
  `);
  const isCareersPage = props.path.startsWith(paths.CAREERS);
  const isHomePage = props.path === paths.HOME;
  const activeBanner = isHomePage ? data.allSanityLohpBanner.nodes[0] : null;

  const headerNavHeight = activeBanner
    ? HEADER_NAV_HEIGHT.responsive.map(height => (height ? `calc(53px +  ${height})` : null))
    : HEADER_NAV_HEIGHT.responsive;

  return (
    <Box {...props}>
      {isCareersPage ? (
        <CareersNav />
      ) : (
        <TopAlign>
          {activeBanner && <Banner {...activeBanner} />}
          <HeaderNav />
        </TopAlign>
      )}
      <Box paddingTop={headerNavHeight}>{children}</Box>
      <Footer />
    </Box>
  );
};
