import React from 'react';

import BlockContent, {BlockContentProps} from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import {themeGet} from '@styled-system/theme-get';
import {Image} from 'src/components/shared/Image';
import {Typography} from 'src/components/shared/typography';
import {colors} from 'src/styleguide/colors';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components/macro';

const {H1, H2, H3, H4, H5, H6, P4} = Typography;

export type RichContentType = (props: BlockContentProps & {extraListPadding?: boolean}) => JSX.Element;
const StyledRichContentBlock = styled(BlockContent)`
  line-height: 1.4em;

  ul,
  ol {
    padding-left: ${(props: any) => (props.extraListPadding ? '40px' : undefined)};
    margin: 0;

    li {
      padding-bottom: 1em;
      margin-left: 0px;
      font-size: inherit;
    }
  }

  li {
    list-style-type: disc;
  }

  ul {
    list-style-type: disc;
  }
  ul ul {
    list-style-type: circle;
  }

  ol li {
    list-style-type: decimal;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const LabelledImage = styled(Image).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})`
  object-fit: cover;
`;

export type RichContentBlockArray = {
  children: {
    marks: string[];
    text: string;
    _key: string;
    _type: string;
  }[];
  markDefs: any[];
  style: string;
  _key: string;
  _type: string;
}[];

// extraListPadding is a hack so we can eliminate additional padding without
// breaking existing usage. This was introduced after seeing that 40px padding + additional
// will occasionally show up when the RichContent component is used in the TextWithImage
// component. TextWithImage already has padding for list items which caused this additional
// padding. It gave the component's lists unequal spacing and looked bad.
export const RichContent: RichContentType = ({serializers, extraListPadding = true, ...props}: any): JSX.Element => {
  return (
    <StyledRichContentBlock
      {...props}
      extraListPadding={extraListPadding}
      serializers={{
        ...serializers,
        types: {
          labelledImage: ({node}) => {
            return <LabelledImage {...node} />;
          },
          image: ({node}) => {
            const imgSrc = imageUrlBuilder(sanityClient).image(node.asset).url();
            return <Image mb="60px" src={imgSrc ?? ''} />;
          },
          imageWithAlt: ({node}) => {
            const imgSrc = imageUrlBuilder(sanityClient).image(node.asset).url();
            const marginBottom = node.marginBottom ?? 60;
            const useFlex = node.align ? 'flex' : undefined;
            const width = node.width ? `${node.width}%` : undefined;
            return (
              <div style={{display: useFlex, justifyContent: node.align}}>
                <Image
                  display={'inline'}
                  mb={`${marginBottom}px`}
                  width={width}
                  src={imgSrc ?? ''}
                  alt={node.imageAltTag}
                />
              </div>
            );
          },
          block: ({children, node}) => {
            switch (node.style) {
              case 'h1':
                return <H1 mb="21px">{children}</H1>;
              case 'h2':
                return <H2 mb="21px">{children}</H2>;
              case 'h3':
                return <H3 mb="21px">{children}</H3>;
              case 'h4':
                return <H4 mb="21px">{children}</H4>;
              case 'h5':
                return <H5 mb="21px">{children}</H5>;
              case 'h6':
                return <H6 mb="21px">{children}</H6>;
              case 'blockquote':
                return (
                  <P4 as="blockquote" mb="21px" pl="21px" borderLeft="2px solid" borderColor="lineGray">
                    {children}
                  </P4>
                );
              default:
                return <P4 mb="21px">{children}</P4>;
            }
          },
          ...serializers?.types,
        },
      }}
    />
  );
};

export const StyledRichContent: typeof RichContent = styled(RichContent)`
  p,
  p span,
  li,
  a {
    color: ${themeGet('colors.cream')};
  }
`;
