import React from 'react';

export const FacebookSocialIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9998 4C9.37253 4 4 9.39238 4 16.0441C4 21.6923 7.87448 26.4319 13.1011 27.7336V19.7248H10.6267V16.0441H13.1011V14.4581C13.1011 10.3588 14.9495 8.45872 18.9594 8.45872C19.7197 8.45872 21.0315 8.60855 21.5681 8.75789V12.0941C21.2849 12.0642 20.7929 12.0493 20.1819 12.0493C18.2144 12.0493 17.4541 12.7975 17.4541 14.7424V16.0441H21.3737L20.7003 19.7248H17.4541V28C23.3959 27.2798 28 22.202 28 16.0441C27.9995 9.39238 22.627 4 15.9998 4Z"
      fill="white"
    />
  </svg>
);
