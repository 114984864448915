import React from 'react';

export const LinkedIn: React.FC<{color: string}> = ({color}) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77857 3.88951C5.77857 2.84658 4.93249 2 3.89022 2C2.84376 2 1.99994 2.84658 1.99994 3.88951C1.99994 4.93266 2.84376 5.77916 3.89022 5.77916C4.93249 5.77916 5.77857 4.93266 5.77857 3.88951ZM5.52023 7.21151H2.25863V17.6954H5.52023V7.21151Z"
      fill={color}
    />
    <path
      d="M7.56421 7.2116H10.6874V8.64484H10.7322C11.1666 7.82061 12.2298 6.95142 13.815 6.95142C17.114 6.95142 17.7237 9.12222 17.7237 11.9456V17.6955H14.4658V12.5971C14.4658 11.3814 14.4448 9.81737 12.7727 9.81737C11.0773 9.81737 10.8185 11.1423 10.8185 12.5101V17.6955H7.56421V7.2116Z"
      fill={color}
    />
  </svg>
);
