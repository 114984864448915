import React from 'react';

import css from '@styled-system/css';
import {Events} from 'src/analytics';
import {ExternalAnchor} from 'src/components/controls';
import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {colors} from 'src/styleguide/colors';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {typographyStyles} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import styled from 'styled-components/macro';

import {SanityLohpBanner} from 'graphql-types';

const Container = styled(Flex).attrs({
  backgroundColor: colors.cream,
  py: 14,
  px: 24,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: ['column', null, 'row'],
  color: 'white',
})``;

const Message = styled(Typography.P2).attrs({
  color: 'darkGreen',
  textAlign: 'center',
  fontSize: ['0.75rem', null, null, '1.0625rem'],
  display: 'block',
  mr: [0, null, 16],
})``;

const LinkMessage = styled(ExternalAnchor).attrs({
  color: 'darkGreen',
})`
  ${css(typographyStyles.P2)};
  font-size: 0.75rem;
  font-weight: ${fontWeights.bold};

  @media screen and (min-width: ${breakpoints[2]}) {
    font-size: 1.0625rem;
  }
`;

export type BannerProps = Pick<SanityLohpBanner, 'message' | 'linkText' | 'linkUrl' | 'internalTitle'>;

export const Banner: React.FC<BannerProps> = ({internalTitle, message, linkText, linkUrl}) => {
  if (!message && !linkUrl) {
    return null;
  }
  return (
    <Container>
      <Message>{message}</Message>
      {typeof linkUrl === 'string' && (
        <LinkMessage
          href={linkUrl}
          onClick={() => Events.didClickNav({item: `${internalTitle} Banner`, pageLocation: 'banner'})}
        >
          {linkText} ›
        </LinkMessage>
      )}
    </Container>
  );
};
