import React from 'react';

import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {Events} from 'src/analytics';
import {Box, Flex, FlexProps} from 'src/components/shared';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';

const {facebookSquare, email, twitter, linkedIn} = Icons;

type StickySocialLinksProps = FlexProps & {
  url: string;
  title?: string;
  description?: string;
  color?: string;
  iconSize?: number;
  classes?: string;
};

const iconClasses = ' md:w-8 lg:w-[48px] h-8 lg:h-[48px] hover:fill-white rounded-full ';
let iconBgClass = ' bg-ivory ';

const beforeOnClick = (type: string) => {
  Events.didClickSocial({type});
};

export const StickySocialLinks: React.FC<StickySocialLinksProps> = ({
  url,
  title,
  description,
  color,
  iconSize,
  classes,
  ...props
}) => {
  if (color == 'white') {
    iconBgClass = ' bg-white ';
  }
  return (
    <div className={classes}>
      <LinkedinShareButton
        beforeOnClick={() => beforeOnClick('LinkedIn')}
        title={title}
        summary={description}
        url={url}
        resetButtonStyle={false}
      >
        <svg
          className={'fill-[#0077B5] hover:bg-black hover:fill-white ' + iconClasses + iconBgClass}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.9232 20.5625H13.9741V32.9154H17.9232V20.5625Z" />
          <path d="M33.8806 32.9154H29.9315V26.4983C29.9315 24.5732 28.9442 23.9315 27.6278 23.9315C26.3114 23.9315 25.3241 24.5732 25.3241 26.4983V32.9154H21.375C21.375 32.9154 21.375 21.5251 21.375 20.5625H25.3241V22.4876C25.6532 21.5251 27.1342 20.5625 29.2733 20.5625C31.906 20.5625 34.0452 22.3272 34.0452 26.1775V32.9154H33.8806Z" />
          <path d="M13.9736 16.3865C13.809 17.5095 14.6318 18.472 15.6191 18.6325C16.7709 18.7929 17.7582 17.9908 17.9227 17.0282C18.0873 15.9052 17.2645 14.9426 16.2772 14.7822C15.1254 14.6218 14.1381 15.2635 13.9736 16.3865Z" />
        </svg>
      </LinkedinShareButton>
      <EmailShareButton
        beforeOnClick={() => beforeOnClick('Email')}
        subject={title}
        body={description}
        url={url}
        resetButtonStyle={false}
      >
        <svg
          className={'fill-[#1E320B] hover:bg-black hover:fill-white ' + iconClasses + iconBgClass}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.8 17C16.516 17.001 16.2362 17.0673 15.9836 17.1935C15.731 17.3197 15.5127 17.5023 15.3465 17.7262C15.2953 17.7972 15.2595 17.8776 15.2415 17.9625C15.2235 18.0473 15.2235 18.1349 15.2417 18.2198C15.2598 18.3046 15.2956 18.385 15.3469 18.4559C15.3983 18.5267 15.464 18.5866 15.54 18.6319L23.4465 23.4444C23.6125 23.5453 23.8043 23.5988 24 23.5988C24.1957 23.5988 24.3875 23.5453 24.5535 23.4444L32.424 18.6319C32.5025 18.5841 32.5699 18.5209 32.6217 18.4462C32.6734 18.3716 32.7085 18.2872 32.7247 18.1985C32.7408 18.1099 32.7376 18.0189 32.7152 17.9315C32.6929 17.8441 32.6519 17.7623 32.595 17.6912C32.431 17.4813 32.2204 17.3101 31.9789 17.1904C31.7373 17.0707 31.4711 17.0056 31.2 17L16.8 17ZM32.55 20.5744C32.4714 20.5745 32.3947 20.5973 32.3295 20.64L24.5535 25.4131C24.3875 25.514 24.1957 25.5675 24 25.5675C23.8043 25.5675 23.6125 25.514 23.4465 25.4131L15.657 20.6969C15.591 20.6634 15.5175 20.6463 15.443 20.6472C15.3686 20.6481 15.2955 20.667 15.2304 20.7021C15.1653 20.7372 15.1101 20.7875 15.0699 20.8484C15.0297 20.9094 15.0057 20.9791 15 21.0512L15 29.25C15 29.7141 15.1896 30.1592 15.5272 30.4874C15.8648 30.8156 16.3226 31 16.8 31H31.2C31.6774 31 32.1352 30.8156 32.4728 30.4874C32.8104 30.1592 33 29.7141 33 29.25V20.9944C32.9953 20.8814 32.9459 20.7746 32.862 20.6963C32.7781 20.618 32.6663 20.5743 32.55 20.5744Z" />
        </svg>
      </EmailShareButton>
      <TwitterShareButton
        beforeOnClick={() => beforeOnClick('Twitter')}
        title={title}
        url={url}
        resetButtonStyle={false}
      >
        <svg
          className={'fill-[#00ACEE] hover:bg-black hover:fill-white ' + iconClasses + iconBgClass}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M34.3669 18.4488C33.5442 18.7697 32.886 18.9301 31.8987 18.9301C32.7214 18.4488 33.0505 18.128 33.5442 17.005C32.7214 17.4863 31.8987 17.4863 31.076 17.6467C30.2532 16.8446 29.2659 16.3633 27.9495 16.3633C25.6459 16.3633 23.8359 18.128 23.8359 20.374C23.8359 20.6948 23.8359 21.0157 24.0004 21.3365C20.5449 21.1761 17.4185 19.5718 15.2794 17.005C14.9503 17.6467 14.7857 18.2884 14.7857 19.0906C14.7857 20.5344 15.4439 21.6574 16.5958 22.4595C15.9376 22.4595 15.2794 22.2991 14.6212 21.9783C14.6212 23.9034 16.1021 25.5077 17.9121 25.9889C17.5831 26.1494 17.254 26.1494 16.7603 26.1494C16.4312 26.1494 16.2667 26.1494 15.9376 26.1494C16.4312 27.7537 17.9121 29.0371 19.7222 29.0371C17.9121 30.9622 13.963 30.3205 13.6339 30.3205C15.4439 31.4435 17.7476 32.2456 20.0513 32.2456C27.785 32.2456 31.8987 26.1494 31.8987 20.8553C31.8987 20.6948 31.8987 20.5344 31.8987 20.374C33.0505 19.7323 33.7087 19.251 34.3669 18.4488Z" />
        </svg>
      </TwitterShareButton>
      <FacebookShareButton
        beforeOnClick={() => beforeOnClick('Facebook')}
        quote={title}
        url={url}
        resetButtonStyle={false}
      >
        <svg
          className={'fill-[#4267B2] hover:bg-black hover:fill-white ' + iconClasses + iconBgClass}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M26.1393 17.8703C27.1265 17.8703 27.9493 17.8703 28.9366 17.8703C28.9366 17.2286 28.9366 15.3035 28.9366 13.6992C27.6202 13.6992 26.3038 13.6992 25.6456 13.6992C21.0383 13.6992 21.2028 17.2286 21.2028 17.8703C21.2028 18.0308 21.2028 18.9933 21.2028 20.5976H19.0637V24.7687H21.2028C21.2028 29.2607 21.2028 34.3944 21.2028 34.3944H25.3165C25.3165 34.3944 25.3165 29.2607 25.3165 24.7687H28.1138L28.9366 20.5976H25.3165C25.3165 19.4746 25.3165 18.6725 25.3165 18.5121C25.3165 18.0308 25.6456 17.8703 26.1393 17.8703Z" />
        </svg>
      </FacebookShareButton>
    </div>
  );
};
