import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {P4, BodyLarge, Body} = Typography;

export type BulletBoxProps = BoxProps & {
  heading?: string;
  description?: string;
  bullets: {key: string; text: string}[];
  keyAsBulletLabel: boolean;
  footer?: React.ReactNode;
};

const Wrapper: typeof Box = styled(Box).attrs({
  border: '1px solid',
  borderColor: 'lightGreen',
  p: ['25px 26px', null, null, '32px 51px'],
})``;

const List = styled.ul`
  list-style: disc;
  padding-left: 20px;
  width: 100%;
  :not(:last-child) {
    margin-bottom: 23px;
  }
`;
const ListItem = styled.li`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0px;
  }
`;

export const BulletBox: React.FC<BulletBoxProps> = ({
  heading,
  description,
  bullets,
  keyAsBulletLabel,
  footer,
  ...props
}) => {
  return (
    <Wrapper width="100%" {...props}>
      {heading && (
        <BodyLarge fontWeight="bold" mb="23px">
          {heading}
        </BodyLarge>
      )}
      {description && <Body mb="23px">{description}</Body>}
      <List>
        {bullets.map(item => (
          <ListItem key={item.key}>
            <P4 maxWidth="unset">
              {keyAsBulletLabel && (
                <P4 as="span" fontWeight="bold">
                  {item.key}:{' '}
                </P4>
              )}
              {item.text}
            </P4>
          </ListItem>
        ))}
      </List>
      {footer}
    </Wrapper>
  );
};
