import React, {useEffect, useState} from 'react';

import {useAnimation, motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {Typography} from 'src/components/shared/fireworkTypography';
import styled from 'styled-components/macro';

const {H2} = Typography;

const Wrapper = motion.div;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

type FireworkTextRotatorProps = {strings: string[]; fadeTime?: number; timeBetweenRotations?: number};

export const FireworkTextRotator: React.FC<FireworkTextRotatorProps> = ({...props}) => {
  const [index, setIndex] = useState(0);
  const [rotatorText, setRotatorText] = useState(props.strings.length ? props.strings[0] : '');
  const [wasInView, setWasInView] = useState(false);

  const anim = useAnimation();

  const {ref, inView} = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const animation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {duration: props.fadeTime},
    },
  };

  const onAnimationComplete = (variant: string) => {
    if (variant === 'visible') {
      setTimeout(() => anim.start('hidden'), props.timeBetweenRotations);
      return;
    }

    setIndex(index >= props.strings.length - 1 ? 0 : index + 1);
    anim.start('visible');
  };

  useEffect(() => {
    // Only trigger animation once
    if (wasInView) {
      return;
    }

    setWasInView(true);

    anim.start('visible');
  }, [inView]);

  useEffect(() => {
    setRotatorText(props.strings[index]);
  }, [index]);

  return (
    <Wrapper ref={ref} initial="hidden" animate={anim} variants={animation} onAnimationComplete={onAnimationComplete}>
      <StyledH2>{rotatorText}</StyledH2>
    </Wrapper>
  );
};

FireworkTextRotator.defaultProps = {
  fadeTime: 0.5, // In seconds
  timeBetweenRotations: 5000, // In milliseconds
};
