import React from 'react';

import {NavLink} from 'react-router-dom';
import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {P3} = Typography;
const activeClassName = 'nav-item-active';

interface NavigationItem {
  name: string;
  path?: string;
  exact?: boolean;
}

type Props = {
  navigationItems: NavigationItem[];
};

const NavSection = styled(Box).attrs({
  pt: 'L',
  mb: 'L',
})``;

const NavItem = styled(NavLink).attrs({
  activeClassName,
})`
  color: ${({theme}) => theme.colors.black};
  &:hover {
    p {
      background: ${({theme}) => theme.colors.lightGrey};
    }
  }
  &.${activeClassName} {
    p {
      background: ${({theme}) => theme.colors.lightGold}25;
    }
  }
`;

export const NavigationList: React.FC<Props> = ({navigationItems}) => {
  return (
    <NavSection>
      {navigationItems.map(item =>
        item.path ? (
          <NavItem exact={item.exact} key={item.name} to={item.path} activeClassName={activeClassName}>
            <P3 p={'S'}>{item.name}</P3>
          </NavItem>
        ) : (
          <P3 p={'S'}>{item.name}</P3>
        ),
      )}
    </NavSection>
  );
};
