import React from 'react';

import {Box, BoxProps} from 'src/components/shared';

export const MobileOnly: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['contents', null, null, 'none']} {...props}>
    {children}
  </Box>
);

export const TabletUp: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['none', null, null, 'contents']} {...props}>
    {children}
  </Box>
);

export const TabletDown: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['contents', null, null, null, 'none']} {...props}>
    {children}
  </Box>
);

export const TabletOnly: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['none', null, null, 'contents', 'none']} {...props}>
    {children}
  </Box>
);

export const LaptopUp: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['none', null, null, null, 'contents']} {...props}>
    {children}
  </Box>
);

export const LaptopDown: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['contents', null, null, null, null, 'none']} {...props}>
    {children}
  </Box>
);

export const LaptopOnly: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['none', null, null, null, 'contents', 'none']} {...props}>
    {children}
  </Box>
);

export const DesktopUp: React.FC<BoxProps> = ({children, ...props}) => (
  <Box as="span" display={['none', null, null, null, null, 'contents']} {...props}>
    {children}
  </Box>
);
