// This theme object follows the Theme Specification (https://styled-system.com/theme-specification/)
// to help with design consistency and interoperability.
//
// Below are initial theme keys (eg "fonts", "space")
// More can be added, both default ones (see "Theme" interface) and additional ones (https://styled-system.com/table).
import {Theme} from 'styled-system';

import {colors} from './colors';
import {space} from './space';
import {fonts, fontWeights, lineHeights, letterSpacings} from './typographyStyles';
import {zIndices} from './zIndices';

export const maxPageContainerSize = '1160px';
export const defaultPageGutter = ['S', 'M', null, null, 'XL'];

export const breakpoints: string[] = ['374px', '479px', '767px', '1023px', '1439px'];
export const breakpointValues: number[] = breakpoints.map(bp => Number(bp.replace(/\D/g, '')));

export const defaultTheme: Theme = {
  fonts,
  fontWeights,
  lineHeights,
  letterSpacings,
  space: {
    ...space,
  },
  breakpoints,
  colors: {
    ...colors,
  },
  zIndices,
};

// Augment the DefaultTheme interface so that we can useTheme() and get type completion
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof colors;
    space: typeof space;
    fonts: typeof fonts;
    fontWeights: typeof fontWeights;
    lineHeights: typeof lineHeights;
    letterSpacings: typeof letterSpacings;
    breakpoints: typeof breakpoints;
    zIndices: typeof zIndices;
  }
}
