import React, {useContext} from 'react';

import {DefaultTheme, ThemeContext, useTheme} from 'styled-components/macro';

import {ArrowLarge} from './arrowLarge';
import {ArrowSmall} from './arrowSmall';
import {CheckmarkCircle} from './checkmarkCircle';
import {ChevronCircle} from './chevronCircle';
import {ChevronRight} from './chevronRight';
import {ChevronRightLarge} from './chevronRightLarge';
import {Close} from './close';
import {Email} from './email';
import {EnterpriseAdvisory, EnterpriseCoaching, EnterpriseEducation} from './enterpriseIcons';
import {
  ExperienceAdvisory,
  ExperienceCoaching,
  ExperienceEducation,
  ExperienceAdvisoryModal,
  ExperienceCoachingModal,
  ExperienceEducationModal,
} from './experienceIcons';
import {HeroTextMaskDesktop, HeroTextMaskLaptop, HeroTextMaskMobile, HeroTextMaskTablet} from './heroTextMask';
import {
  HomepageAdvisory,
  HomepageCoaching,
  HomepageEducation,
  HomepageLeftSwoosh,
  HomepageRightSwoosh,
} from './homepageIcons';
import {Logo} from './logo';
import {Accenture} from './logos/accenture';
import {Allstate} from './logos/allstate';
import {Amazon} from './logos/amazon';
import {Audible} from './logos/audible';
import {Bloomberg} from './logos/bloomberg';
import {Cartier} from './logos/cartier';
import {Chanel} from './logos/chanel';
import {Chobani} from './logos/chobani';
import {Citi} from './logos/citi';
import {CNBC} from './logos/cnbc';
import {CNN} from './logos/cnn';
import {Dell} from './logos/dell';
import {Discovery} from './logos/discovery';
import {Disney} from './logos/disney';
import {Doordash} from './logos/doordash';
import {Ebay} from './logos/ebay';
import {FacebookRound} from './logos/facebookRound';
import {FacebookSquare} from './logos/facebookSquare';
import {FastCompany} from './logos/fastCompany';
import {Forbes} from './logos/forbes';
import {GoldmanSachs} from './logos/goldmanSachs';
import {Google} from './logos/google';
import {Hbo} from './logos/hbo';
import {Headspace} from './logos/headspace';
import {HelloFresh} from './logos/helloFresh';
import {Hulu} from './logos/hulu';
import {Ibm} from './logos/ibm';
import {Inc500} from './logos/inc500';
import {JohnsonAndJohnson} from './logos/johnsonAndJohnson';
import {Jordans} from './logos/jordans';
import {LinkedIn} from './logos/linkedIn';
import {LinkedInFull} from './logos/linkedInFull';
import {Loreal} from './logos/loreal';
import {Lvmh} from './logos/lvmh';
import {Mercedes} from './logos/mercedes';
import {MorganStanley} from './logos/morganStanley';
import {Nbc} from './logos/nbc';
import {Netflix} from './logos/netflix';
import {NewYorkTimes} from './logos/newYorkTimes';
import {Nike} from './logos/nike';
import {Pfizer} from './logos/pfizer';
import {PublicisGroupe} from './logos/publicisGroupe';
import {Salesforce} from './logos/salesforce';
import {Samsung} from './logos/samsung';
import {Sony} from './logos/sony';
import {SonyMusic} from './logos/sonyMusic';
import {Spotify} from './logos/spotify';
import {Square} from './logos/square';
import {Squarespace} from './logos/squarespace';
import {Time} from './logos/time';
import {TodayShow} from './logos/todayShow';
import {Toyota} from './logos/toyota';
import {Twitter} from './logos/twitter';
import {Uber} from './logos/uber';
import {UnitedHealthGroup} from './logos/unitedHealthGroup';
import {Universal} from './logos/universal';
import {Verizon} from './logos/verizon';
import {WallStreetJournal} from './logos/wallStreetJournal';
import {Walmart} from './logos/walmart';
import {MembershipCriteria} from './membershipCriteria';
import {Menu} from './menu';
import {PartyPopper} from './partyPopper';
import {Person} from './person';

export enum Icons {
  logo = 'logo',
  menu = 'menu',
  arrowSmall = 'arrowSmall',
  arrowLarge = 'arrowLarge',
  checkmarkCircle = 'checkmarkCircle',
  chevronRight = 'chevronRight',
  chevronRightLarge = 'chevronRightLarge',
  chevronCircle = 'chevronCircle',
  close = 'close',
  email = 'email',
  partyPopper = 'partyPopper',
  enterpriseAdvisory = 'enterpriseAdvisory',
  enterpriseCoaching = 'enterpriseCoaching',
  enterpriseEducation = 'enterpriseEducation',
  experienceAdvisory = 'experienceAdvisory',
  experienceCoaching = 'experienceCoaching',
  experienceEducation = 'experienceEducation',
  experienceAdvisoryModal = 'experienceAdvisoryModal',
  experienceCoachingModal = 'experienceCoachingModal',
  experienceEducationModal = 'experienceEducationModal',
  heroTextMaskDesktop = 'heroTextMaskDesktop',
  heroTextMaskLaptop = 'heroTextMaskLaptop',
  heroTextMaskTablet = 'heroTextMaskTablet',
  heroTextMaskMobile = 'heroTextMaskMobile',
  homepageAdvisory = 'homepageAdvisory',
  homepageCoaching = 'homepageCoaching',
  homepageEducation = 'homepageEducation',
  homepageLeftSwoosh = 'homepageLeftSwoosh',
  homepageRightSwoosh = 'homepageRightSwoosh',
  membershipCriteria = 'membershipCriteria',
  person = 'person',
  // Logos
  accenture = 'accenture',
  allstate = 'allstate',
  amazon = 'amazon',
  audible = 'audible',
  bloomberg = 'bloomberg',
  cartier = 'cartier',
  chanel = 'chanel',
  chobani = 'chobani',
  citi = 'citi',
  cnbc = 'cnbc',
  cnn = 'cnn',
  dell = 'dell',
  discovery = 'discovery',
  disney = 'disney',
  doordash = 'doordash',
  ebay = 'ebay',
  facebookRound = 'facebookRound',
  facebookSquare = 'facecbookSquare',
  fastCompany = 'fastCompany',
  forbes = 'forbes',
  google = 'google',
  goldmanSachs = 'goldmanSachs',
  hbo = 'hbo',
  headspace = 'headspace',
  helloFresh = 'helloFresh',
  hulu = 'hulu',
  ibm = 'ibm',
  inc500 = 'inc500',
  johnsonAndJohnson = 'johnsonAndJohnson',
  jordans = 'jordans',
  linkedIn = 'linkedIn',
  linkedInFull = 'linkedInFull',
  loreal = 'loreal',
  lvmh = 'lvmh',
  mercedes = 'mercedes',
  morganStanley = 'morganStanley',
  nbc = 'nbc',
  netflix = 'netflix',
  newYorkTimes = 'newYorkTimes',
  nike = 'nike',
  pfizer = 'pfizer',
  publicisGroupe = 'publicisGroupe',
  salesforce = 'salesforce',
  samsung = 'samsung',
  sony = 'sony',
  sonyMusic = 'sonyMusic',
  spotify = 'spotify',
  square = 'square',
  squarespace = 'squarespace',
  time = 'time',
  todayShow = 'todayShow',
  toyota = 'toyota',
  twitter = 'twitter',
  uber = 'uber',
  unitedHealthGroup = 'unitedHealthGroup',
  universal = 'universal',
  verizon = 'verizon',
  wallStreetJournal = 'wallStreetJournal',
  walmart = 'walmart',
}

const getIcon = (iconProps: {className?: string; color: string; colorSecondary?: string; size?: number}) => ({
  [Icons.logo]: <Logo {...iconProps} />,
  [Icons.menu]: <Menu {...iconProps} />,
  [Icons.arrowSmall]: <ArrowSmall {...iconProps} />,
  [Icons.arrowLarge]: <ArrowLarge {...iconProps} />,
  [Icons.checkmarkCircle]: <CheckmarkCircle {...iconProps} />,
  [Icons.chevronRight]: <ChevronRight {...iconProps} />,
  [Icons.chevronRightLarge]: <ChevronRightLarge {...iconProps} />,
  [Icons.chevronCircle]: <ChevronCircle {...iconProps} />,
  [Icons.close]: <Close {...iconProps} />,
  [Icons.email]: <Email {...iconProps} />,
  [Icons.partyPopper]: <PartyPopper {...iconProps} />,
  [Icons.enterpriseAdvisory]: <EnterpriseAdvisory {...iconProps} />,
  [Icons.enterpriseCoaching]: <EnterpriseCoaching {...iconProps} />,
  [Icons.enterpriseEducation]: <EnterpriseEducation {...iconProps} />,
  [Icons.experienceAdvisory]: <ExperienceAdvisory {...iconProps} />,
  [Icons.experienceCoaching]: <ExperienceCoaching {...iconProps} />,
  [Icons.experienceEducation]: <ExperienceEducation {...iconProps} />,
  [Icons.experienceAdvisoryModal]: <ExperienceAdvisoryModal {...iconProps} />,
  [Icons.experienceCoachingModal]: <ExperienceCoachingModal {...iconProps} />,
  [Icons.experienceEducationModal]: <ExperienceEducationModal {...iconProps} />,
  [Icons.heroTextMaskDesktop]: <HeroTextMaskDesktop {...iconProps} />,
  [Icons.heroTextMaskLaptop]: <HeroTextMaskLaptop {...iconProps} />,
  [Icons.heroTextMaskTablet]: <HeroTextMaskTablet {...iconProps} />,
  [Icons.heroTextMaskMobile]: <HeroTextMaskMobile {...iconProps} />,
  [Icons.homepageAdvisory]: <HomepageAdvisory {...iconProps} />,
  [Icons.homepageCoaching]: <HomepageCoaching {...iconProps} />,
  [Icons.homepageEducation]: <HomepageEducation {...iconProps} />,
  [Icons.homepageLeftSwoosh]: <HomepageLeftSwoosh {...iconProps} />,
  [Icons.homepageRightSwoosh]: <HomepageRightSwoosh {...iconProps} />,
  [Icons.membershipCriteria]: <MembershipCriteria {...iconProps} />,
  [Icons.person]: <Person {...iconProps} />,
  // Logos

  [Icons.accenture]: <Accenture {...iconProps} />,
  [Icons.allstate]: <Allstate {...iconProps} />,
  [Icons.amazon]: <Amazon {...iconProps} />,
  [Icons.audible]: <Audible {...iconProps} />,
  [Icons.bloomberg]: <Bloomberg {...iconProps} />,
  [Icons.cartier]: <Cartier {...iconProps} />,
  [Icons.chanel]: <Chanel {...iconProps} />,
  [Icons.chobani]: <Chobani {...iconProps} />,
  [Icons.citi]: <Citi {...iconProps} />,
  [Icons.cnbc]: <CNBC {...iconProps} />,
  [Icons.cnn]: <CNN {...iconProps} />,
  [Icons.dell]: <Dell {...iconProps} />,
  [Icons.discovery]: <Discovery {...iconProps} />,
  [Icons.disney]: <Disney {...iconProps} />,
  [Icons.doordash]: <Doordash {...iconProps} />,
  [Icons.ebay]: <Ebay {...iconProps} />,
  [Icons.facebookRound]: <FacebookRound {...iconProps} />,
  [Icons.facebookSquare]: <FacebookSquare {...iconProps} />,
  [Icons.fastCompany]: <FastCompany {...iconProps} />,
  [Icons.forbes]: <Forbes {...iconProps} />,
  [Icons.google]: <Google {...iconProps} />,
  [Icons.goldmanSachs]: <GoldmanSachs {...iconProps} />,
  [Icons.headspace]: <Headspace {...iconProps} />,
  [Icons.helloFresh]: <HelloFresh {...iconProps} />,
  [Icons.hbo]: <Hbo {...iconProps} />,
  [Icons.hulu]: <Hulu {...iconProps} />,
  [Icons.ibm]: <Ibm {...iconProps} />,
  [Icons.inc500]: <Inc500 {...iconProps} />,
  [Icons.johnsonAndJohnson]: <JohnsonAndJohnson {...iconProps} />,
  [Icons.jordans]: <Jordans {...iconProps} />,
  [Icons.linkedIn]: <LinkedIn {...iconProps} />,
  [Icons.linkedInFull]: <LinkedInFull {...iconProps} />,
  [Icons.loreal]: <Loreal {...iconProps} />,
  [Icons.lvmh]: <Lvmh {...iconProps} />,
  [Icons.mercedes]: <Mercedes {...iconProps} />,
  [Icons.morganStanley]: <MorganStanley {...iconProps} />,
  [Icons.nbc]: <Nbc {...iconProps} />,
  [Icons.netflix]: <Netflix {...iconProps} />,
  [Icons.newYorkTimes]: <NewYorkTimes {...iconProps} />,
  [Icons.nike]: <Nike {...iconProps} />,
  [Icons.pfizer]: <Pfizer {...iconProps} />,
  [Icons.publicisGroupe]: <PublicisGroupe {...iconProps} />,
  [Icons.salesforce]: <Salesforce {...iconProps} />,
  [Icons.samsung]: <Samsung {...iconProps} />,
  [Icons.sony]: <Sony {...iconProps} />,
  [Icons.sonyMusic]: <SonyMusic {...iconProps} />,
  [Icons.spotify]: <Spotify {...iconProps} />,
  [Icons.square]: <Square {...iconProps} />,
  [Icons.squarespace]: <Squarespace {...iconProps} />,
  [Icons.time]: <Time {...iconProps} />,
  [Icons.todayShow]: <TodayShow {...iconProps} />,
  [Icons.toyota]: <Toyota {...iconProps} />,
  [Icons.twitter]: <Twitter {...iconProps} />,
  [Icons.uber]: <Uber {...iconProps} />,
  [Icons.unitedHealthGroup]: <UnitedHealthGroup {...iconProps} />,
  [Icons.universal]: <Universal {...iconProps} />,
  [Icons.verizon]: <Verizon {...iconProps} />,
  [Icons.wallStreetJournal]: <WallStreetJournal {...iconProps} />,
  [Icons.walmart]: <Walmart {...iconProps} />,
});

interface IconProps {
  'aria-label': string;
  className?: string;
  icon: Icons;
  color?: keyof DefaultTheme['colors'] | string;
  colorSecondary?: string;
  size?: number;
}

export const Icon: React.FC<IconProps> = ({icon, color = 'black', ...rest}: IconProps) => {
  const theme = useTheme();
  let colorKey;
  if (color in theme.colors) {
    colorKey = color as keyof DefaultTheme['colors'];
  }
  const renderColor = (colorKey && theme.colors[colorKey]) || color;
  return getIcon({color: renderColor, ...rest})[icon];
};
