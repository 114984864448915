import * as log from 'src/utils/log';

export interface AnalyticsProperties {
  alias: (previousId: string, userId: string) => void;
  group: (groupId: string, traits?: Record<string, unknown>) => void;
  track: (event: string, properties?: Record<string, unknown>) => void;
  page: (name?: string, properties?: Record<string, unknown>) => void;
  identify: (userId: string, traits?: Record<string, unknown>) => void;
}

export interface Analytics {
  analytics: AnalyticsProperties;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

const analyticsStub: Analytics = {
  analytics: {
    alias: (userId: string, previousId?: string): void => {
      log.info(`🛠🔄 If analytics was enabled, I would have sent an alias event!
      previousId: ${previousId}
      userId: ${userId}`);
    },
    group: (groupId: string, traits?: Record<string, unknown>): void => {
      log.info(`🛠🚻 If analytics was enabled, I would have sent a group event!
      groupId: ${groupId}`);
      traits && log.info('🛠🚻 traits:', traits);
    },
    identify: (userId: string, traits?: Record<string, unknown>): void => {
      log.info(`🛠🚺 If analytics was enabled, I would have sent an identify event!
      userId: ${userId}}`);
      traits && log.info('🛠🚺 traits:', traits);
    },
    page: (name?: string, properties?: Record<string, unknown>): void => {
      log.info(`🛠📃 If analytics was enabled, I would have sent a page event!
      name: ${name || 'N/A'}`);
      properties && log.info('🛠📃 properties:', properties);
    },
    track: (event: string, properties?: Record<string, unknown>): void => {
      log.info(`🛠📍 If analytics was enabled, I would have sent a track event!
      event: ${event}`);
      properties && log.info('🛠📍 properties:', properties);
    },
  },
};

export const segmentAnalytics = (window: Window): AnalyticsProperties =>
  window?.analytics ? window.analytics : analyticsStub.analytics;
