import React from 'react';

export const UnitedHealthGroup: React.FC<{color: string}> = ({color}) => (
  <svg width="166" height="13" viewBox="0 0 166 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M128.542 6.15287C129.82 6.15287 130.332 5.57369 130.332 4.46797C130.332 3.30208 129.738 2.75299 128.572 2.75299H126.872V11.3279C126.872 11.5912 126.654 11.8168 126.383 11.8168H125.33C125.066 11.8168 124.841 11.5912 124.841 11.3279V2.06097C124.841 1.79771 125.059 1.55701 125.33 1.55701H128.775C130.888 1.55701 132.205 2.51981 132.205 4.46797C132.205 6.00244 131.234 6.7471 130.106 7.04046L132.325 11.1925C132.37 11.2828 132.415 11.3731 132.415 11.4708C132.415 11.6514 132.303 11.8093 132.137 11.8093H130.851C130.7 11.8093 130.542 11.689 130.467 11.531L128.098 6.90506C128.038 6.79223 127.932 6.60419 127.932 6.43118C127.932 6.27322 128.008 6.15287 128.278 6.15287H128.542Z"
      fill="#2A2C41"
    />
    <path
      d="M138.357 2.49711C136.236 2.49711 135.868 4.40767 135.868 6.6868C135.868 8.96593 136.259 10.8765 138.327 10.8765C140.396 10.8765 140.847 9.06371 140.847 6.6868C140.847 4.30989 140.449 2.49711 138.357 2.49711ZM138.357 12.0349C136.379 12.0349 133.859 11.3579 133.859 6.6868C133.859 2.01571 136.364 1.33875 138.357 1.33875C140.351 1.33875 142.856 2.0458 142.856 6.6868C142.856 11.3278 140.411 12.0349 138.357 12.0349Z"
      fill="#2A2C41"
    />
    <path
      d="M148.663 12.035C145.91 12.035 144.789 10.4855 144.789 8.60501V2.06097C144.789 1.79771 145.007 1.55701 145.278 1.55701H146.308C146.579 1.55701 146.797 1.79019 146.797 2.06097V8.71784C146.797 10.4178 147.617 10.8766 148.753 10.8766C150.257 10.8766 150.709 9.98903 150.709 8.71784V2.06097C150.709 1.79771 150.927 1.55701 151.198 1.55701H152.04C152.303 1.55701 152.529 1.79019 152.529 2.06097V8.60501C152.529 10.7788 151.168 12.035 148.655 12.035"
      fill="#2A2C41"
    />
    <path
      d="M157.914 6.68693H158.418C159.456 6.68693 160.269 6.1604 160.269 4.76885C160.269 3.24191 159.479 2.74546 158.373 2.74546H156.786V11.3279C156.786 11.5912 156.591 11.8168 156.327 11.8168H155.304C155.041 11.8168 154.815 11.5912 154.815 11.3279V2.06097C154.815 1.79771 155.034 1.55701 155.304 1.55701H158.998C160.983 1.55701 162.149 2.76803 162.149 4.76133C162.149 6.56658 161.089 7.7776 158.998 7.7776C158.599 7.7776 158.208 7.73999 157.967 7.69486C157.546 7.62716 157.35 7.39398 157.35 7.15328C157.35 6.91258 157.538 6.67941 157.922 6.67941"
      fill="#2A2C41"
    />
    <path
      d="M108.375 11.8168H107.397C107.119 11.8168 106.893 11.5912 106.893 11.3129V7.22098H102.937V11.3129C102.937 11.5912 102.719 11.8168 102.433 11.8168H101.455C101.177 11.8168 100.951 11.5912 100.951 11.3129V2.06097C100.951 1.79771 101.169 1.55701 101.44 1.55701H102.441C102.704 1.55701 102.929 1.79019 102.929 2.06097V5.77678H106.886V2.06097C106.886 1.79771 107.112 1.55701 107.375 1.55701H108.383C108.646 1.55701 108.872 1.79019 108.872 2.06097V11.3129C108.872 11.5912 108.646 11.8168 108.368 11.8168"
      fill="#2A2C41"
    />
    <path
      d="M4.27243 11.9897C1.12076 11.9897 0 10.2221 0 8.10842V0.789634C0 0.488759 0.255744 0.210449 0.556619 0.210449H1.4442C1.74508 0.210449 2.00082 0.488759 2.00082 0.782112V8.31151C2.00082 10.2145 3.05388 10.7336 4.31004 10.7336C5.96485 10.7336 6.61925 9.89111 6.61925 8.31151V0.789634C6.61925 0.488759 6.875 0.217971 7.17587 0.217971H7.95815C8.26655 0.217971 8.51477 0.496281 8.51477 0.789634V8.11594C8.51477 10.553 7.03296 11.9972 4.26491 11.9972"
      fill="#2A2C41"
    />
    <path
      d="M47.9597 2.74546H46.2071V10.6209H47.9597C50.2764 10.6209 50.8405 8.86827 50.8405 6.73958C50.8405 4.61089 50.299 2.74546 47.9597 2.74546ZM48.426 11.8168H44.7252C44.462 11.8168 44.2363 11.5837 44.2363 11.3204V2.06849C44.2363 1.80523 44.462 1.55701 44.7252 1.55701H48.426C50.8631 1.55701 52.7962 2.86581 52.7962 6.7471C52.7962 10.6284 50.7127 11.8168 48.426 11.8168Z"
      fill="#2A2C41"
    />
    <path
      d="M63.3487 11.8167H62.4687C62.1528 11.8167 61.9045 11.5685 61.9045 11.2526V6.56646H57.1958V11.2526C57.1958 11.5685 56.9476 11.8167 56.6317 11.8167H55.7516C55.4357 11.8167 55.1875 11.5685 55.1875 11.2526V0.857354C55.1875 0.556479 55.4357 0.293213 55.7366 0.293213H56.6467C56.9476 0.293213 57.1958 0.556479 57.1958 0.857354V5.09217H61.9045V0.857354C61.9045 0.556479 62.1528 0.293213 62.4536 0.293213H63.3638C63.6647 0.293213 63.9129 0.556479 63.9129 0.857354V11.2526C63.9129 11.5685 63.6647 11.8167 63.3487 11.8167Z"
      fill="#2A2C41"
    />
    <path
      d="M90.9319 11.8168H85.9825C85.7042 11.8168 85.4785 11.5912 85.4785 11.3129V2.06097C85.4785 1.79771 85.7042 1.55701 85.9674 1.55701H86.9979C87.2612 1.55701 87.4869 1.79019 87.4869 2.06097V10.3726H90.9319C91.1951 10.3726 91.4208 10.6133 91.4208 10.8766V11.3354C91.4208 11.5987 91.2027 11.8244 90.9319 11.8244"
      fill="#2A2C41"
    />
    <path
      d="M23.9721 11.8168H22.9717C22.6934 11.8168 22.4678 11.5837 22.4678 11.3054V2.06849C22.4678 1.80523 22.6859 1.55701 22.9567 1.55701H23.9872C24.2505 1.55701 24.4761 1.80523 24.4761 2.06849V11.3054C24.4761 11.5837 24.258 11.8168 23.9721 11.8168Z"
      fill="#2A2C41"
    />
    <path
      d="M33.4649 3.00873H31.1332V11.3054C31.1332 11.5837 30.9075 11.8168 30.6292 11.8168H29.6288C29.3505 11.8168 29.1248 11.5837 29.1248 11.3054V3.00873H26.778C26.5147 3.00873 26.2891 2.79059 26.2891 2.51981V2.06097C26.2891 1.79771 26.5072 1.55701 26.778 1.55701H33.4649C33.7282 1.55701 33.9539 1.79771 33.9539 2.06097V2.51981C33.9539 2.78307 33.7357 3.00873 33.4649 3.00873Z"
      fill="#2A2C41"
    />
    <path
      d="M98.6422 3.00873H96.1524V11.3054C96.1524 11.5837 95.9343 11.8168 95.6485 11.8168H94.6481C94.3698 11.8168 94.1441 11.5837 94.1441 11.3054V3.00873H91.6393C91.376 3.00873 91.1504 2.79059 91.1504 2.51981V2.06097C91.1504 1.79771 91.3685 1.55701 91.6393 1.55701H98.6422C98.9054 1.55701 99.1311 1.79771 99.1311 2.06097V2.51981C99.1311 2.78307 98.913 3.00873 98.6422 3.00873Z"
      fill="#2A2C41"
    />
    <path
      d="M41.9725 11.8168H36.1881C35.9249 11.8168 35.6992 11.6438 35.6992 11.3806V2.06097C35.6992 1.79771 35.9174 1.55701 36.1881 1.55701H41.7619C42.0251 1.55701 42.2508 1.79771 42.2508 2.06097V2.46715C42.2508 2.73042 42.0326 2.95608 41.7619 2.95608H37.7076V5.79935H41.3557C41.6189 5.79935 41.8446 6.03252 41.8446 6.30331V6.70949C41.8446 6.97276 41.6265 7.19842 41.3557 7.19842H37.7076V10.4103H41.9725C42.2357 10.4103 42.4614 10.651 42.4614 10.9142V11.3806C42.4614 11.6438 42.2433 11.8168 41.9725 11.8168Z"
      fill="#2A2C41"
    />
    <path
      d="M72.8641 11.8168H67.0797C66.8165 11.8168 66.5908 11.6438 66.5908 11.3806V2.06097C66.5908 1.79771 66.8165 1.55701 67.0797 1.55701H72.6535C72.9167 1.55701 73.1424 1.79771 73.1424 2.06097V2.46715C73.1424 2.73042 72.9242 2.95608 72.6535 2.95608H68.5992V5.79935H72.2398C72.503 5.79935 72.7287 6.03252 72.7287 6.30331V6.70949C72.7287 6.97276 72.5105 7.19842 72.2398 7.19842H68.5992V10.4103H72.8641C73.1273 10.4103 73.353 10.651 73.353 10.9142V11.3806C73.353 11.6438 73.1349 11.8168 72.8641 11.8168Z"
      fill="#2A2C41"
    />
    <path
      d="M18.8955 11.8168H17.3836C16.9172 11.8168 16.6915 11.5987 16.5787 11.3354C15.8265 9.74081 12.9156 3.2043 12.9156 3.2043V11.3204C12.9156 11.5837 12.6974 11.8168 12.4266 11.8168H11.7497C11.4864 11.8168 11.2607 11.5837 11.2607 11.3204V2.05345C11.2607 1.79019 11.4789 1.55701 11.7497 1.55701H13.2315C13.6677 1.55701 13.9385 1.70744 14.089 2.03841C14.8712 3.7534 17.7371 10.1244 17.7371 10.1244V2.05345C17.7371 1.79019 17.9627 1.55701 18.226 1.55701H18.9105C19.1738 1.55701 19.3994 1.78266 19.3994 2.05345V11.3204C19.3994 11.5837 19.1738 11.8168 18.9105 11.8168"
      fill="#2A2C41"
    />
    <path
      d="M122.118 11.4783C121.577 11.719 120.734 12.065 118.959 12.065C116.071 12.065 113.476 10.854 113.476 6.04753C113.476 2.27907 115.07 0.0300293 118.959 0.0300293C120.749 0.0300293 121.742 0.503908 121.975 0.609214C122.329 0.759652 122.359 0.83487 122.359 1.00035C122.359 1.08309 122.314 1.32379 122.306 1.38397C122.246 1.67732 122.163 2.15872 122.118 2.41446C122.096 2.55738 122.073 2.75295 121.855 2.75295C121.674 2.75295 121.524 2.54234 121.426 2.44455C121.276 2.28659 120.411 1.39901 118.967 1.39901C116.214 1.39901 115.74 4.03919 115.74 6.04753C115.74 8.82311 116.642 10.7036 118.967 10.7036C119.854 10.7036 120.32 10.5607 120.463 10.5231V6.7245C120.463 6.49132 120.599 6.42363 120.832 6.42363H122.103C122.374 6.42363 122.464 6.52893 122.464 6.74707V10.9067C122.464 11.3053 122.442 11.3505 122.118 11.4934"
      fill="#2A2C41"
    />
    <path
      d="M77.5658 8.16122L79.0927 3.07643L80.5294 8.16122H77.5658ZM83.5607 11.1624L80.6121 2.03089C80.5068 1.72249 80.2586 1.55701 79.89 1.55701H78.5888C78.2127 1.55701 78.002 1.72249 77.8967 2.03089L74.9557 11.2226C74.9256 11.3129 74.8955 11.3731 74.8955 11.4633C74.8955 11.6664 75.076 11.8168 75.249 11.8168H75.911C76.2645 11.8168 76.5202 11.6514 76.6255 11.343L77.2047 9.42489H80.9431L81.5524 11.343C81.6351 11.6062 81.8307 11.8168 82.282 11.8168H83.2448C83.4704 11.8168 83.6134 11.6664 83.6134 11.4483C83.6134 11.343 83.5833 11.2753 83.5532 11.17"
      fill="#2A2C41"
    />
    <path
      d="M164.24 2.69268H164.579C164.797 2.69268 164.932 2.64755 164.932 2.4219C164.932 2.22633 164.759 2.16615 164.579 2.16615H164.24V2.68516V2.69268ZM163.962 1.94802H164.624C165.022 1.94802 165.218 2.10598 165.218 2.43694C165.218 2.71525 165.045 2.91082 164.774 2.91082L165.263 3.67805H164.962L164.481 2.91082H164.24V3.67805H163.962V1.94802ZM164.526 4.06919C165.203 4.06919 165.714 3.52009 165.714 2.81303C165.714 2.10598 165.203 1.55688 164.526 1.55688C163.849 1.55688 163.33 2.10598 163.33 2.81303C163.33 3.52009 163.842 4.06919 164.526 4.06919ZM164.526 1.33875C165.338 1.33875 166 2.00067 166 2.81303C166 3.6254 165.338 4.28732 164.526 4.28732C163.714 4.28732 163.052 3.6254 163.052 2.81303C163.052 2.00067 163.714 1.33875 164.526 1.33875Z"
      fill="#2A2C41"
    />
  </svg>
);
