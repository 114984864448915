import React, {forwardRef} from 'react';

import {props} from '@styled-system/should-forward-prop';
import {themeGet} from '@styled-system/theme-get';
import {Link, GatsbyLinkProps} from 'gatsby';
import {transform, TransformProps} from 'src/styleguide/styleProps/transform';
import {typographyStyles} from 'src/styleguide/typographyStyles';
import styled, {StyledComponentProps} from 'styled-components/macro';
import {
  compose, // used to combine multiple style functions
  variant, // used to apply multiple styles based on a prop, eg. variant="primary"
  border, // import style functions which add props to the component (that can pull values from theme object)
  flexbox,
  layout,
  position,
  space,
  BorderProps, // import the respective types for style functions
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system';

export type StyledButtonProps = BorderProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  TransformProps &
  SpaceProps & {
    disabled?: boolean;
    variant?:
      | 'primary'
      | 'secondary'
      | 'secondaryGold'
      | 'tertiary'
      | 'basic'
      | 'basicGoldText'
      | 'icon'
      | 'iconWithHover';
  };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ButtonProps = StyledComponentProps<'button', any, StyledButtonProps, any>;

const defaultButtonStyles = {
  position: 'relative',
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,

  borderRadius: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',

  outline: 'none',

  px: 'S',
  py: 'XS',

  minWidth: [null, null, '99.8px'], // styled-system uses null to ignore breakpoints

  cursor: 'pointer',
  textAlign: 'center',

  transform: 'perspective(1px) translateZ(0)',
  transitionDuration: '0.2s',
  transitionProperty: 'color, background-color',

  ...typographyStyles.P1,
};

const defaultIconButtonStyles = {
  position: 'relative',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  color: 'black',
  '&:active': {
    opacity: '0.5',
  },
};

const defaultTextButtonStyles = {
  backgroundColor: 'transparent',
  color: 'darkGold',
  fontWeight: 'bold',
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  '&:active, &:hover': {
    textDecoration: 'underline',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  '&[hidden]': {
    display: 'block',
    visibility: 'hidden',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buttonVariants = ({theme}: {theme: any}) =>
  variant({
    variants: {
      primary: {
        ...defaultButtonStyles,
        bg: 'white',
        color: 'offBlack',
        svg: {
          fill: 'offBlack',
          stroke: 'offBlack',
          path: {
            fill: 'offBlack',
          },
        },
        '&:hover': {
          bg: 'offBlack',
          color: 'white',
          borderColor: 'white',
          svg: {
            fill: 'white',
            stroke: 'white',
          },
        },
        '&:active,&:focus': {
          bg: 'offBlack',
          borderColor: 'lineGray60',
          color: 'lineGray60',
        },
        '&:disabled': {
          bg: 'emptyGray',
          borderColor: 'emptyGray',
          cursor: 'not-allowed',
          color: 'white',
        },
      },
      secondary: {
        ...defaultButtonStyles,
        color: 'white',
        bg: 'transparent',
        borderColor: 'white',
        svg: {
          path: {
            stroke: 'white',
          },
        },
        '&:hover': {
          bg: 'white',
          color: 'offBlack',
          borderColor: 'white',
          svg: {
            path: {
              stroke: 'white',
            },
          },
        },
        '&:active,&:focus': {
          bg: 'lineGray60',
          borderColor: 'lineGray60',
          color: 'white',
        },
        '&:disabled': {
          color: 'emptyGray',
          bg: 'white',
          borderColor: 'emptyGray',
          cursor: 'not-allowed',
        },
      },
      secondaryGold: {
        ...defaultButtonStyles,
        color: 'darkGold',
        bg: 'transparent',
        borderColor: 'darkGold',
        svg: {
          path: {
            stroke: 'darkGold',
          },
        },
        '&:hover': {
          bg: 'darkGold',
          color: 'white',
          borderColor: 'darkGold',
          svg: {
            path: {
              stroke: 'darkGold',
            },
          },
        },
        '&:active,&:focus': {
          bg: 'lineGray60',
          borderColor: 'lineGray60',
          color: 'darkGold',
        },
        '&:disabled': {
          color: 'emptyGray',
          bg: 'darkGold',
          borderColor: 'emptyGray',
          cursor: 'not-allowed',
        },
      },
      tertiary: {
        ...defaultButtonStyles,
        bg: 'darkGold',
        color: 'white',
        svg: {
          fill: 'white',
          stroke: 'white',
          path: {
            fill: 'white',
          },
        },
        '&:hover': {
          color: 'darkGold',
          bg: 'transparent',
          borderColor: 'darkGold',
          svg: {
            fill: 'darkGold',
            stroke: 'darkGold',
          },
        },
        '&:active': {
          bg: 'darkGoldAlt',
          borderColor: 'darkGoldAlt',
          color: 'white',
        },
        '&:disabled': {
          bg: 'sand',
          borderColor: 'sand',
          cursor: 'not-allowed',
          color: 'white',
        },
      },
      transparent: {
        ...defaultButtonStyles,
        bg: 'darkGreen',
        color: 'cream',
        borderColor: 'cream',
        svg: {
          fill: 'cream',
          stroke: 'cream',
          path: {
            fill: 'cream',
          },
        },
        '&:hover': {
          color: 'darkGreen',
          bg: 'cream',
          borderColor: 'cream',
          svg: {
            fill: 'cream',
            stroke: 'cream',
          },
        },
        '&:active': {
          bg: 'cream',
          borderColor: 'cream',
          color: 'darkGreen',
        },
        '&:disabled': {
          bg: 'sand',
          borderColor: 'sand',
          cursor: 'not-allowed',
          color: 'white',
        },
      },
      neonGreen: {
        ...defaultButtonStyles,
        bg: 'neonGreen',
        color: 'darkGreen',
        borderColor: 'neonGreen',
        svg: {
          fill: 'darkGreen',
          stroke: 'darkGreen',
          path: {
            fill: 'darkGreen',
          },
        },
        '&:hover': {
          color: 'neonGreen',
          bg: 'darkGreen',
          borderColor: 'neonGreen',
          svg: {
            fill: 'cream',
            stroke: 'cream',
          },
        },
        '&:active': {
          bg: 'darkGreen',
          borderColor: 'noenGreen',
          color: 'noenGreen',
        },
      },
      darkGreen: {
        ...defaultButtonStyles,
        bg: 'darkGreen',
        color: 'neonGreen',
        borderColor: 'darkGreen',
        svg: {
          fill: 'darkGreen',
          stroke: 'darkGreen',
          path: {
            fill: 'darkGreen',
          },
        },
        '&:hover': {
          color: 'darkGreen',
          bg: 'neonGreen',
          borderColor: 'darkGreen',
          svg: {
            fill: 'darkGreen',
            stroke: 'darkGreen',
          },
        },
        '&:active': {
          bg: 'neonGreen',
          borderColor: 'darkGreen',
          color: 'darkGreen',
        },
      },
      periwinkle: {
        ...defaultButtonStyles,
        bg: 'periwinkle',
        color: 'midnightBlue',
        borderColor: 'periwinkle',
        svg: {
          fill: 'periwinkle',
          stroke: 'periwinkle',
          path: {
            fill: 'periwinkle',
          },
        },
        '&:hover': {
          color: 'periwinkle',
          bg: 'midnightBlue',
          borderColor: 'periwinkle',
          svg: {
            fill: 'periwinkle',
            stroke: 'periwinkle',
          },
        },
        '&:active': {
          bg: 'midnightBlue',
          borderColor: 'periwinkle',
          color: 'periwinkle',
        },
      },
      midnightBlue: {
        ...defaultButtonStyles,
        bg: 'midnightBlue',
        color: 'cream',
        borderColor: 'midnightBlue',
        '&:hover': {
          color: 'midnightBlue',
          bg: 'cream',
          borderColor: 'midnightBlue',
        },
        '&:active': {
          color: 'midnightBlue',
          bg: 'cream',
          borderColor: 'midnightBlue',
        },
      },
      cream: {
        ...defaultButtonStyles,
        bg: 'cream',
        color: 'midnightBlue',
        borderColor: 'cream',
        '&:hover': {
          color: 'cream',
          bg: 'midnightBlue',
          borderColor: 'cream',
        },
        '&:active': {
          color: 'cream',
          bg: 'midnightBlue',
          borderColor: 'cream',
        },
      },
      basic: {
        ...defaultButtonStyles,
        bg: 'transparent',
        color: 'black',
        '&:active, &:hover': {
          opacity: '0.5',
        },
        '&:disabled': {
          cursor: 'not-allowed',
          color: 'black',
          opacity: 0.5,
        },
      },
      basicGray: {
        ...defaultButtonStyles,
        bg: 'lightGray',
        color: 'black',
        '&:active, &:hover': {
          opacity: '0.5',
        },
        '&:disabled': {
          cursor: 'not-allowed',
          color: 'black',
          opacity: 0.5,
        },
      },
      basicGold: {
        ...defaultButtonStyles,
        ...typographyStyles.P3,
        ...defaultTextButtonStyles,
      },
      basicGoldText: {
        ...defaultButtonStyles,
        ...defaultTextButtonStyles,
      },
      basicLightGoldText: {
        ...defaultButtonStyles,
        ...defaultTextButtonStyles,
        color: 'lightGold',
      },
      icon: {
        ...defaultIconButtonStyles,
      },
      iconWithHover: {
        ...defaultIconButtonStyles,
        p: 'S',
        '&:disabled': {
          cursor: 'default',
        },
        '&:hover:not(:disabled)': {
          bg: 'lightGray',
          boxShadow: `inset 1px 2px 4px ${themeGet('colors.insetGray')}`,
        },
      },
    },
  });

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !props.includes(prop) && defaultValidatorFn(prop),
})<ButtonProps>(buttonVariants, compose(space, layout, flexbox, border, position, transform));

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({disabled, children, ...props}, ref) => (
  <StyledButton ref={ref} disabled={disabled} {...props}>
    {children}
  </StyledButton>
));

Button.displayName = 'Button';
Button.defaultProps = {
  variant: 'primary',
};

export const LinkButtonStyled = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !props.includes(prop) && defaultValidatorFn(prop),
})<ButtonProps & GatsbyLinkProps<Record<string, unknown>>>(
  buttonVariants,
  compose(space, layout, flexbox, border, position),
);

export const LinkButton = forwardRef<HTMLButtonElement, ButtonProps>(({disabled, children, ...props}, ref) => (
  <LinkButtonStyled ref={ref} disabled={disabled} {...props}>
    {children}
  </LinkButtonStyled>
));

LinkButton.displayName = 'LinkButton';
LinkButton.defaultProps = {
  variant: 'primary',
};
