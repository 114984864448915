import React from 'react';
export const ErrorSymbol: React.FC = () => {
  return (
    <svg style={{maxWidth: '1rem'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 8.27499V5.52499M8 10.4695L8.00556 10.4756M13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8Z"
        stroke="#F09E8C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
