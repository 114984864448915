import React from 'react';

import DynamicStyledSystemComponent from 'src/styleguide/DynamicStyledSystemComponent';
import {StyledSystemProps} from 'src/styleguide/types';
import {typographyStyles} from 'src/styleguide/typographyStyles';
import {easing} from 'src/utils/animations';
import styled, {StyledComponentProps} from 'styled-components/macro';
import {variant} from 'styled-system';

export type StyledAnchorProps = StyledSystemProps<HTMLAnchorElement> & {
  variant?: 'default' | 'primary' | 'secondary' | 'primaryLong' | 'text' | 'largeText' | 'icon';
  to?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnchorProps = StyledComponentProps<'a', any, StyledAnchorProps, any>;

const defaultButtonStyles = {
  display: 'block',
  position: 'relative',
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,

  borderRadius: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',

  outline: 'none',

  px: 'S',
  py: 'XS',

  cursor: 'pointer',
  textAlign: 'center',
  ...typographyStyles.P2,
};

const defaultTextVariantStyles = {
  color: 'emptyGray',
  cursor: 'pointer',
  transition: `color 250ms ${easing.bezier}`,
  outline: 'none',
  '&:hover': {
    color: 'white',
  },
  '&:active, &:focus': {
    color: 'lightGray',
  },
};

const primary = {
  ...defaultButtonStyles,
  bg: 'darkGold',
  color: 'white',
  '&:hover': {
    color: 'darkGold',
    bg: 'transparent',
    borderColor: 'darkGold',
    svg: {
      fill: 'darkGold',
      stroke: 'darkGold',
    },
  },
  '&:active, &:focus': {
    backgroundColor: 'darkGold',
  },
  '&:disabled': {
    backgroundColor: 'sand',
    cursor: 'not-allowed',
  },
};

const AnchorVariants = variant({
  variants: {
    default: {
      cursor: 'pointer',
    },
    primary: {
      ...primary,
    },
    primaryLong: {
      ...primary,
      py: 'S',
    },
    secondary: {
      ...defaultButtonStyles,
      color: 'darkGold',
      bg: 'transparent',
      borderColor: 'darkGold',
      '&:active, &:focus, &:hover': {
        color: 'white',
        borderColor: 'darkGold',
        backgroundColor: 'darkGold',
      },
      '&:disabled': {
        color: 'lightGold',
        borderColor: 'lightGold',
        cursor: 'not-allowed',
      },
    },
    text: {
      ...defaultTextVariantStyles,
      ...typographyStyles.P2,
    },
    largeText: {
      ...defaultTextVariantStyles,
      ...typographyStyles.P0,
    },
    title: {
      ...typographyStyles.H4,
      color: 'black',
      '&:active, &:focus, &:hover': {
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: 'lightGray',
        cursor: 'not-allowed',
      },
    },
    icon: {
      ...defaultButtonStyles,
      px: 'NONE',
    },
    tightGray: {
      ...defaultButtonStyles,
      ...typographyStyles.P4,
      color: 'darkestGray',
      p: 'NONE',
      m: 'NONE',
      textAlign: 'left',
    },
  },
});

const Wrapper: React.FC<AnchorProps> = styled(DynamicStyledSystemComponent).attrs({as: 'a'})(AnchorVariants);

export const Anchor: React.FC<AnchorProps> = ({href, to, children, ...props}) => {
  let width = undefined;
  // Setting width here so it can be overridden
  switch (props.variant) {
    case 'primary':
    case 'secondary':
    case 'icon':
    case 'tightGray':
      width = [null, 150, null];
      break;
  }

  return (
    <Wrapper width={width} {...props} href={href || to}>
      {children}
    </Wrapper>
  );
};

Anchor.displayName = 'Anchor';
Anchor.defaultProps = {
  variant: 'default',
};

export const ExternalAnchor = styled(Anchor).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})``;

ExternalAnchor.displayName = 'ExternalAnchor';
