import React from 'react';

export const Ebay: React.FC<{color: string}> = ({color}) => (
  <svg width="117" height="71" viewBox="0 0 117 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.2055 28.3037C25.1469 28.3037 20.918 30.4449 20.918 36.9221C20.918 42.061 23.7551 45.2996 30.3393 45.2996C38.0744 45.2996 38.583 40.1874 38.583 40.1874H34.8359C34.8359 40.1874 34.0329 42.9443 30.1252 42.9443C26.9401 42.9443 24.6383 40.803 24.6383 37.7786H38.9845V35.8783C38.9577 32.8806 37.0841 28.3037 30.2055 28.3037ZM30.0717 30.7126C33.0961 30.7126 35.1838 32.5594 35.1838 35.3429H24.7186C24.7186 32.3988 27.4219 30.7126 30.0717 30.7126Z"
      fill={color}
    />
    <path
      d="M38.9577 22.0142V42.0345C38.9577 43.1586 38.8774 44.7645 38.8774 44.7645H42.464C42.464 44.7645 42.5978 43.6136 42.5978 42.5698C42.5978 42.5698 44.3643 45.3266 49.182 45.3266C54.2406 45.3266 57.6933 41.8204 57.6933 36.7885C57.6933 32.1046 54.5351 28.3307 49.2088 28.3307C44.2037 28.3307 42.6513 31.034 42.6513 31.034V22.0142H38.9577ZM48.2452 30.8199C51.6712 30.8199 53.8659 33.3626 53.8659 36.7885C53.8659 40.4554 51.35 42.8642 48.272 42.8642C44.6052 42.8642 42.6246 40.0003 42.6246 36.8153C42.6246 33.8711 44.3911 30.8199 48.2452 30.8199Z"
      fill={color}
    />
    <path
      d="M66.5526 28.3037C58.9246 28.3037 58.4428 32.4791 58.4428 33.1482H62.2435C62.2435 33.1482 62.4308 30.7126 66.285 30.7126C68.8009 30.7126 70.728 31.8635 70.728 34.0582V34.8344H66.285C60.3699 34.8344 57.2651 36.5741 57.2651 40.0536C57.2651 43.5063 60.1558 45.3799 64.0367 45.3799C69.3362 45.3799 71.0492 42.4357 71.0492 42.4357C71.0492 43.6134 71.1295 44.7643 71.1295 44.7643H74.5019C74.5019 44.7643 74.3681 43.3457 74.3681 42.4357V34.54C74.4216 29.3743 70.2462 28.3037 66.5526 28.3037ZM70.7548 37.1897V38.2336C70.7548 39.5986 69.9251 42.9978 64.9467 42.9978C62.2435 42.9978 61.0658 41.6328 61.0658 40.0804C61.0658 37.2165 65.0003 37.1897 70.7548 37.1897Z"
      fill={color}
    />
    <path
      d="M72.3606 28.9463H76.6163L82.7455 41.2047L88.8479 28.9463H92.7021L81.5678 50.7867H77.4995L80.7113 44.711L72.3606 28.9463Z"
      fill={color}
    />
    <path
      d="M94.1476 26.6174V26.9921H94.4688V27.233H94.1476V27.8219C94.1476 27.9825 94.2011 28.0628 94.3082 28.0628C94.3617 28.0628 94.4153 28.0628 94.442 28.036V28.3036C94.3885 28.3304 94.3082 28.3304 94.2011 28.3304C94.0673 28.3304 93.987 28.2769 93.9067 28.2233C93.8264 28.143 93.7997 28.036 93.7997 27.8486V27.233H93.6123V26.9921H93.7997V26.6977L94.1476 26.6174ZM94.7364 27.3936C94.7364 27.233 94.7364 27.126 94.7364 26.9921H95.0309L95.0576 27.1795C95.1112 27.0724 95.245 26.9654 95.4591 26.9654C95.6465 26.9654 95.7803 27.0724 95.8338 27.2063C95.8873 27.126 95.9409 27.0724 95.9944 27.0457C96.0747 26.9921 96.155 26.9654 96.2621 26.9654C96.4762 26.9654 96.7171 27.126 96.7171 27.5274V28.3036H96.3959V27.581C96.3959 27.3669 96.3156 27.233 96.155 27.233C96.0479 27.233 95.9676 27.3133 95.9141 27.4204C95.9141 27.4472 95.8873 27.5007 95.8873 27.5274V28.3304H95.5662V27.5542C95.5662 27.3669 95.4859 27.233 95.3253 27.233C95.1914 27.233 95.1112 27.3401 95.0844 27.4204C95.0576 27.4472 95.0576 27.5007 95.0576 27.5274V28.3036H94.7364V27.3936Z"
      fill={color}
    />
  </svg>
);
