import React from 'react';

import imageUrlBuilder from '@sanity/image-url';
import {Box} from 'src/components/shared/Box';
import {Flex} from 'src/components/shared/Flex';
import {Image} from 'src/components/shared/Image';
import {Typography} from 'src/components/shared/typography';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components/macro';

const AspectRatio = styled(Box).attrs({
  height: 0,
  position: 'relative',
  pt: '57.5%',
})`
  overflow: hidden;
`;

const Container = styled(Image).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})`
  object-fit: cover;
`;

const CaptionText = styled(Typography.P2).attrs({
  paddingRight: 'L',
})`
  flex: 3;
`;

const AttributionText = styled(Typography.P2).attrs({
  textAlign: ['left', null, null, 'right', null],
  mb: ['XS', null, null, 0, null, null],
  fontStyle: 'italic',
  whiteSpace: ['normal', 'normal', 'nowrap', 'nowrap', 'nowrap'],
})`
  color: ${({theme}) => theme.colors.darkestGray};
  flex: 1;
`;

export type LabelledImageProps = {
  _type: 'labelledImage';
  _key: string;
  altText: string;
  asset: {
    _ref: string;
    _type: string;
  };
  attribution: string;
  caption: string;
};

export const LabelledImage: React.FC<LabelledImageProps> = props => {
  return (
    <Box mb="21px">
      <AspectRatio>
        <Container src={imageUrlBuilder(sanityClient).image(props.asset).url() || ''} alt={props.altText} />
      </AspectRatio>
      <Flex
        justifyContent="space-between"
        mt={props.caption || props.attribution ? 'S' : 'NONE'}
        flexDirection={['column-reverse', null, null, 'row', null]}
      >
        {!!props.caption && <CaptionText>{props.caption}</CaptionText>}
        {!!props.attribution && <AttributionText>{props.attribution}</AttributionText>}
      </Flex>
    </Box>
  );
};
