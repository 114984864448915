import React from 'react';

export const Email: React.FC<{color: string}> = ({color}) => (
  <svg viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.223022 0V14.103H19.7769V0H0.223022ZM9.99998 8.01786L1.61218 0.754977H18.3953L9.99998 8.01786ZM7.07067 6.47771L0.978 12.7969V1.20796L7.07067 6.47771ZM7.64445 6.97599L9.99998 9.01443L12.3555 6.97599L18.5086 13.3555H1.49138L7.64445 6.97599ZM12.9293 6.47771L19.022 1.20796V12.7969L12.9293 6.47771Z"
      fill={color}
    />
  </svg>
);
