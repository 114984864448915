import React, {useMemo} from 'react';

import {useLocation} from '@reach/router';
import {Anchor, Button, ExternalAnchor} from 'src/components/controls';
import {Box, Flex, Modal} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import {easing} from 'src/utils/animations';
import {ModalIds, ModalPriority} from 'src/utils/constants';
import * as copy from 'src/utils/copy';
import {comparePath} from 'src/utils/paths';
import styled, {css} from 'styled-components/macro';

const {H6, Link} = Typography;
const activeClassName = 'nav-item-active';

const ModalCSS = css`
  width: 80%;
  min-height: 100%;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.14);
  transition: transform 300ms ${easing.bezier};
`;

const Nav = styled(Box).attrs({
  as: 'nav',
})``;

const List = styled(Box).attrs({
  as: 'ul',
  width: '100%',
})``;

const ListItem = styled(Box).attrs({
  as: 'li',
})`
  &.${activeClassName} {
    background: ${({theme}) => theme.colors.lightGray};
  }
`;

const NavItem = styled(Link)`
  text-decoration: none;
  outline: none;

  &:visited,
  &:link {
    color: inherit;
  }
`;
const ExternalNavItem = styled(ExternalAnchor)`
  text-decoration: none;
  outline: none;

  &:visited,
  &:link {
    color: inherit;
  }
`;

const ChevronIconWrapper = styled(Box).attrs({
  p: 'S',
})`
  transform: rotate(180deg);
`;

export type NavigationItem = {
  /**
   * link (default): For internal gatsby links
   *
   * external: For external links to open in same window
   *
   * externalTab: For external links to open in new tab
   */
  type?: 'link' | 'external' | 'externalTab';
  name: string;
  path: string;
  additionalPaths?: string[];
  dataCy?: string;
  drawerDataCY?: string;
  subnavigationItems?: SubnavigationItem[];
  subnavigationVariant?: 'left' | 'right';
  hightlightColor?: string;
  hidden?: boolean;
  onClick?: () => void;
};

export type SubnavigationItem = {
  name: string;
  path: string;
  exact?: boolean;
  dataCy?: string;
};

interface NavDrawerProps {
  isOpen: boolean;
  onRequestClose: () => void;
  navigationItems: NavigationItem[];
}

export const NavDrawer: React.FC<NavDrawerProps> = ({isOpen, onRequestClose, navigationItems}: NavDrawerProps) => {
  const {pathname} = useLocation();

  const isNavigationItemActive = (item: Pick<NavigationItem, 'path' | 'subnavigationItems'>) =>
    comparePath(pathname, item.path) ||
    item.subnavigationItems?.some(subnavItem => comparePath(pathname, subnavItem.path));

  const visibleNavigationItems = useMemo(() => navigationItems.filter(i => !i.hidden), [navigationItems]);

  return (
    <Modal
      id={ModalIds.navDrawer}
      priority={ModalPriority.highest}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalCSS={ModalCSS}
      animation="slide"
    >
      <Flex flexDirection={'column'} minHeight={'100vh'} data-cy="navbar.mobile.drawer">
        <Box pt={['none', null, 'M']} pb={'M'} ml={['none', null, 'XL']}>
          <Button variant={'icon'} p={'M'} onClick={onRequestClose} data-cy="navbar.mobile.close">
            <Icon aria-label={copy.navDrawer.ariaLabels.closeIcon} icon={Icons.close} color={'black'} size={20} />
          </Button>
        </Box>
        <Nav>
          <List>
            {visibleNavigationItems.map(item => (
              <ListItem
                key={item.name}
                pl={['none', null, 'XL']}
                {...(isNavigationItemActive(item) ? {className: activeClassName} : {})}
              >
                <NavItem
                  as={item.type === 'external' ? Anchor : item.type === 'externalTab' ? ExternalAnchor : undefined}
                  to={item.path}
                  onClick={() => {
                    item.onClick?.();
                    onRequestClose();
                  }}
                  data-cy={item.drawerDataCY}
                >
                  <Flex alignItems={'center'} justifyContent={'space-between'} pr={'M'}>
                    <H6 color={'black'} p={'M'}>
                      {item.name}
                    </H6>
                    <ChevronIconWrapper>
                      <Icon
                        aria-label={copy.navDrawer.ariaLabels.rightChevronIcon}
                        icon={Icons.chevronRight}
                        color={item.hightlightColor}
                        size={10}
                      />
                    </ChevronIconWrapper>
                  </Flex>
                </NavItem>
              </ListItem>
            ))}
          </List>
        </Nav>
      </Flex>
    </Modal>
  );
};
