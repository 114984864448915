const allowLog = process.env.REACT_APP_ENV !== 'production';

export const out = (...args: Array<string | Record<string, unknown>>): boolean | void =>
  allowLog && console.log(...args);
export const dir = (...args: Array<string | Record<string, unknown>>): boolean | void =>
  allowLog && console.dir(...args);
export const info = (...args: Array<string | Record<string, unknown>>): boolean | void =>
  allowLog && console.info(...args);
export const warn = (...args: Array<string | Record<string, unknown>>): boolean | void =>
  allowLog && console.warn(...args);
export const table = (...args: Array<string | Record<string, unknown>>): boolean | void =>
  allowLog && console.table(...args);
