import {useEffect, useState} from 'react';

export const useLoadOnInteraction = (): boolean => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!window) return () => {};
    const handleInteraction = () => setLoaded(true);
    window.addEventListener('scroll', handleInteraction, {once: true, passive: true});
    window.addEventListener('click', handleInteraction, {once: true, passive: true});
    return () => {
      window.removeEventListener('scroll', handleInteraction);
      window.removeEventListener('click', handleInteraction);
    };
  }, []);

  return loaded;
};
