import React from 'react';

import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {Events} from 'src/analytics';
import {Box, Flex, FlexProps} from 'src/components/shared';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';

const {facebookSquare, email, twitter, linkedIn} = Icons;

type SocialMediaLinksProps = FlexProps & {
  url: string;
  title?: string;
  description?: string;
  color?: string;
  iconSize?: number;
};

const Wrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 5px;
  }
`;

const beforeOnClick = (type: string) => {
  Events.didClickSocial({type});
};

export const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({
  url,
  title,
  description,
  color,
  iconSize,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <EmailShareButton
        beforeOnClick={() => beforeOnClick('Email')}
        subject={title}
        body={description}
        url={url}
        resetButtonStyle={false}
      >
        <Box width={iconSize}>
          <Icon icon={email} color={color} aria-label={copy.socialMediaLinks.ariaLabels.emailIcon} />
        </Box>
      </EmailShareButton>
      <FacebookShareButton
        beforeOnClick={() => beforeOnClick('Facebook')}
        quote={title}
        url={url}
        resetButtonStyle={false}
      >
        <Box width={iconSize}>
          <Icon icon={facebookSquare} color={color} aria-label={copy.socialMediaLinks.ariaLabels.facebookIcon} />
        </Box>
      </FacebookShareButton>
      <TwitterShareButton
        beforeOnClick={() => beforeOnClick('Twitter')}
        title={title}
        url={url}
        resetButtonStyle={false}
      >
        <Box width={iconSize}>
          <Icon icon={twitter} color={color} aria-label={copy.socialMediaLinks.ariaLabels.twitterIcon} />
        </Box>
      </TwitterShareButton>
      <LinkedinShareButton
        beforeOnClick={() => beforeOnClick('LinkedIn')}
        title={title}
        summary={description}
        url={url}
        resetButtonStyle={false}
      >
        <Box width={iconSize}>
          <Icon icon={linkedIn} color={color} aria-label={copy.socialMediaLinks.ariaLabels.linkedInIcon} />
        </Box>
      </LinkedinShareButton>
    </Wrapper>
  );
};

SocialMediaLinks.defaultProps = {
  iconSize: 20,
};
