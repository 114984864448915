import React from 'react';

export const Hulu: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 72">
    <path
      d="M63.7615 47.6922H69.4509V24.1741H63.7615V47.6922ZM53.7721 41.0105C53.7721 41.8375 53.0775 42.5321 52.2506 42.5321H48.9428C48.1159 42.5321 47.4213 41.8375 47.4213 41.0105C47.4213 40.6798 47.4213 31.5173 47.4213 31.5173H41.7319V41.4736C41.7319 45.5421 44.345 47.6922 48.182 47.6922H53.7391C57.2784 47.6922 59.4284 45.1452 59.4284 41.4736V31.5173H53.7391C53.7721 31.5173 53.7721 40.7128 53.7721 41.0105ZM85.7581 31.5173C85.7581 31.5173 85.7581 40.7128 85.7581 41.0105C85.7581 41.8375 85.0635 42.5321 84.2365 42.5321H80.9288C80.1018 42.5321 79.4072 41.8375 79.4072 41.0105C79.4072 40.6798 79.4072 31.5173 79.4072 31.5173H73.7179V41.4736C73.7179 45.5421 76.331 47.6922 80.168 47.6922H85.725C89.2643 47.6922 91.4143 45.1452 91.4143 41.4736V31.5173H85.7581ZM31.2464 31.5173C31.2464 31.5173 28.3025 31.5173 27.6078 31.5173C26.3178 31.5173 25.6893 31.8481 25.6893 31.8481V24.1741H20V47.6922H25.6563V38.1989C25.6563 37.372 26.3509 36.6774 27.1778 36.6774H30.4856C31.3125 36.6774 32.0071 37.372 32.0071 38.1989V47.6922H37.6965V37.4712C37.6965 33.1712 34.8187 31.5173 31.2464 31.5173Z"
      fill={color}
    />
  </svg>
);
