import React from 'react';

import {ButtonProps, LinkButton} from 'src/components/controls';
import {Box, BoxProps, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {H6, P0} = Typography;

export type CtaSectionProps = BoxProps & {
  heading?: string;
  links: (ButtonProps & {
    label: string;
    to: string;
  })[];
};

const Wrapper: typeof Box = styled(Box).attrs({})``;

export const CtaSection: React.FC<CtaSectionProps> = ({heading, links, ...props}) => {
  return (
    <Wrapper {...props}>
      <Flex height="100%" flexDirection="column" alignItems="center" justifyContent="center">
        {heading && (
          <H6 color="lightGold" textAlign="center" mb={['30px', null, null, null, null, '50px']}>
            {heading}
          </H6>
        )}
        <Flex flexDirection={['column', null, null, 'row']} alignItems="center" justifyContent="center">
          {links.map(({label, to, ...buttonProps}, index) => (
            <LinkButton
              key={label}
              to={to}
              width="244px"
              py="7px"
              mr={index !== links.length - 1 ? [null, null, null, '24px'] : undefined}
              mb={index !== links.length - 1 ? ['24px', null, null, '0px'] : undefined}
              {...buttonProps}
            >
              <P0>{label}</P0>
            </LinkButton>
          ))}
        </Flex>
      </Flex>
    </Wrapper>
  );
};

CtaSection.defaultProps = {
  height: ['208px', null, null, '262px'],
  py: '0px',
};
