import React from 'react';

export const AppleStoreIcon: React.FC = () => {
  return (
    <svg width="110" height="37" viewBox="0 0 110 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1465_142)">
        <path
          d="M101.24 0.000119501H8.76466C8.42757 0.000119501 8.09453 0.000119501 7.75834 0.00195798C7.47691 0.00379646 7.19773 0.00913725 6.9136 0.0136323C6.29635 0.020896 5.68054 0.0751996 5.07154 0.176071C4.46339 0.279139 3.8743 0.473451 3.32417 0.752435C2.77473 1.03378 2.27268 1.39936 1.83625 1.83591C1.39752 2.27123 1.03181 2.7744 0.753179 3.32608C0.473777 3.87665 0.280038 4.46665 0.178654 5.07569C0.076307 5.68395 0.021233 6.29923 0.0139265 6.916C0.00539594 7.19784 0.00449508 7.48062 0 7.76249V29.01C0.00449508 29.2955 0.00539594 29.572 0.0139265 29.8575C0.0212352 30.4742 0.0763092 31.0895 0.178654 31.6977C0.279758 32.3071 0.473508 32.8974 0.753179 33.4482C1.03169 33.9981 1.39745 34.4993 1.83625 34.9322C2.27102 35.3706 2.7734 35.7365 3.32417 36.0157C3.87429 36.2954 4.46332 36.4909 5.07154 36.5955C5.68064 36.6956 6.29639 36.7499 6.9136 36.758C7.19773 36.7643 7.47691 36.7679 7.75834 36.7679C8.09452 36.7697 8.42759 36.7697 8.76466 36.7697H101.24C101.571 36.7697 101.906 36.7697 102.237 36.7679C102.517 36.7679 102.804 36.7643 103.084 36.758C103.7 36.7503 104.315 36.696 104.923 36.5955C105.533 36.4901 106.124 36.2947 106.677 36.0157C107.227 35.7363 107.729 35.3705 108.163 34.9322C108.601 34.4976 108.968 33.9968 109.25 33.4482C109.527 32.897 109.719 32.3068 109.819 31.6977C109.921 31.0894 109.978 30.4743 109.989 29.8575C109.993 29.572 109.993 29.2955 109.993 29.01C110 28.6761 110 28.344 110 28.0046V8.76611C110 8.42948 110 8.09553 109.993 7.76249C109.993 7.48062 109.993 7.19784 109.989 6.91597C109.978 6.29914 109.921 5.684 109.819 5.07565C109.719 4.46697 109.527 3.87702 109.25 3.32604C108.682 2.22026 107.782 1.32017 106.677 0.752352C106.124 0.47405 105.533 0.279789 104.923 0.175988C104.315 0.0746724 103.7 0.0203499 103.084 0.0135036C102.804 0.00901774 102.517 0.003631 102.237 0.00183848C101.906 0 101.571 0.000119501 101.24 0.000119501Z"
          fill="#A6A6A6"
        />
        <path
          d="M7.76305 35.9654C7.48298 35.9654 7.20967 35.9618 6.93179 35.9555C6.35615 35.948 5.78185 35.8979 5.21361 35.8056C4.68375 35.7144 4.17046 35.5446 3.69066 35.302C3.21527 35.0613 2.78168 34.7458 2.40649 34.3675C2.02587 33.9936 1.70898 33.5599 1.46839 33.0837C1.22515 32.6044 1.05681 32.0907 0.969246 31.5604C0.874678 30.9906 0.823513 30.4144 0.816192 29.8368C0.810364 29.6429 0.802734 28.9974 0.802734 28.9974V7.76248C0.802734 7.76248 0.81086 7.12691 0.816238 6.94019C0.823248 6.36353 0.874115 5.78824 0.9684 5.2193C1.05613 4.68754 1.2246 4.17231 1.46797 3.69144C1.70768 3.21556 2.0228 2.7816 2.40113 2.40638C2.77903 2.0276 3.21401 1.71045 3.69021 1.46649C4.1689 1.22462 4.68122 1.05607 5.21001 0.966478C5.78012 0.873236 6.35642 0.822825 6.93406 0.815667L7.7635 0.804443H102.232L103.072 0.816118C103.644 0.822919 104.215 0.872881 104.78 0.965577C105.314 1.05629 105.832 1.22602 106.316 1.46918C107.27 1.96083 108.046 2.73867 108.536 3.69368C108.776 4.17123 108.941 4.68226 109.028 5.20943C109.123 5.78304 109.177 6.36285 109.188 6.94423C109.19 7.20456 109.19 7.48419 109.19 7.76248C109.198 8.1072 109.198 8.4353 109.198 8.7661V28.0046C109.198 28.3385 109.198 28.6644 109.19 28.9929C109.19 29.2919 109.19 29.5657 109.187 29.8476C109.176 30.4186 109.124 30.988 109.03 31.5514C108.944 32.0855 108.777 32.6033 108.533 33.0865C108.291 33.5575 107.976 33.9874 107.6 34.3603C107.224 34.7407 106.79 35.058 106.313 35.3002C105.831 35.5447 105.314 35.7151 104.78 35.8056C104.212 35.8984 103.638 35.9485 103.062 35.9555C102.793 35.9618 102.511 35.9654 102.237 35.9654L101.241 35.9672L7.76305 35.9654Z"
          fill="black"
        />
        <path
          d="M22.7688 18.6614C22.7787 17.8941 22.9825 17.1418 23.3612 16.4745C23.74 15.8072 24.2814 15.2465 24.935 14.8446C24.5198 14.2516 23.972 13.7635 23.3351 13.4192C22.6982 13.0749 21.9898 12.8839 21.2662 12.8612C19.7226 12.6992 18.2261 13.7849 17.4395 13.7849C16.6377 13.7849 15.4266 12.8773 14.1224 12.9041C13.2789 12.9314 12.4567 13.1767 11.7362 13.6161C11.0156 14.0556 10.4211 14.6742 10.0107 15.4117C8.23292 18.4897 9.559 23.0133 11.262 25.5013C12.114 26.7197 13.1098 28.0806 14.4128 28.0323C15.6879 27.9794 16.1641 27.2192 17.7031 27.2192C19.2279 27.2192 19.6747 28.0323 21.0041 28.0016C22.3723 27.9794 23.2343 26.7779 24.0565 25.548C24.6687 24.6799 25.1397 23.7205 25.4523 22.7053C24.6574 22.3691 23.9791 21.8063 23.5019 21.0872C23.0247 20.3681 22.7697 19.5244 22.7688 18.6614Z"
          fill="white"
        />
        <path
          d="M20.2578 11.2248C21.0038 10.3293 21.3713 9.17825 21.2823 8.01611C20.1426 8.13582 19.0898 8.68052 18.3338 9.54169C17.9641 9.9624 17.681 10.4518 17.5006 10.982C17.3202 11.5122 17.2461 12.0728 17.2824 12.6316C17.8525 12.6375 18.4164 12.5139 18.9318 12.2703C19.4472 12.0266 19.9006 11.6691 20.2578 11.2248Z"
          fill="white"
        />
        <path
          d="M38.886 24.9481H34.5349L33.4899 28.0335H31.647L35.7683 16.6184H37.6831L41.8044 28.0335H39.93L38.886 24.9481ZM34.9855 23.5244H38.4345L36.7343 18.5171H36.6867L34.9855 23.5244Z"
          fill="white"
        />
        <path
          d="M50.7048 23.8726C50.7048 26.4589 49.3206 28.1205 47.2317 28.1205C46.7025 28.1482 46.1762 28.0263 45.7131 27.7688C45.25 27.5113 44.8688 27.1286 44.6131 26.6645H44.5735V30.7867H42.8652V19.7109H44.5188V21.0952H44.5502C44.8177 20.6333 45.2054 20.2525 45.672 19.9935C46.1386 19.7344 46.6668 19.6066 47.2002 19.6238C49.3125 19.6239 50.7048 21.2936 50.7048 23.8726ZM48.9489 23.8726C48.9489 22.1877 48.0782 21.0799 46.7496 21.0799C45.4443 21.0799 44.5664 22.211 44.5664 23.8726C44.5664 25.5495 45.4443 26.6726 46.7496 26.6726C48.0782 26.6726 48.9489 25.5729 48.9489 23.8726Z"
          fill="white"
        />
        <path
          d="M59.865 23.8726C59.865 26.4589 58.4807 28.1205 56.3918 28.1205C55.8626 28.1482 55.3364 28.0263 54.8733 27.7688C54.4102 27.5113 54.0289 27.1286 53.7732 26.6645H53.7337V30.7867H52.0254V19.7109H53.6789V21.0952H53.7104C53.9778 20.6333 54.3655 20.2525 54.8321 19.9935C55.2988 19.7344 55.8269 19.6066 56.3604 19.6238C58.4727 19.6238 59.865 21.2936 59.865 23.8726ZM58.1091 23.8726C58.1091 22.1877 57.2383 21.0799 55.9097 21.0799C54.6045 21.0799 53.7265 22.211 53.7265 23.8726C53.7265 25.5495 54.6045 26.6726 55.9097 26.6726C57.2383 26.6726 58.1091 25.5729 58.1091 23.8726Z"
          fill="white"
        />
        <path
          d="M65.9191 24.8529C66.0457 25.9849 67.1454 26.7281 68.6481 26.7281C70.088 26.7281 71.124 25.9849 71.124 24.9642C71.124 24.0781 70.4992 23.5476 69.0198 23.184L67.5404 22.8277C65.4443 22.3214 64.4712 21.3411 64.4712 19.7504C64.4712 17.7808 66.1876 16.428 68.6248 16.428C71.0369 16.428 72.6905 17.7808 72.7461 19.7504H71.0216C70.9184 18.6112 69.9767 17.9235 68.6005 17.9235C67.2244 17.9235 66.2827 18.6193 66.2827 19.6319C66.2827 20.4389 66.8842 20.9138 68.3555 21.2773L69.6131 21.5861C71.9552 22.14 72.9283 23.0808 72.9283 24.7505C72.9283 26.8861 71.2272 28.2237 68.5215 28.2237C65.99 28.2237 64.2808 26.9175 64.1704 24.8528L65.9191 24.8529Z"
          fill="white"
        />
        <path
          d="M76.6154 17.7415V19.711H78.1981V21.0638H76.6154V25.6519C76.6154 26.3647 76.9323 26.6969 77.628 26.6969C77.8159 26.6936 78.0035 26.6804 78.19 26.6573V28.0021C77.8772 28.0605 77.5593 28.087 77.2411 28.0811C75.5562 28.0811 74.8991 27.4482 74.8991 25.8341V21.0638H73.689V19.711H74.899V17.7415H76.6154Z"
          fill="white"
        />
        <path
          d="M79.1143 23.8727C79.1143 21.2541 80.6565 19.6086 83.0614 19.6086C85.4744 19.6086 87.0095 21.2541 87.0095 23.8727C87.0095 26.4985 85.4825 28.1367 83.0614 28.1367C80.6412 28.1367 79.1143 26.4984 79.1143 23.8727ZM85.2688 23.8727C85.2688 22.0764 84.4457 21.0162 83.0614 21.0162C81.6772 21.0162 80.8549 22.0845 80.8549 23.8727C80.8549 25.6762 81.6772 26.7283 83.0614 26.7283C84.4457 26.7283 85.2688 25.6762 85.2688 23.8727Z"
          fill="white"
        />
        <path
          d="M88.418 19.7109H90.0473V21.1274H90.0868C90.1971 20.685 90.4562 20.294 90.8208 20.0202C91.1854 19.7463 91.6331 19.6064 92.0887 19.6238C92.2855 19.6231 92.4819 19.6445 92.674 19.6875V21.2854C92.4254 21.2095 92.1662 21.1746 91.9064 21.1822C91.6582 21.1721 91.4108 21.2159 91.1812 21.3104C90.9515 21.405 90.745 21.5482 90.5759 21.7301C90.4068 21.912 90.279 22.1283 90.2014 22.3643C90.1238 22.6002 90.0982 22.8502 90.1263 23.0969V28.0334H88.418L88.418 19.7109Z"
          fill="white"
        />
        <path
          d="M100.55 25.5891C100.321 27.0999 98.8493 28.1367 96.9668 28.1367C94.5457 28.1367 93.043 26.5146 93.043 23.9122C93.043 21.3017 94.5538 19.6086 96.895 19.6086C99.1976 19.6086 100.646 21.1904 100.646 23.7138V24.2991H94.7674V24.4023C94.7403 24.7086 94.7787 25.0172 94.8799 25.3075C94.9812 25.5978 95.1431 25.8633 95.3549 26.0863C95.5666 26.3092 95.8234 26.4846 96.1081 26.6007C96.3928 26.7168 96.699 26.771 97.0063 26.7597C97.41 26.7975 97.8151 26.704 98.1614 26.4931C98.5077 26.2822 98.7766 25.9651 98.9283 25.5891L100.55 25.5891ZM94.7755 23.1052H98.9363C98.9517 22.8298 98.9099 22.5542 98.8137 22.2957C98.7175 22.0372 98.5689 21.8013 98.3772 21.603C98.1855 21.4047 97.9549 21.2481 97.6998 21.1431C97.4448 21.0381 97.1707 20.987 96.895 20.9929C96.6168 20.9912 96.341 21.0447 96.0836 21.1502C95.8262 21.2556 95.5922 21.411 95.3951 21.6074C95.1981 21.8038 95.0419 22.0373 94.9355 22.2943C94.8292 22.5514 94.7748 22.827 94.7755 23.1052Z"
          fill="white"
        />
        <path
          d="M34.7712 8.02576C35.1294 8.00005 35.4888 8.05416 35.8235 8.18417C36.1582 8.31418 36.4599 8.51686 36.7068 8.77758C36.9537 9.0383 37.1396 9.35056 37.2512 9.69185C37.3628 10.0331 37.3973 10.3949 37.3521 10.7512C37.3521 12.5035 36.4051 13.5107 34.7712 13.5107H32.79V8.02576H34.7712ZM33.6419 12.735H34.6761C34.932 12.7503 35.1881 12.7084 35.4258 12.6122C35.6634 12.516 35.8767 12.3681 36.0499 12.1791C36.2232 11.9901 36.3522 11.7649 36.4274 11.5198C36.5027 11.2748 36.5223 11.016 36.4849 10.7623C36.5196 10.5097 36.498 10.2525 36.4216 10.0092C36.3451 9.76594 36.2159 9.54256 36.043 9.3551C35.8701 9.16764 35.6579 9.02074 35.4216 8.92492C35.1853 8.8291 34.9307 8.78674 34.6761 8.80087H33.6419V12.735Z"
          fill="white"
        />
        <path
          d="M38.3144 11.4394C38.2884 11.1674 38.3195 10.893 38.4058 10.6337C38.492 10.3744 38.6315 10.136 38.8153 9.93377C38.9991 9.73156 39.2232 9.56998 39.473 9.45942C39.7229 9.34886 39.9932 9.29175 40.2664 9.29175C40.5397 9.29175 40.81 9.34886 41.0599 9.45942C41.3097 9.56998 41.5338 9.73156 41.7176 9.93377C41.9014 10.136 42.0409 10.3744 42.1271 10.6337C42.2134 10.893 42.2445 11.1674 42.2185 11.4394C42.245 11.7117 42.2143 11.9866 42.1282 12.2463C42.0422 12.506 41.9028 12.7448 41.7189 12.9474C41.5351 13.1501 41.3109 13.312 41.0607 13.4228C40.8106 13.5336 40.54 13.5908 40.2664 13.5908C39.9929 13.5908 39.7223 13.5336 39.4722 13.4228C39.222 13.312 38.9978 13.1501 38.814 12.9474C38.6301 12.7448 38.4907 12.506 38.4047 12.2463C38.3186 11.9866 38.2879 11.7117 38.3144 11.4394ZM41.3782 11.4394C41.3782 10.5422 40.9752 10.0175 40.2678 10.0175C39.5577 10.0175 39.1583 10.5422 39.1583 11.4395C39.1583 12.3439 39.5577 12.8645 40.2678 12.8645C40.9752 12.8645 41.3782 12.3403 41.3782 11.4394Z"
          fill="white"
        />
        <path
          d="M47.4079 13.5109H46.5605L45.705 10.4623H45.6403L44.7884 13.5109H43.9491L42.8081 9.37158H43.6367L44.3782 12.5301H44.4392L45.2902 9.37158H46.0739L46.9249 12.5301H46.9896L47.7275 9.37158H48.5444L47.4079 13.5109Z"
          fill="white"
        />
        <path
          d="M49.5044 9.37157H50.2908V10.0291H50.3518C50.4554 9.79296 50.63 9.59498 50.8515 9.46279C51.0729 9.3306 51.33 9.27078 51.5871 9.29167C51.7885 9.27653 51.9907 9.3069 52.1788 9.38052C52.3668 9.45415 52.5359 9.56915 52.6735 9.71701C52.8111 9.86486 52.9137 10.0418 52.9736 10.2347C53.0336 10.4275 53.0493 10.6314 53.0198 10.8312V13.5108H52.2029V11.0364C52.2029 10.3712 51.9138 10.0404 51.3097 10.0404C51.1729 10.034 51.0364 10.0573 50.9095 10.1086C50.7826 10.1599 50.6683 10.2381 50.5744 10.3377C50.4805 10.4373 50.4093 10.5561 50.3656 10.6858C50.3219 10.8155 50.3068 10.9532 50.3213 11.0893V13.5108H49.5044L49.5044 9.37157Z"
          fill="white"
        />
        <path d="M54.3213 7.75562H55.1382V13.5107H54.3213V7.75562Z" fill="white" />
        <path
          d="M56.2739 11.4393C56.2479 11.1672 56.279 10.8928 56.3653 10.6335C56.4516 10.3742 56.5911 10.1358 56.7749 9.93354C56.9587 9.73132 57.1828 9.56974 57.4327 9.45918C57.6826 9.34862 57.9528 9.2915 58.2261 9.2915C58.4994 9.2915 58.7697 9.34862 59.0196 9.45918C59.2695 9.56974 59.4935 9.73132 59.6774 9.93354C59.8612 10.1358 60.0007 10.3742 60.087 10.6335C60.1733 10.8928 60.2044 11.1672 60.1784 11.4393C60.2049 11.7116 60.1741 11.9864 60.088 12.2461C60.002 12.5058 59.8625 12.7447 59.6787 12.9473C59.4948 13.1499 59.2706 13.3118 59.0204 13.4226C58.7703 13.5334 58.4997 13.5906 58.2261 13.5906C57.9525 13.5906 57.682 13.5334 57.4318 13.4226C57.1816 13.3118 56.9574 13.1499 56.7736 12.9473C56.5897 12.7447 56.4503 12.5058 56.3642 12.2461C56.2782 11.9864 56.2474 11.7116 56.2739 11.4393ZM59.3377 11.4393C59.3377 10.542 58.9346 10.0173 58.2272 10.0173C57.5172 10.0173 57.1177 10.542 57.1177 11.4393C57.1177 12.3437 57.5172 12.8644 58.2272 12.8644C58.9346 12.8644 59.3377 12.3401 59.3377 11.4393Z"
          fill="white"
        />
        <path
          d="M61.0381 12.3401C61.0381 11.595 61.5929 11.1654 62.5776 11.1044L63.6989 11.0398V10.6825C63.6989 10.2453 63.4098 9.99844 62.8514 9.99844C62.3954 9.99844 62.0794 10.1659 61.9887 10.4585H61.1979C61.2814 9.74753 61.9501 9.2915 62.8891 9.2915C63.9269 9.2915 64.5122 9.80812 64.5122 10.6825V13.5107H63.7258V12.929H63.6612C63.53 13.1376 63.3457 13.3077 63.1273 13.4219C62.9089 13.5361 62.6641 13.5903 62.4178 13.5789C62.2441 13.5969 62.0684 13.5784 61.9023 13.5244C61.7361 13.4705 61.5831 13.3823 61.4531 13.2656C61.3231 13.1489 61.219 13.0062 61.1475 12.8468C61.0761 12.6874 61.0388 12.5148 61.0381 12.3401ZM63.6989 11.9864V11.6403L62.688 11.705C62.118 11.7431 61.8595 11.937 61.8595 12.3019C61.8595 12.6745 62.1826 12.8912 62.627 12.8912C62.7572 12.9044 62.8888 12.8913 63.0138 12.8526C63.1388 12.8139 63.2548 12.7505 63.3548 12.666C63.4548 12.5816 63.5368 12.4779 63.5959 12.3612C63.655 12.2444 63.69 12.1169 63.6989 11.9864Z"
          fill="white"
        />
        <path
          d="M65.5859 11.4393C65.5859 10.1314 66.2583 9.30281 67.3041 9.30281C67.5628 9.29089 67.8196 9.35285 68.0443 9.48144C68.2691 9.61004 68.4526 9.79996 68.5735 10.029H68.6345V7.75562H69.4514V13.5107H68.6686V12.8568H68.604C68.4738 13.0843 68.2839 13.2718 68.0548 13.3991C67.8257 13.5264 67.5661 13.5886 67.3041 13.579C66.2511 13.579 65.5859 12.7504 65.5859 11.4393ZM66.4298 11.4393C66.4298 12.3173 66.8436 12.8456 67.5358 12.8456C68.2243 12.8456 68.6498 12.3096 68.6498 11.4429C68.6498 10.5802 68.2198 10.0367 67.5358 10.0367C66.8481 10.0367 66.4298 10.5686 66.4298 11.4393Z"
          fill="white"
        />
        <path
          d="M72.8315 11.4394C72.8055 11.1674 72.8366 10.893 72.9229 10.6337C73.0091 10.3744 73.1486 10.136 73.3324 9.93377C73.5162 9.73156 73.7402 9.56998 73.9901 9.45942C74.24 9.34886 74.5103 9.29175 74.7835 9.29175C75.0568 9.29175 75.327 9.34886 75.5769 9.45942C75.8268 9.56998 76.0509 9.73156 76.2347 9.93377C76.4185 10.136 76.558 10.3744 76.6442 10.6337C76.7305 10.893 76.7616 11.1674 76.7356 11.4394C76.7621 11.7117 76.7313 11.9866 76.6453 12.2463C76.5593 12.506 76.4199 12.7448 76.236 12.9474C76.0522 13.1501 75.828 13.312 75.5778 13.4228C75.3277 13.5336 75.0571 13.5908 74.7835 13.5908C74.51 13.5908 74.2394 13.5336 73.9892 13.4228C73.7391 13.312 73.5149 13.1501 73.3311 12.9474C73.1472 12.7448 73.0078 12.506 72.9218 12.2463C72.8357 11.9866 72.805 11.7117 72.8315 11.4394ZM75.8953 11.4394C75.8953 10.5422 75.4923 10.0175 74.7849 10.0175C74.0748 10.0175 73.6754 10.5422 73.6754 11.4395C73.6754 12.3439 74.0748 12.8645 74.7849 12.8645C75.4923 12.8645 75.8953 12.3403 75.8953 11.4394Z"
          fill="white"
        />
        <path
          d="M77.8315 9.37157H78.6179V10.0291H78.679C78.7825 9.79296 78.9572 9.59498 79.1786 9.46279C79.4 9.3306 79.6572 9.27078 79.9142 9.29167C80.1156 9.27653 80.3178 9.3069 80.5059 9.38052C80.694 9.45415 80.8631 9.56915 81.0007 9.71701C81.1383 9.86486 81.2408 10.0418 81.3008 10.2347C81.3607 10.4275 81.3765 10.6314 81.3469 10.8312V13.5108H80.53V11.0364C80.53 10.3712 80.241 10.0404 79.6368 10.0404C79.5001 10.034 79.3636 10.0573 79.2367 10.1086C79.1097 10.1599 78.9954 10.2381 78.9016 10.3377C78.8077 10.4373 78.7365 10.5561 78.6928 10.6858C78.6491 10.8155 78.634 10.9532 78.6484 11.0893V13.5108H77.8315V9.37157Z"
          fill="white"
        />
        <path
          d="M85.9627 8.34082V9.39022H86.8595V10.0783H85.9627V12.2067C85.9627 12.6403 86.1414 12.8302 86.548 12.8302C86.6521 12.8299 86.7561 12.8236 86.8595 12.8113V13.4918C86.7128 13.518 86.5642 13.532 86.4151 13.5335C85.5067 13.5335 85.1449 13.214 85.1449 12.4159V10.0783H84.4878V9.39019H85.1449V8.34082H85.9627Z"
          fill="white"
        />
        <path
          d="M87.9756 7.75562H88.7853V10.0367H88.8499C88.9585 9.79829 89.138 9.59919 89.3638 9.4665C89.5897 9.33381 89.851 9.27398 90.1121 9.29517C90.3124 9.28426 90.5127 9.31772 90.6986 9.39313C90.8846 9.46855 91.0516 9.58407 91.1878 9.73146C91.3239 9.87885 91.4259 10.0545 91.4863 10.2458C91.5468 10.4371 91.5643 10.6394 91.5376 10.8383V13.5107H90.7199V11.0398C90.7199 10.3787 90.4119 10.0438 89.8347 10.0438C89.6943 10.0323 89.5531 10.0516 89.421 10.1004C89.2888 10.1492 89.1689 10.2262 89.0696 10.3262C88.9703 10.4261 88.8941 10.5466 88.8462 10.6791C88.7984 10.8116 88.78 10.9529 88.7925 11.0932V13.5107H87.9756L87.9756 7.75562Z"
          fill="white"
        />
        <path
          d="M96.3005 12.3931C96.1895 12.7714 95.949 13.0986 95.6211 13.3176C95.2933 13.5365 94.8988 13.6331 94.5069 13.5906C94.2342 13.5978 93.9632 13.5456 93.7127 13.4376C93.4622 13.3295 93.2382 13.1683 93.0563 12.965C92.8744 12.7617 92.7388 12.5213 92.6592 12.2604C92.5795 11.9995 92.5575 11.7244 92.5948 11.4541C92.5585 11.1831 92.5808 10.9073 92.6603 10.6457C92.7398 10.384 92.8746 10.1424 93.0556 9.93737C93.2366 9.73232 93.4595 9.56853 93.7093 9.45711C93.959 9.3457 94.2298 9.28924 94.5033 9.29157C95.655 9.29157 96.3499 10.0784 96.3499 11.3782V11.6633H93.427V11.7091C93.4142 11.861 93.4333 12.0139 93.483 12.158C93.5328 12.3021 93.6121 12.4342 93.7159 12.5458C93.8197 12.6574 93.9456 12.7462 94.0857 12.8063C94.2258 12.8664 94.3769 12.8966 94.5293 12.8949C94.7247 12.9184 94.9227 12.8832 95.0981 12.7938C95.2734 12.7045 95.4182 12.565 95.5141 12.3931L96.3005 12.3931ZM93.4269 11.0591H95.5177C95.528 10.9202 95.5091 10.7807 95.4622 10.6495C95.4154 10.5183 95.3416 10.3983 95.2457 10.2974C95.1497 10.1964 95.0337 10.1166 94.9051 10.0631C94.7764 10.0096 94.6381 9.98365 94.4988 9.98684C94.3575 9.98507 94.2173 10.0116 94.0864 10.0649C93.9555 10.1181 93.8367 10.197 93.7368 10.297C93.6369 10.3969 93.558 10.5158 93.5048 10.6467C93.4516 10.7776 93.4251 10.9178 93.4269 11.0591Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1465_142">
          <rect width="110" height="36.7696" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
