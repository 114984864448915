import React from 'react';

export const ChevronCircle: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46">
    <circle cx="23" cy="23" r="22.375" stroke={color} strokeWidth="1.25" />
    <path
      d="M16.3335 20.5001L23.0002 27.1667L29.6668 20.5001"
      stroke={color}
      strokeWidth="1.25"
      strokeMiterlimit="10"
    />
  </svg>
);
