import React from 'react';

export const Nike: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103 71">
    <path
      d="M31.1455 40.8318C30.6562 40.8318 30.2681 41.1693 30.2681 41.6755 30.2681 42.1817 30.6562 42.5191 31.1455 42.5191 31.6348 42.5191 32.0229 42.1817 32.0229 41.6755 32.0229 41.1861 31.6348 40.8318 31.1455 40.8318zM31.1455 42.2829C30.7911 42.2829 30.538 42.0298 30.538 41.6755 30.538 41.338 30.7911 41.068 31.1455 41.068 31.4998 41.068 31.7529 41.3211 31.7529 41.6755 31.7529 42.0129 31.4829 42.2829 31.1455 42.2829zM39.6329 37.8953H35.3808L35.8701 30.5048 33.1029 37.8953H29.1714L35.4314 21.1907H39.6329L39.1435 28.6824 41.9614 21.1907H45.9097L39.6329 37.8953zM40.1558 37.8953L46.4158 21.1907H50.3641L44.1041 37.8953H40.1558z"
      fill={color}
    />
    <path
      d="M28.7157 32.142C23.1981 38.6214 18.0011 47.2437 22.5907 51.2089C27.0452 55.056 32.6134 52.7781 36.4099 51.2595C40.2064 49.7409 82.1536 31.5177 82.1536 31.5177C82.5585 31.3152 82.4741 31.0621 81.9848 31.1971C81.7823 31.2477 36.3087 43.5653 36.3087 43.5653C30.538 45.1851 23.9574 41.6417 28.7157 32.142Z"
      fill={color}
    />
    <path
      d="M31.2972 41.7423C31.4153 41.7423 31.5166 41.6748 31.5166 41.5061 31.5166 41.2867 31.3816 41.2361 31.1285 41.2361H30.791V42.1472H31.0272V41.7592H31.061L31.2635 42.1472H31.5334L31.2972 41.7423zM31.1454 41.5904H31.0104V41.3879H31.1285C31.196 41.3879 31.2803 41.3879 31.2803 41.4892 31.2635 41.5736 31.2297 41.5904 31.1454 41.5904zM70.8144 21.1401C70.3251 21.1401 69.937 21.4776 69.937 21.9838 69.937 22.49 70.3251 22.8275 70.8144 22.8275 71.3037 22.8275 71.6918 22.49 71.6918 21.9838 71.6918 21.4776 71.3037 21.1401 70.8144 21.1401zM70.8144 22.5744C70.4601 22.5744 70.207 22.3213 70.207 21.9669 70.207 21.6295 70.4601 21.3595 70.8144 21.3595 71.1688 21.3595 71.4219 21.6126 71.4219 21.9669 71.4219 22.3213 71.1688 22.5744 70.8144 22.5744z"
      fill={color}
    />
    <path
      d="M70.9666 22.0353C71.0848 22.0353 71.186 21.9678 71.186 21.799 71.186 21.5797 71.051 21.5291 70.7979 21.5291H70.4604V22.4402H70.6967V22.0521H70.7304L70.9329 22.4402H71.2029L70.9666 22.0353zM70.8317 21.9003H70.6967V21.6809H70.8148C70.8823 21.6809 70.9666 21.6809 70.9666 21.7822 70.9498 21.8834 70.8992 21.9003 70.8317 21.9003zM68.2161 23.9748L69.2791 21.1907H61.3655 61.3318 57.2315L53.0976 26.2021 55.0043 21.1907H51.0053L44.7959 37.8953H48.7443L51.3934 30.9097 51.579 37.8953 63.694 37.8615 64.8414 34.6556H60.235L61.7536 30.606H65.5501L66.7144 27.5013H62.9179L64.2171 23.9916H68.2161V23.9748zM55.4936 36.7816L55.2405 28.075 61.2136 21.5281 55.4936 36.7816z"
      fill={color}
    />
  </svg>
);
