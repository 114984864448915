import React from 'react';

import css from '@styled-system/css';
import {Link} from 'react-router-dom';
import {Anchor} from 'src/components/controls';
import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {colors} from 'src/styleguide/colors';
import styled from 'styled-components/macro';

const {H3, H5, P1, P3} = Typography;

type TextHeaderProps = {
  text: string;
  link?: {text: string; path?: string; icon?: React.ReactNode; onClick?: () => void; color?: string};
  additionalInfo?: string;
  variant?: 'primary' | 'secondary';
};

const Wrapper = styled(Flex).attrs({
  borderBottom: '1px solid',
  borderColor: 'lineGray',
  py: 'S',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  mb: '1rem',
})`
  grid-area: header;
`;

const StyledLink = styled(Link)(
  css({
    color: 'darkGold',
  }),
);

export const TextHeader: React.FC<TextHeaderProps> = ({text, link, additionalInfo, variant}) => {
  const primary = variant === 'primary';
  return (
    <Wrapper>
      {primary ? <H3 pt={'L'}>{text}</H3> : <H5>{text}</H5>}
      {additionalInfo && (
        <P3 color={colors.darkestGray} width={['100%', null, null, null, 'auto']}>
          {additionalInfo}
        </P3>
      )}
      {link &&
        (link.onClick ? (
          <Anchor onClick={link.onClick} color={link.color ?? ''} variant="text">
            <Flex alignItems={'center'}>
              {link.text}
              {link.icon && link.icon}
            </Flex>
          </Anchor>
        ) : (
          <StyledLink to={link.path ?? ''}>
            <P1>
              {link.text} {link.icon && link.icon}
            </P1>
          </StyledLink>
        ))}
    </Wrapper>
  );
};

TextHeader.defaultProps = {
  variant: 'primary',
};
