import React from 'react';

export const WallStreetJournal: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 20">
    <path
      d="M8.53069 0.0562148V0.449719L9.00027 0.505933C9.7829 0.618363 9.86116 0.955652 10.1742 2.02373L10.9568 4.9469L8.76548 13.4916L5.79148 2.75453C5.47842 1.57401 5.3219 0.674578 6.57411 0.505933L7.12195 0.449719V0.0562148H0V0.449719L0.704369 0.562148C1.40874 0.674578 1.72179 0.955652 2.26963 2.58588L7.12195 19.113H8.37416L11.583 6.63335L15.1831 19.113H16.2005L20.8963 3.14803C21.4441 1.29294 21.6789 0.787007 22.5398 0.618363L23.2442 0.505933V0.0562148H18.0005V0.449719L18.7832 0.562148C19.9571 0.730793 20.1919 1.23673 19.6441 3.37289L16.7483 13.5478L13.9308 2.75453C13.6178 1.68644 13.4613 0.674578 14.7135 0.505933L15.5744 0.393504V0.0562148H8.53069ZM42.5752 0.11243V0.505933L43.5143 0.674578C44.3752 0.787007 44.6883 1.01187 44.6883 1.79887V15.1218C44.6883 16.5834 44.6883 18.5509 42.5752 18.5509C41.323 18.5509 40.3838 17.7639 40.4621 17.2579C40.5403 16.4147 42.5752 16.4709 42.7317 15.0656C42.81 14.1099 42.0273 13.6602 41.1664 13.604C39.9925 13.4916 38.5055 14.2223 38.5055 15.6277C38.5055 17.4828 40.149 19.113 43.0448 19.113C46.1753 19.113 48.2884 17.5952 48.2884 14.6159V1.79887C48.2884 1.01187 48.5232 0.787007 49.3058 0.674578L50.0884 0.505933V0.11243H42.5752ZM29.6617 0C26.3747 0 24.5746 1.96752 24.5746 4.49719C24.5746 9.21923 33.1053 11.0743 33.1053 15.3466C33.1053 17.3704 31.9314 18.4947 30.2096 18.4947C27.6269 18.4947 25.8269 16.2461 25.3573 12.7045H24.8094V18.9444H25.279C25.6703 18.045 26.1399 17.9325 26.6095 17.9325C27.3139 17.9325 28.0965 19.0568 30.2879 19.0568C33.6532 19.0568 35.9228 16.9207 35.9228 14.0537C35.9228 8.37601 27.3921 6.97064 27.3921 2.92317C27.3921 1.96752 27.7834 0.618363 29.6617 0.618363C32.401 0.618363 33.5749 3.20424 34.0445 6.40849H34.5923V0.11243H34.1227C33.9662 0.730793 33.5749 1.01187 33.1053 1.01187C32.2444 1.01187 31.7749 0 29.6617 0ZM35.8445 5.39662V5.45284V5.39662Z"
      fill={color}
    />
  </svg>
);
