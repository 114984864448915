import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import {Image} from 'src/components/shared/Image';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {H1, H2, H3, H4, H5, H6, P4} = Typography;

export type EditorialContentType = typeof BlockContent;
const StyledContent = styled(BlockContent)``;

const LabelledImage = styled(Image).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})`
  object-fit: cover;
`;

export const EditorialContent: EditorialContentType = ({serializers, ...props}) => (
  <StyledContent
    {...props}
    serializers={{
      ...serializers,
      types: {
        labelledImage: ({node}) => {
          return <LabelledImage {...node} />;
        },
        block: ({children, node}) => {
          switch (node.style) {
            case 'h1':
              return <H1 mb="21px">{children}</H1>;
            case 'h2':
              return <H2 mb="21px">{children}</H2>;
            case 'h3':
              return <H3 mb="21px">{children}</H3>;
            case 'h4':
              return <H4 mb="21px">{children}</H4>;
            case 'h5':
              return <H5 mb="21px">{children}</H5>;
            case 'h6':
              return <H6 mb="21px">{children}</H6>;
            case 'blockquote':
              return (
                <P4 as="blockquote" mb="21px" pl="21px" borderLeft="2px solid" borderColor="lineGray">
                  {children}
                </P4>
              );
            default:
              return <P4 mb="21px">{children}</P4>;
          }
        },
        ...serializers?.types,
      },
    }}
  />
);
