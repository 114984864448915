import React from 'react';

export const LinkedInSocialIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5195 6H7.47656C6.66016 6 6 6.64453 6 7.44141V24.5547C6 25.3516 6.66016 26 7.47656 26H24.5195C25.3359 26 26 25.3516 26 24.5586V7.44141C26 6.64453 25.3359 6 24.5195 6ZM11.9336 23.043H8.96484V13.4961H11.9336V23.043ZM10.4492 12.1953C9.49609 12.1953 8.72656 11.4258 8.72656 10.4766C8.72656 9.52734 9.49609 8.75781 10.4492 8.75781C11.3984 8.75781 12.168 9.52734 12.168 10.4766C12.168 11.4219 11.3984 12.1953 10.4492 12.1953ZM23.043 23.043H20.0781V18.4023C20.0781 17.2969 20.0586 15.8711 18.5352 15.8711C16.9922 15.8711 16.7578 17.0781 16.7578 18.3242V23.043H13.7969V13.4961H16.6406V14.8008H16.6797C17.0742 14.0508 18.043 13.2578 19.4844 13.2578C22.4883 13.2578 23.043 15.2344 23.043 17.8047V23.043Z"
      fill="white"
    />
  </svg>
);
