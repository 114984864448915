import React from 'react';

import {useBreakpointValue} from 'src/hooks/useBreakpointValue';

export const ChiefLogoIcon: React.FC = () => {
  const maxWidth = useBreakpointValue([120, 120, 120, 155]);
  return (
    <svg style={{maxWidth}} viewBox={`0 0 155 30`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.6706 28.7115H80.7892V1.2056H76.6706V28.7115ZM59.6753 1.2056H63.7939V28.7115H59.6753V17.059H42.5233V28.7115H38.4034V1.2056H42.5233V12.8581H59.6791L59.6753 1.2056ZM93.6684 1.2056V28.7115H118.034V24.5132H97.7781V17.059H114.546V12.8606H97.7781V5.40273H118.021V1.2056H93.6659H93.6684ZM134.754 5.40273V12.8581H151.522V17.0565H134.754V28.709H130.636V1.20307H155V5.40273H134.754ZM24.2689 19.5089C23.4177 21.3664 22.0646 22.9381 20.3683 24.0399C18.6719 25.1418 16.7026 25.7281 14.6909 25.7301C11.8897 25.7257 9.20441 24.5899 7.22363 22.5714C5.24286 20.5527 4.12811 17.8161 4.12374 14.9614C4.12846 12.1068 5.24329 9.37001 7.224 7.35147C9.20471 5.33293 11.8898 4.19687 14.6909 4.19206C16.7032 4.19468 18.6731 4.78179 20.3696 5.88485C22.066 6.98791 23.4186 8.56157 24.2689 10.4203L28.0017 8.6464C26.8187 6.06507 24.9382 3.88109 22.5808 2.34976C20.2234 0.818428 17.4866 0.0030943 14.6909 0C10.7979 0.00411506 7.06526 1.581 4.31138 4.38529C1.55751 7.18957 0.00707557 10.9928 0 14.9601C0.00404433 18.9295 1.55314 22.7352 4.30735 25.542C7.06154 28.3488 10.7959 29.9269 14.6909 29.931C17.4866 29.928 20.2234 29.1132 22.5808 27.5819C24.9382 26.0506 26.8187 23.8667 28.0017 21.2853L24.2689 19.5089Z"
        fill="white"
      />
    </svg>
  );
};
