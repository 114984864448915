import React, {useState} from 'react';

import {themeGet} from '@styled-system/theme-get';
import ReactModal from 'react-modal';
import {Button} from 'src/components/controls';
import {zIndices} from 'src/styleguide/zIndices';
import {Icon, Icons} from 'src/svgs';
import {ModalPriority} from 'src/utils/constants';
import {isSSR} from 'src/utils/ssr';
import styled, {css, SimpleInterpolation, createGlobalStyle} from 'styled-components/macro';

const slideAnimation = css`
  .ReactModal__Body {
    will-change: transform;
    transition: transform 500ms ease;
    transform: translateX(-100%);

    &--after-open {
      transform: translateX(0);
    }

    &--before-close {
      transform: translateX(-100%);
    }
  }
`;

const fadeAnimation = css`
  .ReactModal__Overlay {
    will-change: opacity;
    transition: opacity 500ms ease;
    opacity: 0;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }
`;

const CloseButton = styled(Button).attrs({
  variant: 'icon',
  p: 'S',
  m: 'S',
})`
  position: absolute;
  right: 0;
`;

const mapAnimation = (animation: Animation): SimpleInterpolation => {
  switch (animation) {
    case 'fade':
      return fadeAnimation;
    case 'slide':
      return slideAnimation;
    case 'none':
    default:
      return undefined;
  }
};

type Animation = 'fade' | 'slide' | 'none';

const GlobalStyle = createGlobalStyle`
  .ReactModal__Body {
    position: relative;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Html--open {
    overflow: hidden;
  }
`;

interface ReactModalAdapterProps extends Omit<ReactModal.Props, 'className'> {
  id: string;
  className?: string;
  modalClassName?: string | ReactModal.Classes;
  children?:
    | ((renderProps: {
        overlayRef: HTMLDivElement | undefined;
        contentRef: HTMLDivElement | undefined;
      }) => React.ReactNode)
    | React.ReactNode;
  showCloseButton?: boolean;
  isOpenPending?: boolean;
  closeDelayDuration?: number;
  priority?: ModalPriority;
  visible?: boolean;
}

const ReactModalAdapter = ({
  id,
  className,
  modalClassName,
  children,
  showCloseButton = false,
  isOpen,
  isOpenPending = false,
  priority = ModalPriority.medium,
  onAfterClose,
  closeDelayDuration,
  ...props
}: ReactModalAdapterProps): React.ReactElement => {
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement>();
  const [contentRef, setContentRef] = useState<HTMLDivElement>();

  const appElement = !isSSR ? document.getElementById('root') ?? undefined : undefined;

  const handleOnAfterClose = () => {
    onAfterClose?.();
  };

  return (
    <>
      <GlobalStyle />
      <ReactModal
        className={modalClassName}
        portalClassName={className}
        closeTimeoutMS={500}
        appElement={appElement}
        overlayRef={setOverlayRef}
        contentRef={setContentRef}
        isOpen={isOpen}
        onAfterClose={handleOnAfterClose}
        {...props}
      >
        {showCloseButton && (
          <CloseButton onClick={(event: React.MouseEvent) => props?.onRequestClose?.(event)}>
            <Icon aria-label="close modal icon" icon={Icons.close} size={10} />
          </CloseButton>
        )}
        {typeof children === 'function' ? children({overlayRef, contentRef}) : children}
      </ReactModal>
    </>
  );
};

export type ModalProps = Omit<ReactModal.Props, 'onRequestClose' | 'className' | 'overlayClassName'> & {
  overlayCSS?: SimpleInterpolation;
  modalCSS?: SimpleInterpolation;
  animation?: Animation;
  onRequestClose: () => void;
  isOpenPending?: boolean;
  priority?: ModalPriority;
  visible?: boolean;
};

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'ReactModal__Body',
    afterOpen: 'ReactModal__Body--after-open',
    beforeClose: 'ReactModal__Body--before-close',
  },
  overlayClassName: {
    base: 'ReactModal__Overlay',
    afterOpen: 'ReactModal__Overlay--after-open',
    beforeClose: 'ReactModal__Overlay--before-close',
  },
})<ModalProps & {id: string}>`
  ${({animation = 'fade'}): SimpleInterpolation => mapAnimation(animation)}

  .ReactModal__Body {
    background: ${themeGet('colors.cream')};
    outline: none;
    z-index: ${zIndices.modal};

    ${({modalCSS}): SimpleInterpolation => modalCSS};
  }

  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: ${zIndices.modalBackdrop};
    background: rgba(0, 0, 0, 0.35);

    ${({overlayCSS}): SimpleInterpolation => overlayCSS};

    ${({visible = true}) => !visible && {opacity: 0, pointerEvents: 'none'}};
  }
`;
