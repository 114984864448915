import {useState, useEffect} from 'react';

import debounce from 'lodash/debounce';
import {breakpointValues} from 'src/styleguide/defaultTheme';
import {isSSR} from 'src/utils/ssr';

function getValue<T>(width: number, values: T[]) {
  let lastNonNullValue;
  const breakpointIndex = values.findIndex((value, index) => {
    if (value !== null) {
      lastNonNullValue = value;
    }
    if (width <= breakpointValues[index]) {
      return true;
    }
    return false;
  });
  if (breakpointIndex === -1) {
    return values[values.length - 1];
  }

  const value = values[breakpointIndex];
  if (value === null) {
    return lastNonNullValue;
  } else {
    return value;
  }
}

/**
 * useBreakpointValue Hook:
 *
 * Utility to get values with respect to current breakpoint.
 * This follows the same API as ResponsiveValue from styled-system.
 *
 * @param values Array of values corresponding to the breakpoints (Same as styled-system ResponsiveValue)
 * @returns value or undefined
 */
export const useBreakpointValue: <T>(values: T[]) => T | undefined = <T>(values: T[]) => {
  const innerWidth = !isSSR ? window.innerWidth : 0;
  const [breakpointValue, setBreakpointValue] = useState(() => getValue<T>(innerWidth, values));

  useEffect(() => {
    const calcValue = debounce(function () {
      const width = !isSSR ? window.innerWidth : 0;
      setBreakpointValue(getValue(width, values));
    }, 50);
    calcValue();
    if (!isSSR) window?.addEventListener('resize', calcValue);
    return () => {
      if (!isSSR) window?.removeEventListener('resize', calcValue);
    };
  }, [values]);

  return breakpointValue;
};
