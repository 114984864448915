import React from 'react';

export const ArrowSmall: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M15.0005 10.25L3.84424 10.25M10.9437 14.5L15.0005 10.25 10.9437 6" stroke={color} strokeWidth="1.25" />
  </svg>
);

ArrowSmall.defaultProps = {
  color: 'black',
};
