import React, {useEffect, useState} from 'react';

import {useStaticQuery, graphql} from 'gatsby';
import {CATEGORY, TOPIC} from 'src/routes/paths';
import {Chevron} from 'src/svgs/chevron';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NavDropdownProps = any;

export const NavDropdown: React.FC<NavDropdownProps> = (props: NavDropdownProps) => {
  const data = useStaticQuery(graphql`
    query NavItemsMobile {
      allSanityTopic(sort: {order: ASC, fields: title}) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            hideFromNav
            relatedCategories {
              id
              title
              slug {
                current
              }
              hideFromNav
            }
          }
        }
      }
      allSanitySeries {
        edges {
          node {
            id
            title
            slug {
              current
            }
            heroImage {
              altText
            }
            seriesIntroduction
            _rawHeroImage(resolveReferences: {maxDepth: 4})
          }
        }
      }
    }
  `);

  const topicsArray = data.allSanityTopic.edges;
  const hasTopics = topicsArray.length > 0;

  const [openNav, setOpenNav] = React.useState('');

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    e.preventDefault();
    setOpenNav(id === openNav ? '' : id);
  };

  return hasTopics ? (
    <ul className="px-5 bg-green-dark text-white pt-10 pb-4">
      {topicsArray?.map(
        (
          topic: {
            node: {
              id: string;
              slug: {current: string};
              title: string | null | undefined;
              relatedCategories: any[];
              hideFromNav: boolean;
            };
          },
          i: number,
        ) =>
          topic.node.hideFromNav != true ? (
            <li
              key={topic.node.id || i}
              className="first:border-t first:border-light-goldpy-4 static border-b border-light-gold flex flex-col !text-white w-full type-lota uppercase"
              data-nav-parent={i}
            >
              <div className="flex justify-center items-center">
                <a
                  href={`${TOPIC}/${topic.node.slug.current}`}
                  target="_self"
                  className="cursor-pointermr-5 flex items-center justify-between w-full min-w-max lg:max-w-max py-5 transition-all duration-500 sm:hover:bg-ivory sm:border-transparent"
                >
                  <span>{topic.node.title}</span>
                </a>
                <div onClick={event => handleOpen(event, topic.node.id)} className="p-5">
                  <Chevron classes={'fill-white w-[9px] h-[7px] ml-2 transition-all linear duration-300'} />
                </div>
              </div>
              {/* Topics Dropdown Desktop */}
              {openNav == topic.node.id ? (
                <div data-mobile-dropdown="{{ loop.index }}" className="pb-4">
                  <ul className="w-full flex space-y-6 flex-col justify-start pb-2 pl-4">
                    {topic.node.relatedCategories?.map(category =>
                      category.hideFromNav != true ? (
                        <li className="type-lota text-white capitalize" key={category.id}>
                          <a href={`${CATEGORY}/${category.slug.current}`}>{category.title}</a>
                        </li>
                      ) : null,
                    )}
                  </ul>
                </div>
              ) : null}
            </li>
          ) : null,
      )}
    </ul>
  ) : (
    <> </>
  );
};
