import React from 'react';

import styled from 'styled-components/macro';

import {Flex} from './Flex';

interface SpinnerProps {
  size?: number;
  color?: 'light' | 'dark';
}

export const CenteredSpinner: React.FC<{top?: number}> = ({top}) => (
  <Flex position="fixed" left="calc(50% - 20px)" top="50%">
    <Spinner />
  </Flex>
);

export const Spinner = styled.div<SpinnerProps>`
  --size: ${({size = 40}): string => `${size}px`};
  position: relative;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  height: var(--size);
  width: var(--size);

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border-style: solid;
    border-color: ${({theme}): string => theme.colors.lightGray};
    border-color: ${({theme, color}): string => (color === 'dark' ? theme.colors.emptyGray : theme.colors.lightGray)};
    border-width: ${({size}): string => (!size || size > 25 ? '3px' : '2px')};
    border-top-color: ${({theme}): string => theme.colors.lightGreen};
    animation: spinner 0.8s linear infinite;
  }
`;
