import React from 'react';

import {Box} from 'src/components/shared/Box';
import {Image} from 'src/components/shared/Image';

export interface HeroImageProps {
  altText?: string | undefined;
  src: string | undefined | null;
  id: string | undefined | null;
}

export const HeroImage: React.FC<HeroImageProps> = (props: HeroImageProps) => {
  return (
    <div className="mt-5 md:mt-11">
      <Box width="100%">
        <Image width="100%" src={props.src || ''} alt={props.altText} />
      </Box>
    </div>
  );
};
