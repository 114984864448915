import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Button, ButtonProps} from 'src/components/controls';
import {Icon, Icons} from 'src/svgs';
import styled from 'styled-components/macro';

export type ScrollButtonProps = ButtonProps & {'aria-label': string};

const Wrapper: typeof Button = styled(Button).attrs({
  variant: 'icon',
})`
  svg * {
    transition: fill 200ms ease, stroke 200ms ease;
  }

  &:focus {
    svg path {
      stroke: ${() => themeGet('colors.darkGold')};
    }
    svg circle {
      fill: transparent;
      stroke: ${() => themeGet('colors.darkGold')};
    }
  }

  &:active {
    svg path {
      stroke: ${() => themeGet('colors.white')};
    }
    svg circle {
      fill: ${() => themeGet('colors.darkGold')};
      stroke: ${() => themeGet('colors.darkGold')};
    }
  }

  &:hover {
    svg path {
      stroke: ${() => themeGet('colors.white')};
    }
    svg circle {
      fill: ${() => themeGet('colors.lightGold')};
      stroke: ${() => themeGet('colors.lightGold')};
    }
  }
`;

export const ScrollButton: React.FC<ScrollButtonProps> = ({...props}) => {
  return (
    <Wrapper {...props}>
      <Icon aria-label={props['aria-label']} icon={Icons.chevronCircle} color="lightGold" />
    </Wrapper>
  );
};

ScrollButton.defaultProps = {
  width: '46px',
  height: '46px',
};
