import {system} from '@styled-system/core';
import {ResponsiveValue} from 'styled-system';

const config = {
  transform: {
    property: 'transform',
    scale: 'transforms',
  },
};

export type TransformProps = Partial<{transform: ResponsiveValue<string>}>;
export const transform = system(config);
