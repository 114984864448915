import React from 'react';

export const Hbo: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99 71">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.701 40.0492C69.1964 40.0492 71.1989 38.0467 71.1989 35.5513C71.1989 33.0559 69.1964 31.0535 66.701 31.0535C64.2056 31.0535 62.2031 33.0559 62.2031 35.5513C62.2031 38.0159 64.2364 40.0492 66.701 40.0492Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.7629 23.7529C61.618 23.7529 57.2434 27.0493 55.6414 31.6704 55.703 31.2083 55.7338 30.7154 55.7338 30.1917 55.7338 28.4973 54.748 26.618 53.3617 25.6322 52.0678 24.6771 50.7431 24.1226 49.1411 24.1226H38.9438V47.3514H48.4941C50.3734 47.3514 52.0062 46.6428 53.6081 45.5029 55.0869 44.4247 56.0419 42.5454 56.0419 40.7278 56.0419 40.6354 56.0419 40.5737 56.0419 40.4813 57.8904 44.5479 61.9877 47.3514 66.7629 47.3514 73.2632 47.3514 78.5313 42.0833 78.5313 35.5521 78.5313 29.021 73.2632 23.7529 66.7629 23.7529zM44.8897 29.1442H47.3851C48.5865 29.1442 49.8805 29.3599 49.8805 30.9002 49.9729 32.2866 49.1411 33.1184 47.4467 33.1184H44.8897V29.1442zM49.5108 41.6212C48.987 42.0525 48.2785 42.0217 47.6623 42.0217H44.8897V37.7703H47.4159C48.9562 37.7703 50.2501 38.1708 50.3118 39.9576 50.3118 40.5121 49.9729 41.2823 49.5108 41.6212zM55.1177 37.1541C54.6556 36.538 54.0086 35.9834 53.0228 35.4289 54.1011 34.8744 54.8096 34.1658 55.2101 33.2724 55.0561 34.0118 54.9945 34.782 54.9945 35.5829 55.0253 36.0759 55.0561 36.6304 55.1177 37.1541zM66.7013 41.6828C63.3125 41.6828 60.5706 38.9409 60.5706 35.5521 60.5706 32.1633 63.3125 29.4215 66.7013 29.4215 70.0901 29.4215 72.8319 32.1633 72.8319 35.5521 72.8319 38.9409 70.0901 41.6828 66.7013 41.6828zM31.6424 32.6255H26.8364V24.061H20.8906V47.3514H26.8056V38.2632H31.6116V47.3514H37.619V24.061H31.6424V32.6255zM76.1593 46.0264C75.7588 46.0264 75.4507 46.3036 75.4507 46.7041 75.4507 47.1046 75.7588 47.3819 76.1593 47.3819 76.5597 47.3819 76.8678 47.1046 76.8678 46.7041 76.8678 46.3036 76.5597 46.0264 76.1593 46.0264zM76.1593 47.197C75.882 47.197 75.6663 46.9814 75.6663 46.7041 75.6663 46.4269 75.882 46.2112 76.1593 46.2112 76.4365 46.2112 76.6522 46.4269 76.6522 46.7041 76.6522 46.9814 76.4365 47.197 76.1593 47.197z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.2823 46.767C76.3748 46.767 76.4672 46.7053 76.4672 46.5821C76.4672 46.3973 76.3439 46.3665 76.1591 46.3665H75.8818V47.1058H76.0667V46.7978H76.0975L76.2823 47.1058H76.498L76.2823 46.767ZM76.1591 46.6437H76.0667V46.4589H76.1591C76.2207 46.4589 76.2823 46.4589 76.2823 46.5513C76.2515 46.6129 76.2207 46.6437 76.1591 46.6437Z"
      fill={color}
    />
  </svg>
);
