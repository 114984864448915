import React from 'react';

export const XSocialIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5013 6H25.9089L18.4644 14.4718L27.2222 26H20.3649L14.994 19.0082L8.84843 26H5.43882L13.4015 16.9385L5 6H12.0314L16.8863 12.3908L22.5013 6ZM21.3054 23.9692H23.1935L11.0055 7.9241H8.97925L21.3054 23.9692Z"
      fill="white"
    />
  </svg>
);
