import React from 'react';

export const PartyPopper: React.FC<{color: string}> = ({color}: {color: string}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
    <path
      d="M79.25 40C79.25 61.6772 61.6772 79.25 40 79.25C18.3228 79.25 0.75 61.6772 0.75 40C0.75 18.3228 18.3228 0.75 40 0.75C61.6772 0.75 79.25 18.3228 79.25 40Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M41.9701 29.1636L40.5928 28.0159C43.0343 25.0944 43.9108 20.5869 44.036 18.6045L45.8306 18.7088C45.6845 20.8791 44.7037 25.8874 41.9701 29.1636zM52.7165 41.9141L51.7148 40.4116C55.5545 37.8657 61.2306 36.8432 63.693 36.6763L63.8182 38.4709C61.5227 38.6379 56.2432 39.5769 52.7165 41.9141zM51.0062 33.232L49.7124 31.98C53.1556 28.4116 58.8317 25.5318 61.3567 24.5093L62.0244 26.1787C59.6246 27.1595 54.2407 29.8723 51.0062 33.232zM48.3146 37.6791C49.0871 37.6791 49.7133 37.0529 49.7133 36.2805 49.7133 35.508 49.0871 34.8818 48.3146 34.8818 47.5422 34.8818 46.916 35.508 46.916 36.2805 46.916 37.0529 47.5422 37.6791 48.3146 37.6791zM48.3146 27.8285C49.0871 27.8285 49.7133 27.2023 49.7133 26.4299 49.7133 25.6574 49.0871 25.0312 48.3146 25.0312 47.5422 25.0312 46.916 25.6574 46.916 26.4299 46.916 27.2023 47.5422 27.8285 48.3146 27.8285zM36.6286 26.43C37.401 26.43 38.0272 25.8039 38.0272 25.0314 38.0272 24.259 37.401 23.6328 36.6286 23.6328 35.8562 23.6328 35.23 24.259 35.23 25.0314 35.23 25.8039 35.8562 26.43 36.6286 26.43zM25.6271 53.3184L23.0435 59.3172 31.7159 55.5562C29.7541 55.2439 27.5909 54.5745 25.6271 53.3184zM26.4106 51.4993C29.6208 53.6863 33.5773 53.9847 35.7788 53.7943L52.3002 46.6295 50.5907 44.9135C49.453 45.0069 48.0571 45.0441 46.5001 44.9604 41.9732 44.752 36.0193 43.6841 31.0572 40.711L26.4106 51.4993zM32.0408 38.4273C37.2655 41.7041 43.8472 42.5671 48.2365 42.5505L35.6894 29.9561 32.0408 38.4273z"
      fill={color}
    />
  </svg>
);
