module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-parsely-analytics@1.1.0_gatsby@3.14.6_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-parsely-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apikey":"chief.com","enableInDevelopment":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@4.14.0_babel-plugi_cwl7btgmo2ab2ekue4q45x5cy4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.14.2_@babel+core@7.23.2_gats_jzsn6hn34j37i7weddsrok2b3m/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.2.0_gatsby@3.14.6_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cace641016e3ab7839cbfe7d4deb3447"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-segment-js@3.7.1_gatsby@3.14.6/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"B1DGDwhvuBaVEuh7zUXUfOs7yyNzKzdx","devKey":"W0n8TlURyuLeTFGbXoNLKX8vHA2cp244\n","trackPage":false,"delayLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
