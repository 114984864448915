import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, BoxProps, Flex} from 'src/components/shared';
import styled from 'styled-components/macro';

export type PointProps = BoxProps & {
  dotColor: string;
  dotSize?: string;
  pulseColor?: string;
  pulseSize?: string;
  pulseZIndex?: number;
  lineColor?: string;
  lineOpacity?: string;
  lineWidth?: string;
  animate?: boolean;
  animated?: boolean;
};

const Wrapper: typeof Box = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
})``;

const Dot = styled(Box).attrs({
  borderRadius: '1000px',
})<{animated: boolean}>`
  ${props => (props.animated ? 'transition: opacity 500ms cubic-bezier(0.85, 0, 0.15, 1) 250ms;' : '')}
`;

const DotPulse = styled(Box).attrs({
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '1000px',
  opacity: '0.3',
})<{dotColor: string; show: boolean; animated: boolean}>`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    ${props => themeGet(`colors.${props.dotColor}`, props.dotColor)(props)} 100%
  );
  transform-origin: center;
  transform: translate3d(-50%, -50%, 0) scale(${props => (props.show ? 1 : 0)});
  ${props => (props.animated ? 'transition: transform 1250ms cubic-bezier(0.29, 1.85, 0.72, 1.01) 500ms;' : '')}
`;

const Line = styled(Box).attrs({})<{animated: boolean}>`
  transform-origin: bottom;
  ${props => (props.animated ? 'transition: transform 500ms cubic-bezier(0.85, 0, 0.15, 1);' : '')}
`;

export const Point: React.FC<PointProps> = ({
  dotColor,
  dotSize,
  pulseColor = dotColor,
  pulseSize,
  lineWidth,
  lineColor,
  lineOpacity,
  pulseZIndex,
  animate,
  animated,
  height,
  ...props
}) => {
  const show = !animated || !!animate;
  return (
    <Wrapper {...props}>
      <Box position="relative" transform="translateY(0.1px)">
        <Dot size={dotSize} backgroundColor={dotColor} opacity={show ? '1' : '0'} animated={!!animated} />
        <DotPulse size={pulseSize} dotColor={pulseColor} show={show} animated={!!animated} zIndex={pulseZIndex} />
      </Box>
      <Line
        backgroundColor={lineColor}
        width={lineWidth}
        height={height}
        opacity={lineOpacity}
        transform={`scale(${show ? 1 : 0})`}
        animated={!!animated}
      />
    </Wrapper>
  );
};

Point.defaultProps = {
  animated: true,
  height: '279px',
  dotSize: '8px',
  pulseSize: '27px',
  lineColor: 'lineGold',
  lineOpacity: '1',
  lineWidth: '1px',
  pulseZIndex: 1,
};
