import React from 'react';

import {GatsbyLinkProps} from 'gatsby';
import DynamicStyledSystemComponent from 'src/styleguide/DynamicStyledSystemComponent';
import {StyledSystemProps} from 'src/styleguide/types';
import {typographyStyles} from 'src/styleguide/typographyStyles';

export type LinkProps = StyledSystemProps & Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;

type StyledHTMLElement = StyledSystemProps & React.HTMLAttributes<HTMLElement>;

export interface TypographyComponentProps {
  D1: React.FC<StyledHTMLElement>;
  D2: React.FC<StyledHTMLElement>;
  D3: React.FC<StyledHTMLElement>;
  D4: React.FC<StyledHTMLElement>;
  H1: React.FC<StyledHTMLElement>;
  H2: React.FC<StyledHTMLElement>;
  H3: React.FC<StyledHTMLElement>;
  H4: React.FC<StyledHTMLElement>;
  H5: React.FC<StyledHTMLElement>;
  H6: React.FC<StyledHTMLElement>;
  H7: React.FC<StyledHTMLElement>;
  H8: React.FC<StyledHTMLElement>;
  H9: React.FC<StyledHTMLElement>;
  P0: React.FC<StyledHTMLElement>;
  P1: React.FC<StyledHTMLElement>;
  P2: React.FC<StyledHTMLElement>;
  P3: React.FC<StyledHTMLElement>;
  P4: React.FC<StyledHTMLElement>;
  P5: React.FC<StyledHTMLElement>;
  P6: React.FC<StyledHTMLElement>;
  Body: React.FC<StyledHTMLElement>;
  BodyLarge: React.FC<StyledHTMLElement>;
  Small: React.FC<StyledHTMLElement>;
  Link: React.FC<LinkProps>;
}

const createComponent: <T = StyledSystemProps>(textStyle: T, displayName: string) => React.FC<T> = (
  textStyle,
  displayName,
) => {
  const component: React.FC<typeof textStyle> = props => (
    <DynamicStyledSystemComponent {...textStyle} {...props}>
      {props.children}
    </DynamicStyledSystemComponent>
  );
  component.displayName = displayName;
  return component;
};

export const Typography: TypographyComponentProps = {
  D1: createComponent(typographyStyles.D1, 'D1'),
  D2: createComponent(typographyStyles.D2, 'D2'),
  D3: createComponent(typographyStyles.D3, 'D3'),
  D4: createComponent(typographyStyles.D4, 'D4'),
  H1: createComponent(typographyStyles.H1, 'H1'),
  H2: createComponent(typographyStyles.H2, 'H2'),
  H3: createComponent(typographyStyles.H3, 'H3'),
  H4: createComponent(typographyStyles.H4, 'H4'),
  H5: createComponent(typographyStyles.H5, 'H5'),
  H6: createComponent(typographyStyles.H6, 'H6'),
  H7: createComponent(typographyStyles.H7, 'H7'),
  H8: createComponent(typographyStyles.H8, 'H8'),
  H9: createComponent(typographyStyles.H9, 'H9'),
  P0: createComponent(typographyStyles.P0, 'P0'),
  P1: createComponent(typographyStyles.P1, 'P1'),
  P2: createComponent(typographyStyles.P2, 'P2'),
  P3: createComponent(typographyStyles.P3, 'P3'),
  P4: createComponent(typographyStyles.P4, 'P4'),
  P5: createComponent(typographyStyles.P5, 'P5'),
  P6: createComponent(typographyStyles.P6, 'P6'),
  Body: createComponent(typographyStyles.Body, 'Body'),
  BodyLarge: createComponent(typographyStyles.BodyLarge, 'BodyLarge'),
  Small: createComponent(typographyStyles.Small, 'Small'),
  Link: createComponent(typographyStyles.Link, 'Link'),
};
