// this needs to be a js file as it is used in gatsby-node.js
const createSanityClient = require('@sanity/client');

// here we have separate client options for build processes with
// access to all documents vs only documents the viewer has access to
// with the preview in browser. the `withCredentials` option
// passes the token of the user currently accessing the document
const buildOptions = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_BUILD_ONLY_SANITY_TOKEN,
  apiVersion: '2019-01-29',
};
const clientPreviewOptions = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_BUILD_ONLY_SANITY_TOKEN,
  apiVersion: '2019-01-29',
};
const options = typeof window === 'undefined' ? buildOptions : clientPreviewOptions;
module.exports = {sanityClient: createSanityClient(options)};
