import React from 'react';

import {Grid, GridProps} from 'src/components/shared';
import styled from 'styled-components/macro';

export type LayoutGridProps = GridProps;

const Wrapper = styled(Grid).attrs({})``;

export const LayoutGrid: React.FC<LayoutGridProps> = ({...props}) => {
  return <Wrapper {...props}></Wrapper>;
};

LayoutGrid.defaultProps = {
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridColumnGap: ['0', null, null, '24px', '32px', '46px'],
  maxWidth: ['calc(100% - 50px)', null, null, 'calc(100% - 150px)', 'calc(100% - 200px)', '1160px'],
  marginX: 'auto',
};
