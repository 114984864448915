import React from 'react';

export const ArrowLarge: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20">
    <path
      d="M18.9719 10L.0000610352 10M12.276 16.696L18.9719 10.0001 12.276 3.30412"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

ArrowLarge.defaultProps = {
  color: 'black',
};
