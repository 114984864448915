import React, {forwardRef} from 'react';

import {ButtonProps, LinkButtonStyled} from 'src/components/controls';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import styled from 'styled-components/macro';

const StyledFireworkCTA: typeof LinkButtonStyled = styled(LinkButtonStyled).attrs({
  className: 'firework-cta',
  minWidth: ['12.5rem', null, '12.5rem'], // Overrides default button styling
  borderRadius: '6.25rem',
  px: '1.875rem',
  pt: '1.25rem',
  pb: '1.1875rem',
  display: 'inline-block',
})`
  border-width: 2px;
  font-size: 1rem;
  line-height: 0;
  font-family: ${fonts.heading};
  font-weight: ${fontWeights.extraBold};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-wrap: nowrap;
`;

export const FireworkCTA = forwardRef<HTMLButtonElement, ButtonProps>(({disabled, children, ...props}, ref) => (
  <StyledFireworkCTA ref={ref} disabled={disabled} {...props}>
    {children}
  </StyledFireworkCTA>
));

FireworkCTA.displayName = 'FireworkCTA';
FireworkCTA.defaultProps = {
  variant: 'neonGreen',
};
