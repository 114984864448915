import React, {HTMLAttributes} from 'react';

import {props} from '@styled-system/should-forward-prop';
import {transform, TransformProps} from 'src/styleguide/styleProps/transform';
import styled from 'styled-components/macro';
import {
  BorderProps,
  BoxShadowProps,
  FlexboxProps,
  GridProps,
  GridColumnProps,
  LayoutProps,
  LineHeightProps,
  PositionProps,
  SpaceProps,
  TextAlignProps,
  ZIndexProps,
  border,
  boxShadow,
  color,
  compose,
  flexbox,
  layout,
  lineHeight,
  position,
  space,
  textAlign,
  zIndex,
  ColorProps,
  grid,
  BackgroundProps,
  background,
  SizeProps,
  size,
  system,
} from 'styled-system';

export type BoxProps<T = React.ComponentType, R = HTMLDivElement> = Partial<
  BorderProps &
    BoxShadowProps &
    FlexboxProps &
    GridColumnProps &
    LayoutProps &
    PositionProps &
    LineHeightProps &
    GridProps &
    TextAlignProps &
    ZIndexProps &
    SpaceProps &
    BackgroundProps &
    Omit<ColorProps, 'color'> &
    TransformProps &
    SizeProps & {
      className: string;
      cardIndex: number;
      as: keyof JSX.IntrinsicElements | React.ComponentType<T>;
      ref: React.RefObject<R> | ((node?: Element | null | undefined) => void);
      role: HTMLAttributes<HTMLElement>['role'];
      color: string;
      id: string;
    }
>;

export const Box: React.FC<BoxProps> = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !props.includes(prop) && defaultValidatorFn(prop),
})<BoxProps>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  compose(
    border,
    boxShadow,
    color,
    flexbox,
    grid,
    layout,
    position,
    space,
    background,
    lineHeight,
    textAlign,
    transform,
    zIndex,
    size,
    system({
      // add gap property to attrs()
      gap: true,
    }),
  ),
);
