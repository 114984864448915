import React from 'react';

import css from '@styled-system/css';
import {Flex, FlexProps, Box} from 'src/components/shared';
import {Icon, Icons} from 'src/svgs';
import styled from 'styled-components/macro';

import {typographyStyles} from '../../styleguide/typographyStyles';

export type SelectProps = FlexProps & {onChange: React.ChangeEventHandler<HTMLSelectElement>};

const SelectWrapper = styled(Flex)`
  position: relative;
  cursor: pointer;
`;

const StyledSelect = styled(Box).attrs({
  as: 'select',
})<SelectProps>`
  // Reset
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  ${css(typographyStyles.P2)}
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 32px;
`;

const IconWrapper = styled(Flex).attrs({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  margin: 'auto',
  width: '20px',
  height: '20px',
  transform: 'rotate(-90deg)',
  alignItems: 'center',
  justifyContent: 'center',
})`
  cursor: pointer;
  pointer-events: none;
`;

export const Select: React.FC<SelectProps> = ({children, onChange, ...props}) => {
  return (
    <SelectWrapper height="40px" borderBottom="1px solid" borderColor="lineGray60" {...props}>
      <StyledSelect onChange={onChange} color="black">
        {children}
      </StyledSelect>
      <IconWrapper>
        <Icon icon={Icons.chevronRight} size={10} aria-label="Select arrow icon" />
      </IconWrapper>
    </SelectWrapper>
  );
};
