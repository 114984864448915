import React from 'react';

import {Events} from 'src/analytics';
import {Anchor} from 'src/components/controls';
import {Box, BoxProps, Flex, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import * as paths from 'src/routes/paths';
import {zIndices} from 'src/styleguide/zIndices';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';
import {LaptopUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {P0, H9, Link} = Typography;

export type CareerNavProps = BoxProps;

const Wrapper: typeof Box = styled(Box).attrs({})``;

const LeftSection: typeof Box = styled(Box)``;
const RightSection: typeof Flex = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})``;

const StyledAnchor = styled(Anchor)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNavLink = styled(StyledAnchor).attrs({
  color: 'offBlack',
  p: '6px',
  width: [null, 150, null],
})``;

const StyledPrimaryAnchor = styled(StyledAnchor).attrs({
  variant: 'primary',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
  flex-shrink: 0;
`;

export const CareersNav: React.FC<CareerNavProps> = props => {
  const handleTrackEvent = (item: string) => () => {
    Events.didClickNav({item, pageLocation: 'header'});
  };

  return (
    <Wrapper {...props}>
      <LayoutGrid height="100%">
        <Flex position="relative" gridColumn="1/end" alignItems="center" justifyContent="space-between">
          <LeftSection mr={'40px'} height={'24px'} width={'120px'}>
            <Link
              to={paths.HOME}
              aria-label={copy.navigation.ariaLabels.logoLink}
              onClick={handleTrackEvent('Careers Home')}
            >
              <Icon icon={Icons.logo} color="offBlack" aria-label={copy.navigation.ariaLabels.logo} />
            </Link>
          </LeftSection>
          <RightSection>
            <LaptopUp>
              <StyledNavLink href={`#${paths.careerAnchorPaths.story}`} onClick={handleTrackEvent('Careers Story')}>
                <H9>{copy.careersNavigation.story}</H9>
              </StyledNavLink>
              <StyledNavLink href={`#${paths.careerAnchorPaths.values}`} onClick={handleTrackEvent('Careers Values')}>
                <H9>{copy.careersNavigation.values}</H9>
              </StyledNavLink>
              <StyledNavLink href={`#${paths.careerAnchorPaths.perks}`} onClick={handleTrackEvent('Careers Perks')}>
                <H9>{copy.careersNavigation.perks}</H9>
              </StyledNavLink>
              <StyledNavLink href={`#${paths.careerAnchorPaths.team}`} onClick={handleTrackEvent('Careers Team')}>
                <H9>{copy.careersNavigation.team}</H9>
              </StyledNavLink>
            </LaptopUp>
            <StyledPrimaryAnchor
              href={`#${paths.careerAnchorPaths.roles}`}
              onClick={handleTrackEvent('Careers Roles')}
              ml={[0, null, '8px !important', null, '32px !important']}
            >
              <P0>{copy.careersNavigation.roles}</P0>
            </StyledPrimaryAnchor>
          </RightSection>
        </Flex>
      </LayoutGrid>
    </Wrapper>
  );
};

CareersNav.defaultProps = {
  backgroundColor: 'white',
  height: HEADER_NAV_HEIGHT.responsive,
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  zIndex: zIndices.fixed,
  color: 'offBlack',
};
