import React from 'react';

export const Citi: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 72">
    <path
      d="M34.6034 45.1032L34.5072 45.1989C33.1598 46.5705 31.6199 47.2722 30.0158 47.2722 26.6794 47.2722 24.2413 44.7842 24.2413 41.3394 24.2413 37.8945 26.6794 35.4065 30.0158 35.4065 31.6199 35.4065 33.1919 36.1402 34.5072 37.5117L34.6034 37.6074 36.7528 35.0238 36.6887 34.8962C34.8921 32.791 32.7427 31.7703 30.1121 31.7703 27.4494 31.7703 25.0433 32.6634 23.2789 34.2582 21.3861 35.9807 20.3916 38.4367 20.3916 41.3394 20.3916 44.242 21.3861 46.6662 23.2789 48.4205 25.0112 50.0154 27.4494 50.9085 30.1121 50.9085 32.7427 50.9085 34.8921 49.8878 36.6887 47.7826L36.7528 47.7188 34.6034 45.1032zM38.9343 50.5257H42.7519V32.0574H38.9343V50.5257zM57.6375 46.5068C56.6109 47.1129 55.6805 47.4318 54.8144 47.4318 53.5953 47.4318 53.0178 46.7939 53.0178 45.3585V35.5661H56.8996V32.0894H53.0178V26.3479L49.2965 28.3255V32.0894H46.0884V35.5661H49.2965V45.9965C49.2965 48.8353 50.9967 50.781 53.499 50.8129 55.1993 50.8448 56.258 50.3345 56.8675 49.9517L56.8996 49.9198 57.83 46.3473 57.6375 46.5068zM60.7815 50.5257H64.5991V32.0574H60.7815V50.5257z"
      fill={color}
    />
    <path
      d="M66.8447 28.772C63.38 23.8918 57.6054 20.9573 51.7026 20.9573C45.8318 20.9573 40.0251 23.8918 36.5604 28.772L36.3679 29.0272H40.763L40.8272 28.9634C43.8107 25.9013 47.6925 24.2746 51.7026 24.2746C55.7127 24.2746 59.5944 25.9013 62.578 28.9634L62.6421 29.0272H67.0372L66.8447 28.772Z"
      fill={color}
    />
  </svg>
);
