import React, {ImgHTMLAttributes} from 'react';

import styled from 'styled-components/macro';
import {
  compose,
  variant,
  border,
  flexbox,
  layout,
  position,
  space,
  height,
  width,
  BorderProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  HeightProps,
  WidthProps,
} from 'styled-system';

export type ImageProps = {variant?: 'default'} & BorderProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  HeightProps &
  WidthProps;

const imageVariants = variant({
  variants: {
    default: {},
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Image: React.FC<ImageProps & Omit<ImgHTMLAttributes<any>, 'height' | 'width'>> = styled.img<ImageProps>(
  imageVariants,
  compose(border, flexbox, layout, position, space, width, height),
);

Image.defaultProps = {
  variant: 'default',
  width: '100%',
  height: 'auto',
};
