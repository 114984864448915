import {Box} from 'src/components/shared';
import styled from 'styled-components/macro';
import {
  BorderProps,
  ColorProps,
  GridProps as StyledSystemGridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  grid,
  AlignItemsProps,
  JustifyContentProps,
  JustifyItemsProps,
} from 'styled-system';

export type GridProps = BorderProps &
  ColorProps &
  StyledSystemGridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  AlignItemsProps &
  JustifyContentProps &
  JustifyItemsProps;

export const Grid: React.FC<GridProps> = styled(Box)<GridProps>(grid);

Grid.defaultProps = {
  display: 'grid',
};
