import React from 'react';

export const ExperienceAdvisory: React.FC<{color: string}> = ({color}) => (
  <svg width="110" height="77" viewBox="0 0 110 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <ellipse cx="66.1393" cy="18.5147" rx="10.706" ry="10.5148" fill="#F8EAD5" />
      <path
        d="M83.3456 45.0884C83.3456 55.2246 75.6422 45.0884 66.1396 45.0884C56.637 45.0884 48.9336 55.2246 48.9336 45.0884C48.9336 34.9523 56.637 26.7354 66.1396 26.7354C75.6422 26.7354 83.3456 34.9523 83.3456 45.0884Z"
        fill="#F8EAD5"
      />
    </g>
    <g opacity="0.6">
      <ellipse cx="92.3871" cy="28.7623" rx="10.706" ry="10.5148" fill="#F8EAD5" />
      <path
        d="M109.593 55.3361C109.593 65.4722 101.89 55.3361 92.3874 55.3361C82.8848 55.3361 75.1814 65.4722 75.1814 55.3361C75.1814 45.2 82.8848 36.983 92.3874 36.983C101.89 36.983 109.593 45.2 109.593 55.3361Z"
        fill="#F8EAD5"
      />
    </g>
    <ellipse cx="29.7167" cy="18.6601" rx="18.4904" ry="18.1602" fill="#CFCAAC" />
    <path
      d="M59.4334 64.5559C59.4334 82.0621 46.1288 64.5559 29.7167 64.5559C13.3046 64.5559 0 82.0621 0 64.5559C0 47.0496 13.3046 32.858 29.7167 32.858C46.1288 32.858 59.4334 47.0496 59.4334 64.5559Z"
      fill="#CFCAAC"
    />
    <ellipse cx="71.6312" cy="39.2457" rx="12.723" ry="12.4958" fill="#F8EAD5" />
    <path
      d="M92.079 70.826C92.079 82.8719 82.9243 70.826 71.6313 70.826C60.3384 70.826 51.1836 82.8719 51.1836 70.826C51.1836 58.7802 60.3384 49.0151 71.6313 49.0151C82.9243 49.0151 92.079 58.7802 92.079 70.826Z"
      fill="#F8EAD5"
    />
  </svg>
);

export const ExperienceCoaching: React.FC<{color: string}> = ({color}) => (
  <svg width="168" height="86" viewBox="0 0 168 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="114.09" height="47.844" rx="23.922" transform="matrix(-1 0 0 1 167.955 14.8639)" fill="#F8EAD5" />
    <path
      d="M136.76 50.3294L151.54 61.1878C146.513 62.6242 138.972 66.5743 125.623 79.3086C133.734 62.2302 132.923 57.456 128.388 48.891L136.76 50.3294Z"
      fill="#F8EAD5"
    />
    <rect y="0.5" width="114.194" height="28.0098" rx="14.0049" fill="#CFCAAC" />
    <path
      d="M31.1943 15.8558L16.4143 26.7142C21.4417 28.1506 28.9827 32.1006 42.3318 44.8349C34.2203 27.7565 35.0316 22.9823 39.5665 14.4174L31.1943 15.8558Z"
      fill="#CFCAAC"
    />
  </svg>
);

export const ExperienceEducation: React.FC<{color: string}> = ({color}) => (
  <svg width="104" height="81" viewBox="0 0 104 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 21.4196C0 16.7936 4.14707 13.2705 8.7123 14.0182L45.6885 20.0747C49.3144 20.6686 51.9762 23.8018 51.9762 27.4761V72.005C51.9762 76.4269 48.1703 79.889 43.7682 79.4715L6.79197 75.9652C2.94139 75.6 0 72.3665 0 68.4987V21.4196Z"
      fill="#F8EAD5"
    />
    <path
      d="M103.952 21.4196C103.952 16.7936 99.8053 13.2705 95.2401 14.0182L58.2639 20.0747C54.638 20.6686 51.9762 23.8018 51.9762 27.4761V72.005C51.9762 76.4269 55.7821 79.889 60.1842 79.4715L97.1604 75.9652C101.011 75.6 103.952 72.3665 103.952 68.4987V21.4196Z"
      fill="#F8EAD5"
    />
    <path
      d="M6.67017 9.19165C6.67017 4.44465 11.0246 0.891706 15.675 1.84417L45.981 8.0512C49.4707 8.76592 51.9762 11.8366 51.9762 15.3987V65.3032C51.9762 69.7954 48.054 73.28 43.5931 72.751L13.287 69.1575C9.51295 68.71 6.67017 65.5102 6.67017 61.7097V9.19165Z"
      fill="#CFCAAC"
    />
    <path
      d="M97.2822 9.19165C97.2822 4.44465 92.9278 0.891706 88.2774 1.84417L57.9714 8.0512C54.4817 8.76592 51.9762 11.8366 51.9762 15.3987V65.3032C51.9762 69.7954 55.8984 73.28 60.3593 72.751L90.6654 69.1575C94.4394 68.71 97.2822 65.5102 97.2822 61.7097V9.19165Z"
      fill="#CFCAAC"
    />
  </svg>
);

export const ExperienceAdvisoryModal: React.FC<{color: string}> = ({color}) => (
  <svg width="110" height="77" viewBox="0 0 110 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <ellipse cx="66.1393" cy="18.5147" rx="10.706" ry="10.5148" fill="#2A2C41" />
      <path
        d="M83.3456 45.0884C83.3456 55.2246 75.6422 45.0884 66.1396 45.0884C56.637 45.0884 48.9336 55.2246 48.9336 45.0884C48.9336 34.9523 56.637 26.7354 66.1396 26.7354C75.6422 26.7354 83.3456 34.9523 83.3456 45.0884Z"
        fill="#2A2C41"
      />
    </g>
    <g opacity="0.6">
      <ellipse cx="92.3871" cy="28.7623" rx="10.706" ry="10.5148" fill="#2A2C41" />
      <path
        d="M109.593 55.3361C109.593 65.4722 101.89 55.3361 92.3874 55.3361C82.8848 55.3361 75.1814 65.4722 75.1814 55.3361C75.1814 45.2 82.8848 36.983 92.3874 36.983C101.89 36.983 109.593 45.2 109.593 55.3361Z"
        fill="#2A2C41"
      />
    </g>
    <ellipse cx="29.7167" cy="18.6601" rx="18.4904" ry="18.1602" fill="#CFCAAC" />
    <path
      d="M59.4334 64.5559C59.4334 82.0621 46.1288 64.5559 29.7167 64.5559C13.3046 64.5559 0 82.0621 0 64.5559C0 47.0497 13.3046 32.8581 29.7167 32.8581C46.1288 32.8581 59.4334 47.0497 59.4334 64.5559Z"
      fill="#CFCAAC"
    />
    <ellipse cx="71.6312" cy="39.2456" rx="12.723" ry="12.4958" fill="#2A2C41" />
    <path
      d="M92.079 70.8261C92.079 82.8719 82.9243 70.8261 71.6313 70.8261C60.3384 70.8261 51.1836 82.8719 51.1836 70.8261C51.1836 58.7803 60.3384 49.0152 71.6313 49.0152C82.9243 49.0152 92.079 58.7803 92.079 70.8261Z"
      fill="#2A2C41"
    />
  </svg>
);

export const ExperienceCoachingModal: React.FC<{color: string}> = ({color}) => (
  <svg width="169" height="85" viewBox="0 0 169 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="114.09" height="47.844" rx="23.922" transform="matrix(-1 0 0 1 168.283 14.3639)" fill="#2A2C41" />
    <path
      d="M137.089 49.8294L151.869 60.6878C146.842 62.1242 139.301 66.0743 125.952 78.8086C134.063 61.7302 133.252 56.956 128.717 48.391L137.089 49.8294Z"
      fill="#2A2C41"
    />
    <rect width="114.194" height="28.0098" rx="14.0049" fill="#CFCAAC" />
    <path
      d="M31.1943 15.3558L16.4143 26.2142C21.4417 27.6506 28.9827 31.6007 42.3318 44.3349C34.2203 27.2565 35.0316 22.4823 39.5665 13.9174L31.1943 15.3558Z"
      fill="#CFCAAC"
    />
  </svg>
);

export const ExperienceEducationModal: React.FC<{color: string}> = ({color}) => (
  <svg width="104" height="81" viewBox="0 0 104 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 21.4196C0 16.7935 4.14707 13.2704 8.7123 14.0182L45.6885 20.0746C49.3144 20.6685 51.9762 23.8018 51.9762 27.476V72.005C51.9762 76.4268 48.1703 79.8889 43.7682 79.4715L6.79197 75.9651C2.94139 75.6 0 72.3665 0 68.4986V21.4196Z"
      fill="#2A2C41"
    />
    <path
      d="M103.952 21.4196C103.952 16.7935 99.8053 13.2704 95.2401 14.0182L58.2639 20.0746C54.638 20.6685 51.9762 23.8018 51.9762 27.476V72.005C51.9762 76.4268 55.7821 79.8889 60.1842 79.4715L97.1604 75.9651C101.011 75.6 103.952 72.3665 103.952 68.4986V21.4196Z"
      fill="#2A2C41"
    />
    <path
      d="M6.67017 9.19165C6.67017 4.44465 11.0246 0.891706 15.675 1.84417L45.981 8.0512C49.4707 8.76592 51.9762 11.8366 51.9762 15.3987V65.3032C51.9762 69.7954 48.054 73.28 43.5931 72.751L13.287 69.1575C9.51295 68.71 6.67017 65.5102 6.67017 61.7097V9.19165Z"
      fill="#CFCAAC"
    />
    <path
      d="M97.2822 9.19165C97.2822 4.44465 92.9278 0.891706 88.2774 1.84417L57.9714 8.0512C54.4817 8.76592 51.9762 11.8366 51.9762 15.3987V65.3032C51.9762 69.7954 55.8984 73.28 60.3593 72.751L90.6654 69.1575C94.4394 68.71 97.2822 65.5102 97.2822 61.7097V9.19165Z"
      fill="#CFCAAC"
    />
  </svg>
);
