import React from 'react';

export const Lvmh: React.FC<{color: string}> = ({color}) => (
  <svg width="128" height="29" viewBox="0 0 128 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_41435_1671)">
      <path
        d="M1.09849 24.8485V2.19369C1.09849 0.627479 -0.332959 0.125488 0.536843 0.125488H5.38288C6.44155 0.125488 4.94549 0.436722 4.94549 2.13345V24.7883C4.94549 27.233 4.94549 27.489 9.1106 27.735C10.8502 27.8605 15.6962 28.1115 19.8564 22.9661C21.099 21.3999 20.7262 22.5293 20.7262 22.5293C20.7262 22.5293 20.0452 27.1727 19.7918 28.0512C19.6079 28.744 19.419 28.6135 18.7977 28.6135H0.536843C-0.457216 28.6135 0.0994569 28.3625 0.785358 27.2932C1.15813 26.726 1.09352 24.8435 1.09352 24.8435"
        fill="#2A2C41"
      />
      <path
        d="M26.0098 3.82523C26.318 4.51798 35.7665 28.3625 35.7665 28.3625C35.8908 28.6135 36.015 28.6135 36.015 28.6135H38.192C38.4405 28.6135 38.5648 28.4228 38.6294 28.3625C38.6294 28.3625 46.5222 7.27893 47.0193 6.02395C47.5163 4.76898 49.1963 1.81727 50.1854 0.81831C50.9905 0.0653245 49.748 0.190822 49.748 0.190822H44.3403C43.9675 0.190822 43.4059 -0.0601731 44.3403 1.3203C46.0202 3.83025 44.9019 6.5912 44.9019 6.5912L38.4405 24.7231C38.4405 24.7231 30.672 4.20173 30.3588 3.514C30.1103 2.82125 30.1103 1.63154 30.5427 1.12954C31.4125 0.0603045 31.1044 0.251061 30.4185 0.251061H24.0814C22.6549 0.125563 24.8269 0.627554 26.0098 3.82523Z"
        fill="#2A2C41"
      />
      <path
        d="M62.541 4.32719C62.6006 7.33913 60.6771 22.4591 59.9962 24.5323C59.4346 26.4148 58.6294 27.7953 58.3809 28.1065C58.0727 28.483 58.0081 28.6085 58.5051 28.6085H62.541C63.3462 28.6085 62.4814 27.7902 62.4814 27.7902C62.4814 27.7902 61.8004 26.726 62.044 24.5273C62.1036 23.7091 64.3452 4.25691 64.3452 4.25691C64.3452 4.25691 73.0432 27.6647 73.3514 28.3525C73.6595 29.1707 74.0323 29.2309 74.4101 28.3525C74.5343 28.0412 83.7294 3.69468 83.7294 3.69468C83.7294 3.69468 85.7771 22.7703 85.966 24.3365C86.0903 25.9027 86.2145 27.0372 86.0903 27.4087C85.8417 28.2872 85.0962 28.4729 85.8417 28.4729H91.1848C91.9304 28.4729 91.8061 28.3474 91.493 27.8455C91.2444 27.469 90.5585 26.7812 89.813 23.7693C89.1917 21.2593 87.9491 3.88042 87.8249 2.75094C87.641 0.115488 89.3756 -0.0100098 88.5058 -0.0100098H83.4759C83.4759 -0.0100098 82.8546 0.055249 82.6061 0.933733C82.4818 1.37548 75.091 22.4591 75.091 22.4591L67.0143 3.00473e-05H61.9197C60.1205 0.125528 62.6653 0.436762 62.541 4.32719Z"
        fill="#2A2C41"
      />
      <path
        d="M127.652 0.501981C127.96 0.190747 127.96 0.125488 127.215 0.125488H122.617C121.559 0.125488 122.12 0.501981 122.12 0.501981C122.12 0.501981 122.801 1.31521 122.866 2.57018V13.3027H109.695V3.38843C109.695 1.57122 110.256 1.00397 110.44 0.692738C110.877 0.0652494 110.689 0.130508 110.003 0.130508H105.654C104.848 0.130508 104.66 0.195767 105.216 0.632499C105.216 0.632499 105.778 1.00899 105.713 3.51895V25.3555C105.654 27.0472 105.589 27.8002 105.216 28.3022C104.843 28.739 105.4 28.6787 105.4 28.6787H110.062C110.435 28.6787 110.246 28.4277 110.246 28.4277C110.246 28.4277 109.749 28.3022 109.685 25.4158V14.5577H122.856C122.856 14.5577 122.915 24.8485 122.915 25.4158C122.915 27.1727 122.915 27.8605 122.478 28.237C122.23 28.488 121.608 28.6737 122.786 28.6737H127.384C128.005 28.6737 128.189 28.6737 127.692 28.2972C127.443 28.0462 127.011 27.6697 127.011 25.4108V2.88644C127.076 1.00397 127.632 0.501981 127.632 0.501981"
        fill="#2A2C41"
      />
    </g>
    <defs>
      <clipPath id="clip0_41435_1671">
        <rect width="128" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
