import React from 'react';

type ChevronProps = {
  classes: string;
};

export const Chevron: React.FC<ChevronProps> = props => {
  return (
    <svg aria-hidden="true" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.classes}>
      <path d="M9 1.87444L7.66531 0.5L4.5 3.7538L1.33469 0.5L0 1.87444L4.5 6.5L9 1.87444Z" fill="" />
    </svg>
  );
};
