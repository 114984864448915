import {ApolloClient, gql, InMemoryCache} from '@apollo/client';

type SubscribeToChiefInBriefInput = {
  input: {
    email: string;
  };
};

type SubscribeToChiefInBriefResponse = {
  subscribeToChiefInBrief: {
    success: boolean;
  };
};

export const useChiefInBriefSubscribe = () => {
  return async (email: string) => {
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      uri: process.env.GATSBY_GRAPHQL_GATEWAY_URI,
    });

    try {
      const response = await client.mutate<SubscribeToChiefInBriefResponse, SubscribeToChiefInBriefInput>({
        mutation: gql`
          mutation subscribeToChiefInBrief($input: SubscribeToChiefInBriefInput!) {
            subscribeToChiefInBrief(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            email,
          },
        },
      });

      return !!response?.data?.subscribeToChiefInBrief?.success;
    } catch (error) {
      return false;
    }
  };
};
