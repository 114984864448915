import React, {useRef, useEffect, useState} from 'react';

import {motion, useMotionValue, useAnimation} from 'framer-motion';
import debounce from 'lodash/debounce';
import {Box, BoxProps} from 'src/components/shared';
import {usePrevious} from 'src/hooks/usePrevious';
import styled from 'styled-components/macro';

type DragSliderProps = BoxProps & {
  animateToEnd?: boolean;
  animationDuration?: number;
};

const MotionContainer = styled(motion.div)<{justifyContent?: string}>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  outline: none;
`;

export const DragSlider: React.FC<DragSliderProps> = ({children, animateToEnd, animationDuration, ...props}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0);
  const [dragEnabled, setDragEnabled] = useState(false);
  const [animationEnabled, setAnimationEnabled] = useState(animateToEnd);
  const previousAnimateToEnd = usePrevious(animateToEnd);
  const x = useMotionValue(0);
  const controls = useAnimation();
  const availableSpace = sliderChildrenWidth - sliderWidth;

  const calcWidth = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      return ref.current.clientWidth;
    }
    return 0;
  };

  const calcDimensions = () => {
    const parentWidth = calcWidth(parentRef);
    const childrenWidth = calcWidth(childrenRef);
    setSliderWidth(parentWidth);
    setSliderChildrenWidth(childrenWidth);
  };

  const handleWindowResize = React.useCallback(
    debounce(() => {
      calcDimensions();
    }, 200),
    [],
  );

  const handleWindowKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      let newValue;
      const increment = 300;
      if (event.key === 'ArrowRight') {
        newValue = Math.max(x.get() - increment, -availableSpace);
      } else if (event.key === 'ArrowLeft') {
        newValue = Math.min(x.get() + increment, 0);
      }

      if (newValue !== undefined) {
        controls.start({
          x: newValue,
          transition: {duration: x.isAnimating() ? 0.1 : 0.2},
        });
      }
    },
    [availableSpace],
  );

  // Attach window listeners
  useEffect(() => {
    calcDimensions();
    window?.addEventListener('resize', handleWindowResize);

    return () => {
      window?.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    parentRef.current?.addEventListener('keydown', handleWindowKeyDown);

    return () => {
      parentRef.current?.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, [handleWindowKeyDown]);

  // Trigger initial animation if enabled
  useEffect(() => {
    if (!animationEnabled) {
      setDragEnabled(availableSpace > 0);
    } else {
      controls.start({
        x: -availableSpace,
      });
    }
  }, [animationEnabled, availableSpace]);

  // Make sure slider is in bounds when availableSpace changes
  useEffect(() => {
    const value = x.get();
    const clampedValue = Math.min(Math.max(-availableSpace, value), 0);
    controls.start({
      x: clampedValue,
      transition: {duration: 0.2},
    });
  }, [availableSpace]);

  // Handle animateToEnd changing after mount
  useEffect(() => {
    if (previousAnimateToEnd !== animateToEnd && animateToEnd) {
      setDragEnabled(false);
      setAnimationEnabled(true);
    }
  }, [animateToEnd]);

  return (
    <Box {...props}>
      <MotionContainer
        ref={parentRef}
        drag={dragEnabled ? 'x' : false}
        style={{x}}
        dragConstraints={{
          right: 0,
          left: -availableSpace,
        }}
        animate={controls}
        transition={{duration: animationDuration}}
        onAnimationComplete={() => {
          setDragEnabled(availableSpace > 0);
          setAnimationEnabled(false);
        }}
        justifyContent={availableSpace > 0 ? 'flex-start' : 'center'}
        tabIndex={0}
      >
        <Box display="inline-flex" minWidth="auto" ref={childrenRef}>
          {children}
        </Box>
      </MotionContainer>
    </Box>
  );
};

DragSlider.defaultProps = {
  width: '100%',
  overflow: 'hidden',
  animateToEnd: false,
  animationDuration: 4,
};
