import React from 'react';

import {Link} from 'gatsby';

function Paginate(currentPage: number, numPages: number, slug: string) {
  const pageArray = [];

  if ((currentPage === 1 || currentPage === 2) && numPages >= 3) {
    pageArray.push(1, 2, 3);
  } else if ((currentPage == 1 || currentPage == 2) && numPages == 2) {
    pageArray.push(1, 2);
  }

  if (currentPage - 2 >= 1 && numPages != 3) {
    pageArray.push(1, '...');
  }

  if (currentPage - 2 >= 1 && numPages != 3) {
    const before = currentPage - 1;
    const after = currentPage + 1;

    if (after <= numPages) {
      pageArray.push(currentPage, after);
    } else {
      pageArray.push(before, currentPage);
    }
  }

  if (currentPage + 2 < numPages && numPages != 3) {
    pageArray.push('...');
  }

  if (currentPage + 1 < numPages && numPages != 3) {
    pageArray.push(numPages);
  }

  return (
    <div className="mx-14 flex gap-7 text-green-dark justify-center">
      {pageArray.map(i => {
        if (i == '...') {
          return <span key={i}>...</span>;
        }

        return (
          <Link
            key={`pagination-number${i === 1 ? '' : i}`}
            to={`/${slug}/${i === 1 ? '' : i}`}
            className={` ${i === currentPage ? 'text-lighter-gray' : 'hover:underline cursor-pointer'}`}
          >
            {i}
          </Link>
        );
      })}
    </div>
  );
}

function prevPage(currentPage: number, slug: string) {
  const prevPage = currentPage - 1 === 1 ? ' ' : (currentPage - 1).toString();
  if (currentPage == 1) {
    return (
      <div className="min-w-[130px] md:min-w-[177px]">
        <div className="w-full text-center button-disabled bg-opacity-10 text-opacity-20">PREVIOUS</div>
      </div>
    );
  } else {
    return (
      <div className="min-w-[130px] md:min-w-[177px]">
        <Link
          to={currentPage === 1 ? `/${slug}` : `/${slug}/${prevPage}`}
          rel="prev"
          className="w-full text-center button"
        >
          PREVIOUS
        </Link>
      </div>
    );
  }
}

function nextPage(currentPage: number, numPages: number, slug: string) {
  const nextPage = (currentPage + 1).toString();
  if (currentPage == numPages) {
    return (
      <div className="min-w-[130px] md:min-w-[177px]">
        <div className="w-full text-center button-disabled bg-opacity-10 text-opacity-20">NEXT</div>
      </div>
    );
  } else {
    return (
      <div className="min-w-[130px] md:min-w-[177px]">
        <Link to={`/${slug}/${nextPage}`} rel="next" className="w-full text-center button">
          NEXT
        </Link>
      </div>
    );
  }
}

interface PaginationProps {
  currentPage: number;
  numPages: number;
  slug: string;
}

export const Pagination: React.FC<PaginationProps> = ({currentPage, numPages, slug}: PaginationProps) => {
  return (
    <>
      <div className="md:hidden">{Paginate(currentPage, numPages, slug)}</div>
      <div className="flex mt-7 md:mt-0 items-center gap-x-8">
        {prevPage(currentPage, slug)}
        <div className="hidden md:block">{Paginate(currentPage, numPages, slug)}</div>
        {nextPage(currentPage, numPages, slug)}
      </div>
    </>
  );
};
