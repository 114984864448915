import React, {useState, useEffect, useRef, forwardRef} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {motion, MotionProps, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {Button} from 'src/components/controls';
import {Box, BoxProps, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import {swipePower, wrapIndex} from 'src/utils/slider';
import styled, {keyframes} from 'styled-components/macro';

import {Maybe, SanityCarouselItem} from 'graphql-types';

const {D2, H2} = Typography;

// export type ImageCarouselProps = {images: Maybe<SanityCarouselItem>[]; boxProps?: BoxProps};
// type ImageSlideProps = {image: Maybe<SanityCarouselItem>; active?: boolean};
export type ImageCarouselProps = {images: any[]; autorotate?: boolean; interval?: number; boxProps?: BoxProps}; // TEMP
type ImageSlideProps = {image: any; active?: boolean}; // TEMP

const Wrapper: typeof Box = styled(Box).attrs({})`
  &.no-autorotate:hover {
    .nav-button {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const MotionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const Image = styled(motion.div).attrs({
  role: 'tabpanel',
  'aria-roledescription': 'slide',
})<{backgroundImage: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.55%, rgba(0, 0, 0, 0.75) 100%),
    url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const NavButton = styled(Button).attrs({
  variant: 'icon',
  className: 'nav-button',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
  borderRadius: '1000px',
  bg: 'cream',
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 2,
  my: 'auto',
  border: '0',
})`
  background-color: ${props => themeGet('colors.cream')} !important;
  cursor: pointer;
  transition: background-color 200ms ease, opacity 200ms ease, visibility 200ms ease;
  opacity: 0;
  visibility: hidden;

  :hover:not(:disabled) {
    background-color: ${themeGet('colors.periwinkle')} !important;
  }

  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

const PageDot = styled.div<{active: boolean}>`
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid white;
  border-radius: 62.5rem;
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  margin: 0 4px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TextContainer = styled.div<{active: boolean}>`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-timing: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: ${props => (props.active ? 'forwards' : 'backwards')};
  animation-duration: ${props => (props.active ? '600ms' : '0ms')};
`;

const ImageSlide = forwardRef<HTMLDivElement, ImageSlideProps>(({image, active}, ref) => {
  const label = image?.label || '';
  const src = image?.image?.asset?.url || '';

  return (
    <Image aria-label={label} backgroundImage={src} ref={ref}>
      {image?.location && (
        <TextContainer active={!!active}>
          <TabletUp>
            <D2 color="white" textTransform="uppercase">
              {image.location}
            </D2>
          </TabletUp>
          <MobileOnly>
            <H2 color="white" textTransform="uppercase">
              {image.location}
            </H2>
          </MobileOnly>
        </TextContainer>
      )}
    </Image>
  );
});

const transition = {type: 'spring', mass: 0.25, bounce: 0, stiffness: 1000, damping: 50};
const dragTransition = {bounceDamping: transition.damping, bounceStiffness: transition.stiffness};

export const ImageCarousel: React.FC<ImageCarouselProps> = ({images, autorotate, interval, boxProps}) => {
  const [index, setIndex] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const animation = useAnimation();
  const indexRef = useRef(0);
  const {ref, inView} = useInView();
  const [wasInView, setWasInView] = useState(false);
  const rightNavRef = useRef(null);

  const handleNavigateLeft = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index - 1;
      await animation.start({
        x: parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index - 1);
  };

  const handleNavigateRight = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index + 1;
      await animation.start({
        x: -parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index + 1);
  };

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleNavigateRight();
      } else if (event.key === 'ArrowLeft') {
        handleNavigateLeft();
      }
    },
    [images, index, setIndex],
  );

  const handleDragEnd: MotionProps['onDragEnd'] = async (evt, {offset}) => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const power = swipePower(offset.x, rect.width);
      if (power > 10) {
        await handleNavigateLeft();
      } else if (power < -10) {
        await handleNavigateRight();
      }
    }
  };

  useEffect(() => {
    parentRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      parentRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onAnimationComplete = (variant: string) => {
    // Only run timeout for autorotating carousels
    if (!autorotate) {
      return;
    }

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    setTimeout(() => rightNavRef.current?.click(), interval);
  };

  useEffect(() => {
    // Only trigger animation once
    if (wasInView) {
      return;
    }

    setWasInView(true);

    if (autorotate) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      setTimeout(() => rightNavRef.current?.click(), interval);
    }
  }, [inView]);

  const prevIndex = wrapIndex(0, images.length, index - 1);
  const currentIndex = wrapIndex(0, images.length, index);
  const nextIndex = wrapIndex(0, images.length, index + 1);

  return (
    <Wrapper {...boxProps} className={autorotate ? 'autorotate' : 'no-autorotate'} aria-roledescription="carousel">
      <Box ref={ref} position="relative" height="100%" width="100%" overflow="hidden">
        <MotionContainer
          key={index}
          drag={autorotate ? false : 'x'}
          layout
          dragConstraints={{left: 0, right: 0}}
          animate={animation}
          onAnimationComplete={onAnimationComplete}
          dragMomentum={false}
          dragDirectionLock
          onDragEnd={handleDragEnd}
          whileTap={{cursor: 'grabbing'}}
          dragTransition={dragTransition}
        >
          <ImageSlide key={prevIndex} image={images[prevIndex]} />
          <ImageSlide key={index} ref={parentRef} image={images[currentIndex]} active />
          <ImageSlide key={nextIndex} image={images[nextIndex]} />
        </MotionContainer>
        <TabletUp>
          <NavButton left="48px" onClick={handleNavigateLeft}>
            <Box size="20px" transform="rotate(180deg)">
              <Icon icon={Icons.chevronRightLarge} aria-label="Left arrow" color="midnightBlue" />
            </Box>
          </NavButton>
          <NavButton ref={rightNavRef} right="48px" onClick={handleNavigateRight}>
            <Box size="20px">
              <Icon icon={Icons.chevronRightLarge} aria-label="Right arrow" color="midnightBlue" />
            </Box>
          </NavButton>
        </TabletUp>
        <Flex position="absolute" bottom="24px" width="100%" alignItems="center" justifyContent="center" zIndex={1}>
          {images.map((image, imageIndex) => (
            <PageDot key={image?._key} active={currentIndex === imageIndex} />
          ))}
        </Flex>
      </Box>
    </Wrapper>
  );
};

ImageCarousel.defaultProps = {
  autorotate: false,
  interval: 5000,
  boxProps: {
    height: ['480px', null, null, '675px'],
  },
};
