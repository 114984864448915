import React from 'react';

export const Dell: React.FC<{color: string}> = ({color}) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_41435_1733)">
      <path
        d="M40.0027 4.32215C30.153 4.32215 21.2349 8.31678 14.7785 14.7785C8.31946 21.2268 4.32752 30.1503 4.32752 40.0027C4.32752 49.855 8.31946 58.7651 14.7785 65.2161C21.2349 71.6698 30.1557 75.6725 40.0027 75.6725C49.8497 75.6725 58.7785 71.6698 65.2242 65.2161C71.6805 58.7651 75.6644 49.8497 75.6644 40.0027C75.6644 30.1557 71.6805 21.2242 65.2242 14.7785C58.7758 8.31678 49.8523 4.32215 40.0027 4.32215ZM40.0027 0C62.0913 0 80 17.9114 80 40.0054C80 62.0993 62.0913 80 40.0027 80C17.9141 80 0 62.0913 0 40.0054C0 17.9195 17.9168 0 40.0027 0Z"
        fill="#2A2C41"
      />
      <path
        d="M21.0842 39.6778C21.0842 37.3476 19.5379 36.0215 17.3472 36.0215H16.0076V43.3342H17.3097C19.3446 43.3342 21.0842 42.2282 21.0842 39.6752M44.9231 42.3114L35.0493 50.0859L26.0264 42.9825C24.7244 46.1101 21.5781 48.2819 17.954 48.2819H10.2305V31.0792H17.954C22.0023 31.0792 24.9204 33.6537 26.0238 36.3973L35.0493 29.2993L38.3915 31.9302L30.1204 38.4322L31.7097 39.6752L39.9781 33.1758L43.3258 35.7986L35.0546 42.3087L36.6412 43.5544L44.9204 37.0443V31.0738H50.9204V43.2564H56.907V48.298H44.9204V42.306L44.9231 42.3114ZM64.5123 43.2617H70.4962V48.3007H58.5043V31.0765H64.5123V43.2617Z"
        fill="#2A2C41"
      />
    </g>
    <defs>
      <clipPath id="clip0_41435_1733">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
