import React from 'react';

export const Toyota: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 72">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.69 37.3642L110.174 33.4805L111.658 37.3642H108.69ZM111.701 31.2769H108.625L104.327 41.815H106.989L107.97 39.2405H112.356L113.338 41.815H116L111.701 31.2769ZM90.0142 39.9605C88.2688 39.9605 86.8724 38.3896 86.8724 36.4696C86.8724 34.5278 88.2688 32.9787 90.0142 32.9787C91.7378 32.9787 93.156 34.5496 93.156 36.4696C93.156 38.4114 91.7378 39.9605 90.0142 39.9605ZM90.0142 30.906C86.9379 30.906 84.4506 33.3933 84.4506 36.4696C84.4506 39.546 86.9379 42.0332 90.0142 42.0332C93.0906 42.0332 95.5778 39.546 95.5778 36.4696C95.5778 33.3933 93.0687 30.906 90.0142 30.906ZM68.0652 39.9605C66.3416 39.9605 64.9234 38.3896 64.9234 36.4696C64.9234 34.5278 66.3198 32.9787 68.0652 32.9787C69.8107 32.9787 71.207 34.5496 71.207 36.4696C71.207 38.4114 69.7888 39.9605 68.0652 39.9605ZM68.0652 30.906C64.9889 30.906 62.5016 33.3933 62.5016 36.4696C62.5016 39.546 64.9889 42.0332 68.0652 42.0332C71.1416 42.0332 73.6288 39.546 73.6288 36.4696C73.6288 33.3933 71.1198 30.906 68.0652 30.906ZM81.8106 31.2769L79.0834 35.5314L76.3561 31.2769H73.6943L77.9052 37.866V41.7932H80.2615V37.866L84.4724 31.2551L81.8106 31.2769ZM104.894 31.2769H95.8833V33.306H99.2214V41.815H101.578V33.306H104.894V31.2769ZM53.0107 33.2842H56.3489V41.7932H58.7053V33.2842H62.0216V31.2551H53.0107V33.2842Z"
      fill={color}
    />
    <path
      d="M35.229 35.0731C37.9999 34.7677 38.8508 33.8513 38.6763 33.1095 38.589 32.7168 37.9999 32.2586 37.0181 31.8659 35.9927 31.4949 34.8145 31.2986 33.5709 31.2549 34.4872 31.604 35.0545 33.1968 35.229 35.0731zM33.2 39.8949C33.8545 39.8949 34.5745 38.8913 34.5745 36.5131V36.4913C34.1381 36.5349 33.6581 36.5567 33.2 36.5567 32.7418 36.5567 32.2618 36.5349 31.8254 36.4913V36.5131C31.8254 38.9131 32.5454 39.8949 33.2 39.8949zM31.9127 35.1386C32.3054 35.1604 32.7418 35.1822 33.2 35.1822 33.6581 35.1822 34.0945 35.1604 34.5308 35.1386 34.3126 33.5895 33.7454 32.6731 33.2218 32.6731 32.6763 32.6731 32.1309 33.5895 31.9127 35.1386zM29.3382 31.8877C28.4 32.2586 27.8109 32.7168 27.7018 33.1095 27.5273 33.8513 28.4 34.7677 31.1491 35.0731 31.3236 33.1749 31.8909 31.5822 32.8072 31.2549 31.5636 31.2986 30.3636 31.5168 29.3382 31.8877zM26.6109 33.7204C26.0218 34.4404 25.6945 35.3131 25.6945 36.3167 25.6945 39.2404 28.9018 41.6622 32.8291 41.8149 31.9127 41.444 31.0836 39.7422 31.0836 36.5131V36.404C28.4873 36.0549 26.6764 35.0513 26.6109 33.7204zM35.3163 36.4913C35.3163 39.5895 34.4654 41.4222 33.5491 41.7931 37.4981 41.6622 40.7054 39.2404 40.7054 36.2949 40.7054 35.2913 40.3781 34.4186 39.789 33.6986 39.7017 35.0295 37.8909 36.0331 35.3163 36.3822V36.4913z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.3999 23.335H20V49.7348H46.3999V23.335ZM24.4073 36.5349C24.4073 33.1095 28.2254 30.535 33.2 30.535C38.1745 30.535 41.9926 33.1095 41.9926 36.5349C41.9926 39.8513 38.2836 42.5349 33.2 42.5349C28.1163 42.5349 24.4073 39.8513 24.4073 36.5349Z"
      fill={color}
    />
  </svg>
);
