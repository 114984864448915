import React from 'react';

import styled from 'styled-components/macro';

type TextGrouperProps = {children: string; groupSize?: number};

const Widow = styled.span`
  white-space: pre;
`;

/**
 * Automatically groups the last set of words to prevent widows/orphans.
 */
export const WidowGroup: React.FC<TextGrouperProps> = ({children, groupSize = 3}) => {
  const words = children.split(' ');
  const body = words.slice(0, -groupSize).join(' ');
  const widow = words.slice(-groupSize).join(' ');
  return (
    <span>
      {body}&nbsp;<Widow>{widow}</Widow>
    </span>
  );
};
