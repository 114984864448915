import React from 'react';

export const FacebookSquare: React.FC<{color: string}> = ({color}) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1158 0H0.884211C0.649703 0 0.424801 0.0931576 0.258979 0.258979C0.0931576 0.424801 0 0.649703 0 0.884211L0 15.1158C0 15.3503 0.0931576 15.5752 0.258979 15.741C0.424801 15.9068 0.649703 16 0.884211 16H8.54737V9.82456H6.45614V7.39088H8.53614V5.61404C8.53614 3.54526 9.79649 2.41965 11.6407 2.41965C12.2672 2.42151 12.8931 2.45712 13.5158 2.52632V4.68491H12.2414C11.2393 4.68491 11.0456 5.1593 11.0456 5.85825V7.40211H13.4456L13.134 9.82456H11.0456V16H15.1158C15.3503 16 15.5752 15.9068 15.741 15.741C15.9068 15.5752 16 15.3503 16 15.1158V0.884211C16 0.649703 15.9068 0.424801 15.741 0.258979C15.5752 0.0931576 15.3503 0 15.1158 0V0Z"
      fill={color}
    />
  </svg>
);
