import React from 'react';

export const FacebookRound: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 71">
    <path
      d="M58.1872 35.5135C58.1872 25.1529 49.7897 16.7554 39.4291 16.7554C29.0684 16.7554 20.6709 25.1529 20.6709 35.5135C20.6709 44.8772 27.5266 52.6374 36.4997 54.0456V40.9406H31.7408V35.5135H36.4997V31.3816C36.4997 26.6844 39.2954 24.0839 43.5816 24.0839C45.6372 24.0839 47.7854 24.4539 47.7854 24.4539V29.069H45.4214C43.0882 29.069 42.3687 30.5182 42.3687 31.9983V35.5135H47.5696L46.737 40.9406H42.3584V54.0456C51.3315 52.6374 58.1872 44.8772 58.1872 35.5135Z"
      fill={color}
    />
  </svg>
);
