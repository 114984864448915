import React from 'react';

export const YoutubeSocialIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.7609 11.4524C27.7609 11.4524 27.5266 9.88392 26.8047 9.19522C25.8906 8.28881 24.8688 8.28437 24.4 8.23105C21.0438 8 16.0047 8 16.0047 8H15.9953C15.9953 8 10.9563 8 7.6 8.23105C7.13125 8.28437 6.10938 8.28881 5.19531 9.19522C4.47344 9.88392 4.24375 11.4524 4.24375 11.4524C4.24375 11.4524 4 13.2963 4 15.1358V16.8598C4 18.6992 4.23906 20.5432 4.23906 20.5432C4.23906 20.5432 4.47344 22.1116 5.19062 22.8003C6.10469 23.7067 7.30469 23.6756 7.83906 23.7734C9.76094 23.9467 16 24 16 24C16 24 21.0438 23.9911 24.4 23.7645C24.8688 23.7112 25.8906 23.7067 26.8047 22.8003C27.5266 22.1116 27.7609 20.5432 27.7609 20.5432C27.7609 20.5432 28 18.7037 28 16.8598V15.1358C28 13.2963 27.7609 11.4524 27.7609 11.4524ZM13.5203 18.9525V12.5587L20.0031 15.7667L13.5203 18.9525Z"
      fill="white"
    />
  </svg>
);
