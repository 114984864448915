import React from 'react';

import {Box, Flex} from 'src/components/shared';
import {LinkProps, Typography} from 'src/components/shared/typography';
import {typographyStyles} from 'src/styleguide/typographyStyles';
import {Icon, Icons} from 'src/svgs';
import {easing} from 'src/utils/animations';
import * as copy from 'src/utils/copy';
import styled from 'styled-components/macro';
import {variant} from 'styled-system';

export type ArrowLinkProps = LinkProps & {
  variant?: 'small' | 'large';
};

const textVariant = variant({
  variants: {
    small: {
      ...typographyStyles.P0,
      mr: '8px',
    },
    large: {
      ...typographyStyles.H1,
      mr: '24px',
    },
  },
});

const Wrapper: typeof Typography.Link = styled(Typography.Link)``;

const Container = styled(Flex).attrs({
  alignItems: 'center',
})`
  opacity: 1;
  transition: opacity 200ms ${easing.bezier};

  &:hover,
  &:focus {
    opacity: 0.8;

    .small-arrow {
      transform: translateX(8px);
    }
    .large-arrow {
      transform: translateY(-2px) translateX(16px);
    }
  }
`;

const StyledText = styled(Box)<{variant: ArrowLinkProps['variant']}>`
  ${textVariant}
`;

const SmallArrowContainer = styled.div.attrs({
  className: 'small-arrow',
})`
  width: 20px;
  height: 20px;
  transition: transform 300ms ${easing.bezier};
`;

const LargeArrowContainer = styled.div.attrs({
  className: 'large-arrow',
})`
  width: 40px;
  height: 40px;
  transform: translateY(-2px);
  transition: transform 300ms ${easing.bezier};
`;

export const ArrowLink: React.FC<ArrowLinkProps> = ({children, variant, color, ...props}) => {
  return (
    <Wrapper {...props}>
      <Container>
        <StyledText as="span" variant={variant} color={color}>
          {children}
        </StyledText>
        {variant === 'small' && (
          <SmallArrowContainer>
            <Icon aria-label={copy.arrowLink.ariaLabels.arrowIcon} icon={Icons.arrowSmall} color={color} />
          </SmallArrowContainer>
        )}
        {variant === 'large' && (
          <LargeArrowContainer>
            <Icon aria-label={copy.arrowLink.ariaLabels.arrowIcon} icon={Icons.arrowLarge} color={color} />
          </LargeArrowContainer>
        )}
      </Container>
    </Wrapper>
  );
};

ArrowLink.defaultProps = {
  color: 'white',
  variant: 'small',
};
