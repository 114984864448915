import React from 'react';

export const Twitter: React.FC<{color: string}> = ({color}) => (
  <svg viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9999 1.84621C18.3352 2.13381 17.6207 2.328 16.8705 2.41538C17.6357 1.96791 18.2239 1.25954 18.5006 0.415415C17.7844 0.829626 16.9909 1.13064 16.1465 1.29268C15.4701 0.590062 14.5065 0.151245 13.4402 0.151245C11.3923 0.151245 9.73252 1.76965 9.73252 3.76588C9.73252 4.04937 9.76538 4.32519 9.82853 4.58973C6.7472 4.43897 4.01533 2.99989 2.18679 0.812936C1.8676 1.34668 1.68479 1.96769 1.68479 2.63022C1.68479 3.88421 2.33932 4.99059 3.3341 5.63884C2.72634 5.62023 2.15465 5.45748 1.65487 5.18672C1.65451 5.20184 1.65451 5.21696 1.65451 5.23232C1.65451 6.9837 2.93254 8.44464 4.62861 8.77685C4.31743 8.85944 3.98984 8.90361 3.65181 8.90361C3.41289 8.90361 3.18059 8.8808 2.9542 8.83879C3.42605 10.2748 4.79518 11.3199 6.41764 11.349C5.14872 12.3187 3.55013 12.8965 1.81294 12.8965C1.51368 12.8965 1.21871 12.8795 0.928711 12.8461C2.56952 13.8719 4.51819 14.4701 6.61202 14.4701C13.4316 14.4701 17.1608 8.96194 17.1608 4.185C17.1608 4.02825 17.1571 3.87246 17.15 3.71738C17.8743 3.20777 18.5031 2.57118 18.9999 1.84621Z"
      fill={color}
    />
  </svg>
);
