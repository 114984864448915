import {Space} from './types';

export const space: Space = {
  NONE: 0,
  XXS: 4,
  XS: 5,
  S: 10,
  SS: 15,
  M: 20,
  MM: 30,
  L: 40,
  LL: 50,
  XL: 60,
  XXL: 120,
  XXLL: 180,
  XXXL: 240,
};
