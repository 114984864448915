import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import styled from 'styled-components/macro';

export type FormProps = BoxProps<'form', HTMLFormElement> &
  Omit<React.InputHTMLAttributes<HTMLFormElement>, 'height' | 'width' | 'size'>;

export const Form: React.FC<FormProps> = styled(Box).attrs({
  as: 'form',
})`` as React.FC<FormProps>;
