import React from 'react';

export const Logo: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 24">
    <path
      d="M59.8525 22.8092H63.0677V0.957764H59.8525V22.8092ZM46.5852 0.957764H49.8004V22.8092H46.5852V13.5521H33.1956V22.8092H29.9794V0.957764H33.1956V10.2148H46.5882L46.5852 0.957764ZM73.1218 0.957764V22.8092H92.1427V19.4739H76.33V13.5521H89.4194V10.2168H76.33V4.29207H92.1327V0.957764H73.1198H73.1218ZM105.195 4.29207V10.2148H118.285V13.5501H105.195V22.8072H101.98V0.955751H121V4.29207H105.195ZM18.9454 15.4984C18.2809 16.974 17.2246 18.2226 15.9004 19.0979C14.5761 19.9733 13.0388 20.4391 11.4684 20.4407C9.28161 20.4372 7.18538 19.5349 5.63909 17.9313C4.09281 16.3276 3.22259 14.1536 3.21918 11.8857C3.22286 9.61794 4.09315 7.44378 5.63938 5.8402C7.18561 4.23662 9.28169 3.3341 11.4684 3.33028C13.0393 3.33236 14.5771 3.79878 15.9014 4.67508C17.2257 5.55138 18.2816 6.80154 18.9454 8.27814L21.8594 6.86893C20.9359 4.81825 19.4679 3.08324 17.6276 1.86671C15.7873 0.650181 13.6508 0.00245819 11.4684 0C8.4293 0.00326911 5.51546 1.25599 3.36566 3.48379C1.21586 5.71158 0.00552351 8.73293 0 11.8847C0.00315719 15.0381 1.21245 18.0614 3.36251 20.2912C5.51256 22.521 8.42775 23.7747 11.4684 23.778C13.6508 23.7756 15.7873 23.1283 17.6276 21.9118C19.4679 20.6953 20.9359 18.9603 21.8594 16.9096L18.9454 15.4984Z"
      fill={color}
    />
  </svg>
);
