/**
 * Checks to see the current pathname belongs to the given path
 * @param pathName The current pathname (from location)
 * @param path The path to compare to
 * @returns boolean
 */
export const comparePath = (pathName: string, path: string): boolean => {
  if (pathName === '/' || path === '/') return path === pathName;
  return pathName.startsWith(path);
};
