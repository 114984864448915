import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import styled from 'styled-components/macro';

export type SectionProps = BoxProps;

const Wrapper: typeof Box = styled(Box).attrs({
  as: 'section',
})``;

export const Section: React.FC<SectionProps> = ({...props}) => {
  return <Wrapper {...props}></Wrapper>;
};

Section.defaultProps = {
  pt: ['31px', null, null, '52px'],
  pb: ['31px', null, null, '52px'],
};
