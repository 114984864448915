import {Link} from 'gatsby';
import {easing} from 'src/utils/animations';
import {css} from 'styled-components/macro';

import {StyledSystemProps} from './types';

export const fonts = {
  primary: '"Recife Text", Georgia, serif',
  secondary: '"Scto Grotesk B", Arial, Helvetica, sans-serif',
  body: '"Neue Haas Grotesk", sans-serif',
  heading: '"Formale Grotesque", sans-serif',
  monospace: 'Menlo, monospace',
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extraBold: 800,
};

export const lineHeights = {
  solid: 1,
  title: 1.25,
  copy: 1.5,
};

export const letterSpacings = {
  default: 'normal',
};

export const textEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

//More about: https://medium.com/@elad/trimming-multi-lines-in-css-5ae59d5e6d84
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const lineClamp = (numberOfLines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface TypographyStyles {
  D1: StyledSystemProps;
  D2: StyledSystemProps;
  D3: StyledSystemProps;
  D4: StyledSystemProps;
  H1: StyledSystemProps;
  H2: StyledSystemProps;
  H3: StyledSystemProps;
  H4: StyledSystemProps;
  H5: StyledSystemProps;
  H6: StyledSystemProps;
  H7: StyledSystemProps;
  H8: StyledSystemProps;
  H9: StyledSystemProps;
  P0: StyledSystemProps;
  P1: StyledSystemProps;
  P2: StyledSystemProps;
  P3: StyledSystemProps;
  P4: StyledSystemProps;
  P5: StyledSystemProps;
  P6: StyledSystemProps;
  Body: StyledSystemProps;
  BodyLarge: StyledSystemProps;
  Small: StyledSystemProps;
  Link: StyledSystemProps;
}

export const typographyStyles: TypographyStyles = {
  D1: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.bold,
    fontSize: '5.875rem',
    lineHeight: '5.375rem',
    textTransform: 'uppercase',
  },
  D2: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.bold,
    fontSize: '3.875rem',
    lineHeight: '3.625rem',
  },
  D3: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.bold,
    fontSize: '3.875rem',
    lineHeight: '3.625rem',
    textTransform: 'uppercase',
  },
  D4: {
    fontFamily: fonts.secondary,
    fontWeight: fontWeights.regular,
    fontSize: '3.5rem',
    lineHeight: '3.5rem',
  },
  H1: {
    fontSize: ['2.25rem', null, '2.75rem', null],
    lineHeight: ['2.15rem', null, '3.25rem', null],
    letterSpacing: '-0.025em',
    fontWeight: fontWeights.semibold,
    fontFamily: fonts.primary,
    as: 'h1',
  },
  H2: {
    textTransform: 'uppercase',
    fontSize: ['1.9rem', null, '2.6875rem', null],
    lineHeight: ['2.1rem', null, '2.875rem', null],
    fontWeight: fontWeights.bold,
    fontFamily: fonts.heading,
    as: 'h2',
  },
  H3: {
    fontSize: ['1.625rem', null, '2.25rem', null],
    lineHeight: ['1.8125rem', null, '2.375rem', null],
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
    as: 'h3',
  },
  H4: {
    textTransform: 'uppercase',
    fontSize: ['1.3rem', null, '1.5rem', null],
    lineHeight: ['1.65rem', null, '1.4375rem', null],
    fontWeight: fontWeights.bold,
    fontFamily: fonts.heading,
    as: 'h4',
  },
  H5: {
    fontWeight: fontWeights.regular,
    fontSize: ['1rem', null, '1.375rem', null],
    lineHeight: ['1.2rem', null, '1.75rem', null],
    fontFamily: fonts.primary,
    as: 'h5',
  },
  H6: {
    textTransform: 'uppercase',
    fontWeight: fontWeights.bold,
    fontSize: ['1rem', null, '1.0625rem', null],
    lineHeight: ['1.1rem', null, '1.0625rem', null],
    fontFamily: fonts.secondary,
    as: 'h6',
  },
  H7: {
    textTransform: 'uppercase',
    fontWeight: fontWeights.bold,
    fontSize: '1.0625rem',
    lineHeight: '1.0625rem',
    fontFamily: fonts.heading,
    as: 'h6',
  },
  H8: {
    textTransform: 'uppercase',
    fontWeight: fontWeights.bold,
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontFamily: fonts.heading,
    as: 'h6',
  },
  H9: {
    fontWeight: fontWeights.regular,
    fontSize: ['0.9rem', null, '1rem', null],
    lineHeight: ['1.3rem', null, '1.4rem', null],
    fontFamily: fonts.primary,
    as: 'h6',
  },
  P0: {
    fontSize: ['0.9rem', null, '1rem', null],
    lineHeight: ['1.3rem', null, '1.4rem', null],
    fontWeight: fontWeights.bold,
    fontFamily: fonts.body,
    as: 'p',
  },
  P1: {
    fontSize: '0.8125rem',
    lineHeight: '1.1rem',
    fontWeight: fontWeights.bold,
    fontFamily: fonts.body,
    as: 'p',
  },
  P2: {
    fontSize: '0.8125rem',
    lineHeight: '1.3rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'p',
  },
  P3: {
    fontSize: '0.7rem',
    lineHeight: '0.9rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'p',
  },
  P4: {
    fontSize: ['0.9rem', null, '1rem', null],
    lineHeight: ['1.3rem', null, '1.4rem', null],
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'p',
  },
  P5: {
    fontSize: '0.8125rem',
    lineHeight: '1.1rem',
    fontWeight: fontWeights.medium,
    fontFamily: fonts.body,
    as: 'p',
  },
  P6: {
    fontSize: '0.7rem',
    lineHeight: '0.9rem',
    fontWeight: fontWeights.medium,
    fontFamily: fonts.body,
    as: 'p',
  },
  Body: {
    fontSize: ['1rem', null, null, '1.125rem'],
    lineHeight: ['1.375rem', null, null, '1.5rem'],
    fontFamily: fonts.body,
    fontWeight: fontWeights.regular,
  },
  BodyLarge: {
    fontSize: ['1rem', null, null, '1.125rem'],
    lineHeight: ['1.375rem', null, null, '1.5rem'],
    fontFamily: fonts.body,
    fontWeight: fontWeights.regular,
  },
  Small: {
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    lineHeight: '1rem',
    letterSpacing: 0.7,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'small',
  },
  Link: {
    fontSize: '0.8125rem',
    lineHeight: '1.3rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    transition: `color 250ms ${easing.bezier}`,
    outline: 'none',
    color: 'emptyGray',
    hover: {
      color: 'white',
    },
    active: {
      color: 'lightGray',
    },
    focus: {
      color: 'lightGray',
    },
    as: Link,
  },
};
