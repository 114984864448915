import React from 'react';

export const Loreal: React.FC<{color: string}> = ({color}) => (
  <svg width="126" height="23" viewBox="0 0 126 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_41435_1677)">
      <path
        d="M68.5488 21.4524H84.8091V19.4488H71.3366V13.0374H81.4559V11.0099H71.3366V5.10375H84.8091V3.10645H68.5488V21.4524Z"
        fill="#2A2C41"
      />
      <path d="M76.9987 0.141357L74.7969 2.636L81.708 0.141357H76.9987Z" fill="#2A2C41" />
      <path d="M112.104 19.4488V3.10645H109.563V21.4524H126V19.4488H112.104Z" fill="#2A2C41" />
      <path
        d="M95.3445 3.10645L86.0977 21.4524H89.2225L91.5881 16.7046H102.678L105.064 21.4524H108.157L98.904 3.10645H95.3445ZM92.7599 14.4039L97.1164 5.64082L101.525 14.4039H92.7599Z"
        fill="#2A2C41"
      />
      <path
        d="M59.7253 13.781C63.8912 12.6592 64.296 9.53215 64.2755 8.41353C64.0267 5.13553 61.8374 3.10645 57.9046 3.10645H46.4434V21.4524H49.0563V13.7572H56.5186L62.0075 21.4524H65.3008C65.3008 21.4524 61.3507 16.2725 59.7253 13.781ZM57.5487 11.5882H49.0563V5.27695H57.8448C59.8687 5.27695 61.0058 6.23031 61.4153 7.44109C61.6862 8.27052 61.513 9.36054 61.0641 10.101C60.3301 11.3277 58.9347 11.5882 57.5487 11.5882Z"
        fill="#2A2C41"
      />
      <path
        d="M30.742 0C23.3663 0 18.3389 5.2467 18.3389 11.5961C18.3389 18.2808 23.8923 23 30.742 23C37.5917 23 43.1372 18.3476 43.1372 11.5961C43.1372 5.2467 38.0484 0 30.742 0ZM30.6538 20.723C25.5508 20.723 21.3471 16.5854 21.3471 11.6025C21.3471 6.61955 25.3523 2.29126 30.8522 2.29126C36.3521 2.29126 40.1573 6.62591 40.1573 11.6025C40.1573 16.5791 35.7552 20.723 30.6554 20.723H30.6538Z"
        fill="#2A2C41"
      />
      <path d="M11.7588 9.59729H13.6441L17.0665 3.10645H14.3134L11.7588 9.59729Z" fill="#2A2C41" />
      <path d="M2.54363 19.4488V3.10645H0V21.4524H16.4367V19.4488H2.54363Z" fill="#2A2C41" />
    </g>
    <defs>
      <clipPath id="clip0_41435_1677">
        <rect width="126" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
