import React, {useState} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Events} from 'src/analytics';
import {Button, Anchor, FireworkCTA} from 'src/components/controls';
import {Box, BoxProps, Flex, LayoutGrid, NavDrawer, NavigationItem} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import * as externalUrls from 'src/routes/externalUrls';
import * as paths from 'src/routes/paths';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';
import {LaptopUp, TabletDown} from 'src/utils/responsive';
import styled from 'styled-components/macro';
import {color} from 'styled-system';

const {P0, Link} = Typography;

export type HeaderNavProps = BoxProps;

const Wrapper: typeof Box = styled(Box).attrs({})``;

const LeftSection: typeof Flex = styled(Flex)``;
const RightSection: typeof Flex = styled(Flex).attrs({
  alignItems: 'center',
})``;

const StyledLink: typeof Link = styled(Link).attrs({
  color: 'white',
  py: '6px',
})`
  &:hover {
    color: ${themeGet('colors.neonGreen')};
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({})`
  min-width: 6.2375rem;
`;

const StyledP0: typeof P0 = styled(P0).attrs({
  fontFamily: fonts.heading,
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  color: 'cream',
})`
  &:hover {
    color: ${themeGet('colors.neonGreen')};
  }
`;

const IconWrapper = styled(Box).attrs({})`
  &:hover {
    color: ${themeGet('colors.neonGreen')};
  }
  &:hover svg {
    fill: ${themeGet('colors.neonGreen')};
  }
`;

const navigationItems: NavigationItem[] = [
  {
    name: copy.navigation.experience,
    path: paths.EXPERIENCE,
    dataCy: 'navbar.core.link',
    hightlightColor: 'cream',
    onClick: () => {
      Events.didClickNav({item: 'Explore', pageLocation: 'mobileNav'});
    },
  },
  {
    name: copy.navigation.enterprise,
    path: paths.ENTERPRISE,
    dataCy: 'navbar.core.link',
    hightlightColor: 'cream',
    onClick: () => {
      Events.didClickNav({item: 'For Companies', pageLocation: 'mobileNav'});
    },
  },
  {
    name: copy.navigation.aboutUs,
    path: paths.ABOUT_US,
    dataCy: 'navbar.events.link',
    hightlightColor: 'cream',
    onClick: () => {
      Events.didClickNav({item: 'About Us', pageLocation: 'mobileNav'});
    },
  },
  {
    name: copy.navigation.login,
    path: externalUrls.LOGIN,
    dataCy: 'navbar.events.link',
    hightlightColor: 'cream',
    onClick: () => {
      Events.didClickNav({item: 'Login', pageLocation: 'mobileNav'});
    },
    type: 'external',
  },
  {
    name: copy.navigation.apply,
    path: paths.APPLY,
    dataCy: 'navbar.events.link',
    hightlightColor: 'cream',
    onClick: () => {
      Events.didClickNav({item: 'Apply', pageLocation: 'mobileNav'});
    },
  },
];

export const HeaderNav: React.FC<HeaderNavProps> = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const handleTrackEvent = (item: string) => () => {
    Events.didClickNav({item, pageLocation: 'header'});
  };

  return (
    <Wrapper {...props}>
      <LayoutGrid
        backgroundColor="darkGreen"
        maxWidth={'unset'}
        paddingX={'35px'}
        marginX={'unset'}
        width="100%"
        height="100%"
      >
        <Flex position="relative" gridColumn="1/end" alignItems="center" justifyContent="space-between">
          <LeftSection>
            <StyledLink
              to={paths.HOME}
              aria-label={copy.navigation.ariaLabels.logoLink}
              onClick={handleTrackEvent('Home')}
            >
              <IconWrapper width={['100px', null, null, '121px']} height={['18.95px', null, null, '21px']}>
                <Icon icon={Icons.logo} color="cream" aria-label={copy.navigation.ariaLabels.logo} />
              </IconWrapper>
            </StyledLink>

            <LaptopUp>
              <StyledLink
                to={paths.EXPERIENCE}
                onClick={handleTrackEvent('Explore')}
                ml={['12px', null, null, null, '40px', '56px']}
              >
                <StyledP0>{copy.navigation.experience}</StyledP0>
              </StyledLink>
              <StyledLink
                to={paths.ENTERPRISE}
                onClick={handleTrackEvent('For Companies')}
                ml={['12px', null, null, null, '40px', '56px']}
              >
                <StyledP0>{copy.navigation.enterprise}</StyledP0>
              </StyledLink>
              <StyledLink
                to={paths.ABOUT_US}
                onClick={handleTrackEvent('About Us')}
                ml={['12px', null, null, null, '40px', '56px']}
              >
                <StyledP0>{copy.navigation.aboutUs}</StyledP0>
              </StyledLink>
            </LaptopUp>
          </LeftSection>
          <RightSection>
            <TabletDown>
              <StyledFireworkCTA variant="neonGreen" mr="1.5rem" to={paths.APPLY} onClick={handleTrackEvent('Apply')}>
                {copy.navigation.apply}
              </StyledFireworkCTA>

              <Button
                variant="icon"
                aria-label={copy.navigation.ariaLabels.menuButton}
                onClick={(): void => setDrawerIsOpen(true)}
              >
                <Icon aria-label={copy.navigation.ariaLabels.menuIcon} icon={Icons.menu} color="cream" />
              </Button>
            </TabletDown>
            <LaptopUp>
              <StyledFireworkCTA
                variant="transparent"
                ml={['2rem', null, null, null, '3rem']}
                to={externalUrls.LOGIN}
                onClick={handleTrackEvent('Login')}
              >
                {copy.navigation.login}
              </StyledFireworkCTA>
              <StyledFireworkCTA variant="neonGreen" ml="2rem" to={paths.APPLY} onClick={handleTrackEvent('Apply')}>
                {copy.navigation.apply}
              </StyledFireworkCTA>
            </LaptopUp>
          </RightSection>
        </Flex>
      </LayoutGrid>
      <NavDrawer
        isOpen={drawerIsOpen}
        onRequestClose={(): void => setDrawerIsOpen(false)}
        navigationItems={navigationItems}
      />
    </Wrapper>
  );
};

HeaderNav.defaultProps = {
  backgroundColor: 'offBlack',
  height: HEADER_NAV_HEIGHT.responsive,
  width: '100%',
  color: 'white',
};
