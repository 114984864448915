export enum ModalIds {
  navDrawer = 'navDrawer',
}

export enum ModalPriority {
  low = 1,
  medium = 2,
  high = 3,
  highest = 4,
}
