import {Link} from 'gatsby';
import {easing} from 'src/utils/animations';
import {css} from 'styled-components/macro';

import {StyledSystemProps} from './types';
import {fontWeights} from './typographyStyles';

export const fonts = {
  primary: 'Romie',
  secondary: '"Scto Grotesk B", Arial, Helvetica, sans-serif',
  body: '"Neue Haas Grotesk", sans-serif',
  heading: '"Formale Grotesque", sans-serif',
  monospace: 'Menlo, monospace',
};

interface TypographyStyles {
  H1: StyledSystemProps;
  H2: StyledSystemProps;
  H3: StyledSystemProps;
  H4: StyledSystemProps;
  P0: StyledSystemProps;
  P1: StyledSystemProps;
  P2: StyledSystemProps;
  Link: StyledSystemProps;
  HeadLine: StyledSystemProps;
  CTA: StyledSystemProps;
  Article: StyledSystemProps;
}

export const typographyStyles: TypographyStyles = {
  H1: {
    fontSize: ['4.25rem', null, '4rem', '5rem'],
    lineHeight: ['4.25rem', null, '4.25rem', '5rem'],
    letterSpacing: '-0.0025em',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
    as: 'h1',
  },
  H2: {
    fontSize: ['2.875rem', null, null, '1.5rem', '2rem', '3rem'],
    lineHeight: ['3rem', null, null, '1.5rem', '2rem', '3.15rem'],
    letterSpacing: '-0.0025em',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
    as: 'h2',
  },
  H3: {
    fontSize: ['1.875rem', null, null, '2rem'],
    lineHeight: ['1.875rem', null, null, '2rem'],
    letterSpacing: '-0.0025em',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
    as: 'h3',
  },
  H4: {
    fontSize: '1.375rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.025rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
    as: 'h4',
  },
  P0: {
    fontSize: ['0.9rem', null, '1rem'],
    lineHeight: ['1.3rem', null, '1.4rem'],
    fontWeight: fontWeights.bold,
    fontFamily: fonts.body,
    as: 'p',
  },
  P1: {
    fontSize: ['1.3125rem', null, null, '1.5rem'],
    lineHeight: ['1.6875rem', null, null, '1.875rem'],
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'p',
  },
  P2: {
    fontSize: ['0.875rem', null, null, '1.0625rem', null],
    lineHeight: '1.3125rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    as: 'p',
  },
  Link: {
    fontSize: '0.8125rem',
    lineHeight: '1.3rem',
    fontWeight: fontWeights.regular,
    fontFamily: fonts.body,
    transition: `color 250ms ${easing.bezier}`,
    outline: 'none',
    color: 'cream',
    hover: {
      color: 'neonGreen',
    },
    active: {
      color: 'neonGreen',
    },
    focus: {
      color: 'neonGreen',
    },
    as: Link,
  },
  HeadLine: {
    fontSize: '2rem',
    lineHeight: '2rem',
    fontWeight: fontWeights.extraBold,
    fontFamily: fonts.heading,
    as: 'p',
  },
  CTA: {
    fontSize: '1rem',
    lineHeight: '1.4rem',
    letterSpacing: '0.1rem',
    fontWeight: fontWeights.extraBold,
    fontFamily: fonts.body,
    as: 'p',
  },
  Article: {
    fontSize: ['1.1875rem', null, null, '1.75rem'],
    lineHeight: ['1.1875rem', null, null, '1.75rem'],
    fontFamily: fonts.primary,
    fontWeight: fontWeights.regular,
    as: 'p',
  },
};
