import React from 'react';

import {GatsbyLinkProps} from 'gatsby';
import DynamicStyledSystemComponent from 'src/styleguide/DynamicStyledSystemComponent';
import {typographyStyles} from 'src/styleguide/fireworkTypographyStyles';
import {StyledSystemProps} from 'src/styleguide/types';

export type LinkProps = StyledSystemProps & Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;

type StyledHTMLElement = StyledSystemProps & React.HTMLAttributes<HTMLElement>;

export interface TypographyComponentProps {
  H1: React.FC<StyledHTMLElement>;
  H2: React.FC<StyledHTMLElement>;
  H3: React.FC<StyledHTMLElement>;
  H4: React.FC<StyledHTMLElement>;
  P0: React.FC<StyledHTMLElement>;
  P1: React.FC<StyledHTMLElement>;
  P2: React.FC<StyledHTMLElement>;
  Link: React.FC<LinkProps>;
  HeadLine: React.FC<StyledHTMLElement>;
  CTA: React.FC<StyledHTMLElement>;
  Article: React.FC<StyledHTMLElement>;
}

const createComponent: <T = StyledSystemProps>(textStyle: T, displayName: string) => React.FC<T> = (
  textStyle,
  displayName,
) => {
  const component: React.FC<typeof textStyle> = props => (
    <DynamicStyledSystemComponent {...textStyle} {...props}>
      {props.children}
    </DynamicStyledSystemComponent>
  );
  component.displayName = displayName;
  return component;
};

export const Typography: TypographyComponentProps = {
  H1: createComponent(typographyStyles.H1, 'H1'),
  H2: createComponent(typographyStyles.H2, 'H2'),
  H3: createComponent(typographyStyles.H3, 'H3'),
  H4: createComponent(typographyStyles.H4, 'H4'),
  P0: createComponent(typographyStyles.P0, 'P0'),
  P1: createComponent(typographyStyles.P1, 'P1'),
  P2: createComponent(typographyStyles.P2, 'P2'),
  Link: createComponent(typographyStyles.Link, 'Link'),
  HeadLine: createComponent(typographyStyles.HeadLine, 'HeadLine'),
  CTA: createComponent(typographyStyles.CTA, 'CTA'),
  Article: createComponent(typographyStyles.Article, 'Article'),
};
