// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// NOTE: tsc doesn't like the inline styles but they're needed for blend modes
import React from 'react';

export const MembershipCriteria: React.FC<{color: string}> = ({color}) => (
  <svg viewBox="0 0 584 469" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.8"
      d="M170.344 192.596C170.344 176.027 183.775 162.596 200.344 162.596H265.113V419.724H200.344C183.775 419.724 170.344 406.292 170.344 389.724V192.596Z"
      fill="#FF7C32"
    />

    <rect x="344.515" y="64.2821" width="144.716" height="404.598" rx="30" fill="#FF7C32" />

    <g style={{'mix-blend-mode': 'darken'}} opacity="0.1">
      <rect x="256.148" y="162.596" width="8.96469" height="257.128" fill="#ADBFFF" />
    </g>

    <rect opacity="0.7" y="232.153" width="124.908" height="213.855" rx="30" fill="#FF7C32" />

    <g style={{'mix-blend-mode': 'screen'}}>
      <rect x="77.7935" y="209.281" width="124.908" height="259.6" rx="30" fill="#FF7C32" />
    </g>

    <rect opacity="0.7" x="265.156" y="126.941" width="145.726" height="323.642" rx="30" fill="#FF7C32" />

    <g style={{'mix-blend-mode': 'multiply'}}>
      <rect x="77.7935" y="209.281" width="124.908" height="259.6" rx="30" fill="#FF7C32" />
    </g>

    <rect x="77.7935" y="209.281" width="124.908" height="259.6" rx="30" fill="#FF7C32" />

    <g style={{'mix-blend-mode': 'screen'}}>
      <rect x="438.274" width="145.726" height="440.29" rx="30" fill="#FF7C32" />
    </g>

    <g style={{'mix-blend-mode': 'darken'}} opacity="0.12">
      <path
        d="M429.039 64.2821H520.703C537.272 64.2821 550.703 77.7136 550.703 94.2821V409.891C550.703 426.459 537.272 439.891 520.703 439.891H459.039C442.471 439.891 429.039 426.459 429.039 409.891V64.2821Z"
        fill="#ADBFFF"
      />
    </g>

    <rect x="438.274" width="145.726" height="440.29" rx="30" fill="#F8EAD5" />
  </svg>
);
