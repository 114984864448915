export const HOME = '/';
export const APPLY = '/apply';
export const FAQ = '/faq';
export const EXTERNAL_COMMUNICATIONS_POLICY = '/external-communications-policy';
export const PRIVACY_POLICY = '/privacy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const MEMBER_AGREEMENT = '/member-agreement';
export const COVID_POLICIES = '/covid-policies';
export const EDITORIAL = '/articles';
export const ENTERPRISE_INTEREST = '/enterprise-interest?campaign_id=7015G000001ekCsQAI';
export const CAREERS = '/careers';
export const TOPIC = '/topic';
export const CATEGORY = '/category';
export const AUTHORS = '/authors';
export const SERIES = '/series';
export const PODCAST = '/podcast-new-rules-of-business';
export const MEMBERSHIP_CRITERIA = '/membership-criteria';
export const CLUBHOUSES_OVERVIEW = '/clubhouses-overview';
export const EXECUTIVE_COACHING = '/executive-coaching-ext';
export const LEADERSHIP_TEAM = '/leadership-team';
export const PRESS = '/press';
export const EXPERIENCE = '/explore'; // Intentionally re-using `explore` here
export const ENTERPRISE = '/enterprise';
export const ABOUT_US = '/about-us';

export const careerAnchorPaths = {
  story: 'story',
  values: 'values',
  perks: 'perks',
  team: 'team',
  roles: 'roles',
};

export const socialPaths = {
  linkedIn: 'https://www.linkedin.com/company/joinchief',
  instagram: 'https://www.instagram.com/chief.network/',
  youtube: 'https://www.youtube.com/@chiefnetwork',
  facebook: 'https://www.facebook.com/joinchiefnow/',
  x: 'https://x.com/JoinChiefNow',
};

export const mobileAppPaths = {
  appStore: 'https://apps.apple.com/us/app/chief-members/id1498831116',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.chief.members',
};
