import React from 'react';

export const Mercedes: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 71">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.1536 52.3003C27.8967 52.3003 20.4111 44.8148 20.4111 35.5769C20.4111 26.32 27.8967 18.8345 37.1536 18.8345C46.3914 18.8345 53.896 26.32 53.896 35.5769C53.8769 44.8148 46.3914 52.3003 37.1536 52.3003ZM37.1535 38.0916L23.5729 43.7105C26.43 48.5294 31.6299 51.4627 37.2107 51.4246C42.7915 51.4055 47.9723 48.4532 50.7913 43.6153L37.1535 38.0916ZM34.7917 34.1299L36.9631 19.7874C31.3823 19.8636 26.2396 22.873 23.4396 27.711C20.6397 32.549 20.6206 38.5107 23.3634 43.3677L34.7917 34.1299ZM39.4392 34.3203L51.0008 43.2725C53.6865 38.3964 53.6293 32.4918 50.8294 27.6729C48.0295 22.873 42.9439 19.8636 37.3631 19.7874L39.4392 34.3203Z"
      fill={color}
    />
  </svg>
);
