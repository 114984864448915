import React from 'react';

import {Flex} from './Flex';
import {Spinner} from './Spinner';

export const Loading: React.FC = () => {
  return (
    <Flex justifyContent="center" py="XXL">
      <Spinner />
    </Flex>
  );
};
