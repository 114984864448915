import React from 'react';

export const Verizon: React.FC<{color: string}> = ({color}) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146 71">
    <path
      d="M123.374 23.341L117.646 35.6354 115.481 30.9552H113.175L116.738 38.5693H118.624L125.679 23.341H123.374zM62.5151 25.5544H67.1728V29.6725H62.5151V25.5544zM25.9074 46.1445H30.7638L36.0463 30.9505H31.3886L28.4066 40.7201H28.3498L25.3962 30.9505H20.625L25.9074 46.1445zM42.9759 33.8473C44.5947 33.8473 45.7307 35.0685 45.9011 36.8293H39.7099C40.0791 35.0117 41.0447 33.8473 42.9759 33.8473zM43.2031 43.2193C41.1583 43.2193 39.9087 41.8845 39.6815 39.8397H50.6155C50.6439 36.5737 49.7919 33.9609 47.9743 32.3421 46.6963 31.1493 45.0207 30.4961 42.8623 30.4961 38.2899 30.4961 35.109 33.9609 35.109 38.5049 35.109 43.0773 38.1479 46.5705 43.1463 46.5705 45.0491 46.5705 46.5543 46.0593 47.8039 45.2073 49.1387 44.2985 50.0759 42.9637 50.3599 41.6289H45.9011C45.5035 42.6513 44.5663 43.2193 43.2031 43.2193zM51.7231 46.1445H56.3523V39.1865C56.3523 36.0057 58.2551 34.6141 61.1519 35.0117H61.2371V31.0073C61.0099 30.9221 60.7259 30.8937 60.2999 30.8937 58.4823 30.8937 57.2895 31.7173 56.2387 33.5065H56.1535V30.9789H51.7231V46.1445zM62.5151 46.1445H67.1728V30.9505H62.5151V46.1445zM68.4508 46.1445H81.4296V42.5093H74.074V42.4241L81.0888 34.5289V30.9789H68.4224V34.6141H75.5508V34.6993L68.4224 42.6513V46.1445H68.4508zM89.6372 43.1909C87.4504 43.1909 86.286 41.3449 86.286 38.5617 86.286 35.7501 87.4504 33.9325 89.6372 33.9325 91.824 33.9325 93.0452 35.7501 93.0452 38.5617 93.0736 41.3733 91.8524 43.1909 89.6372 43.1909zM89.6656 30.5529C84.8376 30.5529 81.6 34.0177 81.6 38.5901 81.6 43.1341 84.8376 46.6273 89.6656 46.6273 94.4936 46.6273 97.7313 43.1341 97.7313 38.5901 97.7313 34.0177 94.522 30.5529 89.6656 30.5529zM98.7537 46.1445H103.383V37.6813C103.383 35.7501 104.519 34.3869 106.166 34.3869 107.757 34.3869 108.608 35.5229 108.608 37.1133V46.1161H113.238V36.2045C113.238 32.8533 111.221 30.4961 107.955 30.4961 105.882 30.4961 104.434 31.3765 103.326 33.0237H103.241V30.9505H98.7537V46.1445z"
      fill={color}
    />
  </svg>
);
