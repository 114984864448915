import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {Box, BoxProps} from 'src/components/shared';
import styled from 'styled-components/macro';

export type ScrimProps = BoxProps & {scrimColor?: string};

const Wrapper: typeof Box = styled(Box).attrs((props: {scrimColor: string}) => ({
  position: 'absolute',
  top: 0,
  height: '100%',
  width: ['0px', null, null, null, '172px', '241px'],
  background: `linear-gradient(90deg, ${themeGet(
    `colors.${props.scrimColor}`,
    props.scrimColor,
  )(props)} 31.08%, ${themeGet(`colors.${props.scrimColor}`, props.scrimColor)(props)}00 100%)`,
}))<{scrimColor: string}>``;

export const Scrim: React.FC<ScrimProps> = ({...props}) => {
  return <Wrapper {...props}></Wrapper>;
};

Scrim.defaultProps = {
  scrimColor: 'offBlack',
  zIndex: 2,
};
