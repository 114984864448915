import {props} from '@styled-system/should-forward-prop';
import styled, {css, CSSObject} from 'styled-components/macro';
import {
  border,
  borderRadius,
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  size,
  space,
  textAlign,
  textStyle,
  system,
  width,
  maxWidth,
  gridColumn,
  position,
  display,
} from 'styled-system';

import {transform} from './styleProps/transform';
import {textEllipsis} from './typographyStyles';

// There's this set of typed components that are created generically and it seems you can't forward any props to them.
// As part of my development, I find it very useful to cut through all the abstractions and be able to name things with `id`s for using the Chrome elemtent inspector for debugging styling
// In order to do that, I had to add `id` to a whitelist here by hand like this.
function isPropHelpfulForDebugging(prop: string) {
  return prop === 'id';
}

export default styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    isPropHelpfulForDebugging(prop) || (!props.includes(prop) && defaultValidatorFn(prop)),
})<{ellipsis?: boolean; hover?: CSSObject; active?: CSSObject; focus?: CSSObject}>`
  ${display}
  ${space}
  ${fontSize}
  ${fontStyle}
  ${size}
  ${color}
  ${textStyle}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${border}
  ${borderRadius}
  ${lineHeight}
  ${textAlign}
  ${width}
  ${maxWidth}
  ${gridColumn}
  ${transform}
  ${position}
  ${system({
    textTransform: {
      property: 'textTransform',
    },
    textDecoration: {
      property: 'textDecoration',
    },
    whiteSpace: {
      property: 'whiteSpace',
    },
    transition: {
      property: 'transition',
    },
    outline: {
      property: 'outline',
    },
  })}
  ${props => props.ellipsis && textEllipsis}
  ${props =>
    props.hover &&
    `
    &:hover {
      ${css(props.hover)}
    }
  `}
  ${props =>
    props.focus &&
    `
    &:focus {
      ${css(props.focus)}
    }
  `}
  ${props =>
    props.active &&
    `
    &:active {
      ${css(props.active)}
    }
  `}
`;
