// import {ThemeColors} from './types';

// Since we're still defining the theme object,
// temporarily disabling typing
// export const colors: ThemeColors = {
export const colors = {
  // Main colors
  offBlack: '#0A0A0A',
  green: '#15200C',

  // Primary Colors
  lightGreen: '#31522A',
  lightGreenAriaText: '#68AB5F',
  darkGreen: '#111E04',
  lightGold: '#D5BB66',
  darkGold: '#AD9048',
  darkGoldAlt: '#897239',
  cream: '#F8EAD5',
  cocoa: '#492E00',
  offWhite: '#FAF6EF',
  // Background Colors
  ivory: '#F9F6EE',
  white: '#FFFFFF',
  black: '#000000',
  notBlack: '#2E2E2E',
  midnightBlue: '#2A2C41',
  lightGrey: '#DFDFD0',
  // Secondary Colors
  beige: '#E1D8C3',
  lightBeige: '#F8F6EE',
  sand: '#BAB299',
  frenchGray: '#ADAA9B',
  tan: '#CFCAAC',
  brightOrange: '#FF7C32',
  periwinkle: '#ADBFFF',
  neonGreen: '#DCF15B',
  olive: '#5B4D1B',

  // Avatar colors
  blueGreen: '#3C8B9A',
  salmon: '#E88a66',
  blueViolet: '#7C97B9',
  teal: '#559B8C',
  plum: '#492426',

  // Tertiary Colors
  lightGray: '#F5F5F5',
  darkestGray: '#53565A',
  emptyGray: '#989A9C',
  lineGray: '#A9ABAD',
  washingtonPostFontGray: '#2A2A2A',
  shadowGray: 'rgba(103, 103, 103, 0.3)',
  insetGray: 'rgba(202, 202, 202, 0.25)',

  // Other
  darkTeal: '#225D5A',
  coral: '#DA6251',
  forestGreen: '#003D3D',

  lightLineGrey: '#dfdfdf',
  mediumLineGrey: '#d8d8d8',
  oliveGreen: '#5B4D1B',
  poppyRed: '#BA4D4E',
  peacockBlue: '#18637B',
  lightOpaqueGold: '#F4EEDC',
  poppyRedAlt: '#C7454B',
  lineGray60: '#A9ABAD99',

  inactiveGray: '#9B9B9B',
  lineGold: '#473915',
  pulseGreen: '#1B9800',
  weatheredStone: '#C4C4C4',
  smokyBlack: '#171717',
  darkHoleBlack: '#191909',
  backgroundGray: 'rgb(245, 245, 245, 0.25)',
};

export const avatarColors = [
  colors.teal,
  colors.darkGold,
  colors.blueGreen,
  colors.blueViolet,
  colors.plum,
  colors.salmon,
];

export const groupColors = [
  colors.lightGold,
  colors.blueViolet,
  colors.teal,
  colors.salmon,
  colors.plum,
  colors.oliveGreen, // Category 5 does not exist in production
  colors.blueGreen,
];
