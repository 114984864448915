import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import styled from 'styled-components/macro';

export type InputProps = BoxProps<'input'> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'height' | 'width' | 'size'>;

export const Input: React.FC<InputProps> = styled(Box).attrs({
  as: 'input',
})``;
